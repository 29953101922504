import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import { FRIENDLY_SINGLE_LEFT_IMAGE } from "./single-left-image";

export const FRIENDLY_SINGLE_RIGHT_IMAGE: TComponent = {
  ...FRIENDLY_SINGLE_LEFT_IMAGE,
  componentKey: EComponents.FRIENDLY_SINGLE_RIGHT_IMAGE,
  name: EComponents.FRIENDLY_SINGLE_RIGHT_IMAGE,
  displayName: 'Single Right Image',
};
