import _cloneDeep from 'lodash/cloneDeep';
import {TComponent} from '@/store/builder-pro/types';
import {
  ALL_ELEMENTS,
  AMAZON_ELEMENTS,
  DYNAMIC_DATA_COMPONENTS,
  EComponents,
  EComponentTypes,
  FRIENDLY_ELEMENTS,
  OZON_ELEMENTS
} from '@/store/builder-pro/consts';
import {
  syncPropsByTemplate
} from "./sync-props-by-template";

const ALL_ELEMENTS_PROP_KEYS = Object.entries(ALL_ELEMENTS)
  .filter(([, value]) => value.props)
  .map(([key, value]) => ({
    key,
    props: Object.keys(value.props || {}),
  }))
  .reduce((a, c) => ({ ...a, [c.key]: c.props }), {} as Record<string, string[]>);

const kebabToPascal = (str: string) => {
  const result = str.replace(/-(\w)/g, (match, letter) => letter.toUpperCase());
  return result.charAt(0).toUpperCase() + result.slice(1);
};

const syncComponentKey = (component: TComponent) => {
  if (!component.componentKey) {
    switch (component.displayName) {
      case 'Accordion Header':
        component.componentKey = ALL_ELEMENTS.AccordionHeader.componentKey;
        break;
      case 'Accordion Content':
        component.componentKey = ALL_ELEMENTS.AccordionContent.componentKey;
        break;
      case 'Popover Header':
        component.componentKey = ALL_ELEMENTS.PopoverHeader.componentKey;
        break;
      case 'Popover Content':
        component.componentKey = ALL_ELEMENTS.PopoverContent.componentKey;
        break;
      case '360 Viewer':
        component.componentKey = ALL_ELEMENTS.The360Viewer.componentKey;
        break;
      case 'Loader':
        component.componentKey = ALL_ELEMENTS.The360ViewerLoader.componentKey;
        break;
      case 'Additional Content':
        component.componentKey = ALL_ELEMENTS.The360ViewerAdditionalContent.componentKey;
        break;
      case 'Dynamic Data Provider':
        component.componentKey = DYNAMIC_DATA_COMPONENTS.DynamicDataProvider.componentKey;
        break;
      case 'Dynamic Data Receiver':
        component.componentKey = DYNAMIC_DATA_COMPONENTS.DynamicDataReceiver.componentKey;
        break;
      default:
    }

    const componentName = kebabToPascal(component.name) as keyof typeof ALL_ELEMENTS;
    if (ALL_ELEMENTS[componentName]?.componentKey) {
      component.componentKey = ALL_ELEMENTS[componentName].componentKey;
    } else {
      console.warn(`WARNING: No component key found for ${componentName}`);
    }
  }

  return component;
};

const syncComponents = (componentsArray: TComponent[]) => {
  const featureComponents = [...Object.keys(FRIENDLY_ELEMENTS), ...Object.keys(OZON_ELEMENTS), ...Object.keys(AMAZON_ELEMENTS)];

  // creating a variable to hold a feature where the components will be added to if were outside a feature
  let currentFeatureComponent: TComponent | null = null;

  let syncedComponents: TComponent[] = _cloneDeep(componentsArray);

  for (let i = 0; i < syncedComponents.length; i += 1) {
    let updatedComponent = _cloneDeep(syncedComponents[i]);

    updatedComponent = syncComponentKey(updatedComponent);

    // fixing props of the component
    if (ALL_ELEMENTS_PROP_KEYS[updatedComponent.componentKey]) {
      let addedNewProps = false;
      // todo replace TEXT_PROPS.textAlign prop values ('start' => 'left', 'end' => 'right')
      // add all missing props
      ALL_ELEMENTS_PROP_KEYS[updatedComponent.componentKey].forEach((propKey) => {
        if (!updatedComponent.props?.[propKey]) {
          addedNewProps = true;
          if (ALL_ELEMENTS[updatedComponent.componentKey as keyof typeof ALL_ELEMENTS].props?.[propKey]
            && !ALL_ELEMENTS[updatedComponent.componentKey as keyof typeof ALL_ELEMENTS].props?.[propKey]?.optional) {
            if (!updatedComponent.props) updatedComponent.props = {};
            updatedComponent.props[propKey] = _cloneDeep(
              ALL_ELEMENTS[updatedComponent.componentKey as keyof typeof ALL_ELEMENTS].props![propKey]
            );
          }
        }
      });
      if (updatedComponent.props) {
        // remove all redundant props
        const propsToRemove: string[] = [];
        Object.keys(updatedComponent.props).forEach((propKey) => {
          if (!ALL_ELEMENTS_PROP_KEYS[updatedComponent.componentKey].includes(propKey)) {
            propsToRemove.push(propKey);
          }
        });
        propsToRemove.forEach((propKey) => {
          delete updatedComponent.props![propKey];
        });
      }

      syncPropsByTemplate(updatedComponent)

      // if new props are added, sort the props according to the all elements' props
      if (addedNewProps) {
        updatedComponent.props = ALL_ELEMENTS_PROP_KEYS[updatedComponent.componentKey].reduce(
          (a, c) => ({...a, [c]: updatedComponent.props?.[c]}),
          {}
        );
      }
    }

    // fixing the type of the component
    if (!updatedComponent.type) {
      // if the component is not at the top level, then it's definitely not a feature
      if (updatedComponent.parentId) {
        updatedComponent.type = EComponentTypes.COMPONENT;
      } else if (
        updatedComponent.componentKey === EComponents.FEATURE ||
        featureComponents.includes(updatedComponent.componentKey)
      ) {
        updatedComponent.type = EComponentTypes.FEATURE;
      } else {
        updatedComponent.type = EComponentTypes.COMPONENT;
      }
    }

    // if the component is a feature, then reset the current feature to null
    if (updatedComponent.type === EComponentTypes.FEATURE) {
      currentFeatureComponent = null;
    }

    // if the component is an outer component and is not a feature, then put it in a feature
    if (!updatedComponent.parentId && updatedComponent.type !== EComponentTypes.FEATURE) {
      if (!currentFeatureComponent) {
        currentFeatureComponent = _cloneDeep(ALL_ELEMENTS.Feature)
        // 0 means that the component has no parent
        currentFeatureComponent!.parentId = 0;
        currentFeatureComponent!.id = syncedComponents.reduce((prev, cur) => prev < cur.id! ? cur.id! : prev, 0) + 1
        currentFeatureComponent!.order = syncedComponents.filter(c => !c.parentId)
          .reduce((prev, cur) => prev < cur.order! ? cur.order! : prev, 0) + 1
        syncedComponents.push(currentFeatureComponent);
      }
      updatedComponent.parentId = currentFeatureComponent!.id;
    }

    syncedComponents[i] = updatedComponent;
  }

  // fix orders of outer (top-level) components
  syncedComponents
    .filter(component => !component.parentId)
    .sort((a, b) => a.order && b.order ? (b.order > a.order ? -1 : 1 ) : 0)
    .forEach((component, index) => {
      component.order = index + 1;
    })

  return syncedComponents;
};

export { syncComponents };
