// @ts-ignore
import LTT from 'list-to-tree';
import {TComponent} from "@/store/builder-pro/types";

const compareByOrder = (vector: any) => {
  return (a: any, b: any) => {
    const aOrder = Number(a.get('order'));
    const bOrder = Number(b.get('order'));
    if (aOrder > bOrder) {
      return vector ? 1 : -1;
    } else if (aOrder < bOrder) {
      return vector ? -1 : 1;
    } else {
      return 0;
    }
  };
};

const constructNestedComponents = (allComponents: TComponent[] = []) => {
  const ltt = new LTT(allComponents, {
    key_id: 'id',
    key_parent: 'parentId',
    key_child: 'children',
  });
  ltt.sort(compareByOrder(true));
  return ltt.GetTree();
};

export { constructNestedComponents }
