import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS, TEXT_PROPS } from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";
import { FORM_ERROR } from './error';

export const FORM_INPUT: TComponent = {
  componentKey: EComponents.FORM_INPUT,
  name: EComponents.FORM_INPUT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Form Input',
  passStyle: true,
  staticChildren: true,
  requiredUpperElements: [EComponents.FORM],
  props: {
    placeholder: {
      name: 'Placeholder',
      defaultValue: '',
    },
    name: {
      name: 'Name',
      defaultValue: '',
    },
    autocomplete: {
      name: 'Autocomplete',
      defaultValue: '',
      items: ['', 'new-password'],
    },
    type: {
      name: 'Type',
      defaultValue: 'text',
      items: ['text', 'number', 'password'],
    },
    rules: {
      name: 'Rules',
      defaultValue: [],
    },
    ...TEXT_PROPS,
    textAlign: {
      ...TEXT_PROPS.textAlign,
      defaultValue: 'left',
    },
    ...COMMON_PROPS,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: '100%',
    },
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '4px 12px',
    },
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #d9d9d9',
    },
    borderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px',
    },
  },
  children: [FORM_ERROR],
};
