import { BuilderProActions } from '@/store/builder-pro/types';
import _cloneDeep from 'lodash/cloneDeep';
import axios from 'axios';

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL;

const pageActions: Partial<BuilderProActions> = {
  changePage(_page: string) {
    this.setActiveComponent({
      component: null,
    });
    this.activePage = _page;
  },

  addPage ({ page, shouldCloneIndexPage }) {
    const components = _cloneDeep(this.epackData.components);
    if (!components[this.activeLocale][this.activeTemplate][page]) {
      components[this.activeLocale][this.activeTemplate][page] = shouldCloneIndexPage
        ? _cloneDeep(this.epackData.components[this.activeLocale][this.activeTemplate]['index.html']) || []
        : []
      this.activePage = page;
      this.activeComponent = null;
      this.epackData.components = components;
      this.onEpackDataUpdate();
    } else {
      throw new Error('A page with the given name already exists');
    }
  },

  deletePageLocal(page) {
    const components = _cloneDeep(this.epackData.components);
    delete components[this.activeLocale][this.activeTemplate][page];
    this.epackData.components = components;
    if (this.activePage === page) {
      this.activePage = Object.keys(components[this.activeLocale][this.activeTemplate])[0];
      this.activeComponent = null;
    }
    this.resetHistoryMulti({ locale: this.activeLocale, template: this.activeTemplate, page })
    this.onEpackDataUpdate({addToUpdated: false, epackSavedInBackend: true});
  },

  async deletePage({ page, epackId }) {
    return new Promise((resolve, reject) => {
      if (epackId) {
        axios
          .put(
            `${BASE_URL}/api/constructor/epackages/${epackId}/components`,
            {
              lang: this.activeLocale,
              template: this.activeTemplate,
              page: page,
              components: null,
            },
            {
              headers: {
                Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
              },
            }
          )
          .then((res) => {
            this.deletePageLocal(page);
            resolve(res);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      } else {
        this.deletePageLocal(page);
        resolve(true);
      }
    });
  },
};

export { pageActions as page };
