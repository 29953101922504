import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { FRIENDLY_COL_PICTURE } from "./base/col-picture";
import { FRIENDLY_DESCRIPTION } from "./description";
import { COMMON_PROPS } from "../../props";
import {cloneDeep} from "lodash";
import {EEditorSizes} from "@/store/builder-pro/consts";

export const FRIENDLY_IMAGE_TEXT_OVERLAY_LIST_ITEM: TComponent = {
  componentKey: EComponents.FRIENDLY_IMAGE_TEXT_OVERLAY_LIST_ITEM,
  name: EComponents.FRIENDLY_IMAGE_TEXT_OVERLAY_LIST_ITEM,
  type: EComponentTypes.COMPONENT,
  displayName: 'List Item',
  noSave: true,
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  props: {
    color: {
      name: 'Overlay Background',
      defaultValue: 'transparent',
      items: ['transparent', 'light', 'dark']
    },
    overlayWidth: {
      name: 'Overlay Width',
      defaultValue: '40%',
    },
    horizontalPosition: {
      name: 'Horizontal Position',
      defaultValue: 'middle',
      items: ['left', 'middle', 'right'],
    },
    verticalPosition: {
      name: 'Vertical Position',
      defaultValue: 'top',
      items: ['top', 'middle', 'bottom'],
    },
  },
  children: [
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left',
          value: {
            [EEditorSizes.MOBILE]: 'center'
          }
        },
        color: {
          ...FRIENDLY_DESCRIPTION.props!.color,
          defaultValue: '#000000'
        },
        fontWeight: {
          ...FRIENDLY_DESCRIPTION.props!.fontWeight,
          defaultValue: '700'
        }
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        color: {
          ...FRIENDLY_DESCRIPTION.props!.color,
          defaultValue: '#000000'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left',
          value: {
            [EEditorSizes.MOBILE]: 'center'
          }
        }
      }
    },
  ]
};

export const FRIENDLY_IMAGE_TEXT_OVERLAY_LIST: TComponent = {
  componentKey: EComponents.FRIENDLY_IMAGE_TEXT_OVERLAY_LIST,
  name: EComponents.FRIENDLY_IMAGE_TEXT_OVERLAY_LIST,
  type: EComponentTypes.COMPONENT,
  displayName: 'List',
  noSave: true,
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  children: [cloneDeep(FRIENDLY_IMAGE_TEXT_OVERLAY_LIST_ITEM)]
};

export const FRIENDLY_IMAGE_TEXT_OVERLAY: TComponent = {
  componentKey: EComponents.FRIENDLY_IMAGE_TEXT_OVERLAY,
  name: EComponents.FRIENDLY_IMAGE_TEXT_OVERLAY,
  type: EComponentTypes.FEATURE,
  displayName: 'Image & Text Overlay',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    backgroundColor: COMMON_PROPS.backgroundColor,
  },
  children: [
    {
      ...FRIENDLY_COL_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...FRIENDLY_COL_PICTURE.props,
        src: {
          ...FRIENDLY_COL_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/1416x708.jpg",
            mobile: "/static/constructor/placeholders/900x900.jpg",
          },
        },
      },
    },
    {...cloneDeep(FRIENDLY_IMAGE_TEXT_OVERLAY_LIST)},
  ]
};
