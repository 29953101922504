import {BuilderProActions, THistoryStep, THistoryStepAction} from "@/store/builder-pro/types";
import {HISTORY_ACTION_UPDATE_DELAY_MAP, HISTORY_STEP_LIMIT} from "@/store/builder-pro/consts";
import {HISTORY_UNDO_HANDLERS} from "@/store/builder-pro/actions/history/undo-handlers";
import {HISTORY_REDO_HANDLERS} from "@/store/builder-pro/actions/history/redo-handlers";
import {decorateComponents} from "@/store/builder-pro/utils";

const history: Partial<BuilderProActions> = {
  stepInHistory(type: 'undo' | 'redo') {
    if ((type === 'undo' && this.canUndo) || (type === 'redo' && this.canRedo)) {
      const currentStepIndex = this.history[this.activePagePathDecorated].position - (type === 'undo' ? 1 : 0);
      const currentStep = this.history[this.activePagePathDecorated].steps[currentStepIndex];
      if (currentStep) {
        this.history[this.activePagePathDecorated].position += type === 'undo' ? -1 : 1;

        currentStep.forEach(action => {
          if (type === 'undo') {
            HISTORY_UNDO_HANDLERS[action.type](action, this);
          } else {
            HISTORY_REDO_HANDLERS[action.type](action, this);
          }
        })
      }
    }
  },

  undo () {
    this.stepInHistory('undo');
  },

  redo () {
    this.stepInHistory('redo');
  },

  addToHistory (action: THistoryStepAction) {
    // reset history if the active page path doesn't exist in the history
    if (!this.history[this.activePagePathDecorated]) {
      this.resetHistory();
    }

    // if the action position is not the last step,
    //  then remove all the steps from the position until the end
    if (this.history[this.activePagePathDecorated].position !== this.history[this.activePagePathDecorated].steps.length) {
      this.history[this.activePagePathDecorated].steps.splice(
        this.history[this.activePagePathDecorated].position,
        this.history[this.activePagePathDecorated].steps.length - this.history[this.activePagePathDecorated].position
      )
    }

    const deltaTime = this.history[this.activePagePathDecorated].lastChangeTs && (Date.now() - this.history[this.activePagePathDecorated].lastChangeTs!);
    const isPastUpdateDelay = deltaTime === null || deltaTime >= HISTORY_ACTION_UPDATE_DELAY_MAP[action.type];
    let lastStep: THistoryStep | undefined = this.history[this.activePagePathDecorated].steps[this.history[this.activePagePathDecorated].steps.length - 1];

    // create a new history step if the last step doesn't exist, or it's past the update delay
    if (!lastStep || isPastUpdateDelay) {
      this.history[this.activePagePathDecorated].steps.push([]);
      lastStep = this.history[this.activePagePathDecorated].steps[this.history[this.activePagePathDecorated].steps.length - 1];
    }

    // add the new action to the last step in the history
    lastStep.push(action);

    // clamp the history (remove the first items) if it has more steps than the limit
    if (this.history[this.activePagePathDecorated].steps.length > HISTORY_STEP_LIMIT) {
      this.history[this.activePagePathDecorated].steps.splice(0, this.history[this.activePagePathDecorated].steps.length - HISTORY_STEP_LIMIT)
    }

    // update the position (always)
    //  and the lastChange timestamp (only if the last step was past the update delay)
    this.history[this.activePagePathDecorated].position = this.history[this.activePagePathDecorated].steps.length;
    if (isPastUpdateDelay) {
      this.history[this.activePagePathDecorated].lastChangeTs = Date.now();
    }
  },

  resetHistory (pagePathDecorated?: string) {
    if (this.history[pagePathDecorated || this.activePagePathDecorated]) {
      // case when the page path did exist in the history
      this.history[pagePathDecorated || this.activePagePathDecorated].position = 0;
      this.history[pagePathDecorated || this.activePagePathDecorated].lastChangeTs = null;
      this.history[pagePathDecorated || this.activePagePathDecorated].steps = [];
    } else {
      // case when the page path didn't exist in the history
      this.history[pagePathDecorated || this.activePagePathDecorated] = {
        position: 0,
        lastChangeTs: null,
        steps: [],
      }
    }
  },

  // resets all matching parts of page paths
  resetHistoryMulti (payload) {
    if (!payload) {
      this.history = {};
      return;
    }

    let historyKeyMatch = decorateComponents(payload.locale, payload.template, payload.page);
    const historyKeys = Object.keys(this.history).filter(key => key.startsWith(historyKeyMatch));
    historyKeys.forEach(key => {
      delete this.history[key];
    })
  }
};

export { history };
