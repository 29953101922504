import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { OZON_PROPS } from "../../props";
import { OZON_DESCRIPTION } from "./description";

export const OZON_TITLE_DESCRIPTION: TComponent = {
  componentKey: EComponents.OZON_TITLE_DESCRIPTION,
  name: EComponents.OZON_TITLE_DESCRIPTION,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Title & Description',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    textBlockTheme: OZON_PROPS.textBlockTheme,
    textBlockGapSize: OZON_PROPS.textBlockGapSize,
    textBlockPadding: OZON_PROPS.textBlockPadding,
  },
  children: [
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        content: {
          ...OZON_DESCRIPTION.props!.content,
          defaultValue: 'Title',
        },
        fontSize: {
          ...OZON_DESCRIPTION.props!.fontSize,
          defaultValue: '5',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left',
        },
      }
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        fontSize: {
          ...OZON_DESCRIPTION.props!.fontSize,
          defaultValue: '2',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left',
        },
      }
    },
  ]
};
