import {
  TComponent,
  THistoryStepAction,
  THistoryStepActionReorderComponent,
  TStore
} from "@/store/builder-pro/types";

const reorderComponentsGetComponentAndDesiredParentChildren = (action: THistoryStepAction, store: TStore) => {
  let component: TComponent | undefined;
  const desiredParentChildren: TComponent[] = [];

  const components = store.addedComponents;

  const desiredRawParentChildrenMap = (action as THistoryStepActionReorderComponent).desiredRawParentChildrenWithoutDraggedComponent
    .reduce<Record<string, TComponent>>((acc, item) => ({
      ...acc,
      [item.id!]: item
    }), {})

  for (let i = 0; i < components.length; i += 1) {
    if ((action as THistoryStepActionReorderComponent).componentId === components[i].id) {
      component = components[i];
    }
    if (desiredRawParentChildrenMap[components[i].id!]) {
      desiredParentChildren.push(components[i]);
    }

    if (component && desiredParentChildren.length === (action as THistoryStepActionReorderComponent).desiredRawParentChildrenWithoutDraggedComponent.length) {
      break;
    }
  }

  return {
    component,
    desiredParentChildren,
    desiredRawParentChildrenMap,
    foundAllDesiredParentChildren:
      desiredParentChildren.length === (action as THistoryStepActionReorderComponent).desiredRawParentChildrenWithoutDraggedComponent.length
  }
}

export { reorderComponentsGetComponentAndDesiredParentChildren }
