import { TComponent, TComponentProp } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";
import { TEXT } from './text';
import { IMAGE } from './image';

export const BUBBLE: TComponent = {
  componentKey: EComponents.BUBBLE,
  name: EComponents.BUBBLE,
  type: EComponentTypes.COMPONENT,
  withChildren: true,
  nonDraggable: true,
  possibleParents: [EComponents.BUBBLES],
  props: {
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '30px',
    },
    top: {
      inputType: 'number',
      name: 'Bubble Position Top',
      defaultValue: '24',
    },
    left: {
      inputType: 'number',
      name: 'Bubble Position Left',
      defaultValue: '72',
    },
    modalPosition: {
      name: 'Bubble Modal Position',
      defaultValue: 'left-bottom',
      items: ['left-bottom', 'left-top', 'right-bottom', 'right-top'],
    },
  },
  children: [
    {
      ...TEXT,
      props: {
        ...TEXT.props,
        content: {
          ...TEXT.props!.content,
          defaultValue: 'This is an example of a description',
        },
        fontSize: {
          ...TEXT.props!.fontSize,
          defaultValue: '4',
        },
        fontWeight: {
          ...TEXT.props!.fontWeight,
          defaultValue: '700',
        },
        textAlign: {
          ...TEXT.props!.textAlign,
          defaultValue: 'left',
        },
      },
    },
    {
      ...TEXT,
      props: {
        ...TEXT.props,
        content: {
          ...TEXT.props!.content,
          defaultValue: 'This is an example of a description. Here the font is smaller and there is more content.',
        },
        fontSize: {
          ...TEXT.props!.fontSize,
          defaultValue: '2',
        },
        textAlign: {
          ...TEXT.props!.textAlign,
          defaultValue: 'left',
        },
      },
    },
  ],
};

export const BUBBLES: TComponent = {
  componentKey: EComponents.BUBBLES,
  name: EComponents.BUBBLES,
  type: EComponentTypes.COMPONENT,
  possibleChildren: [EComponents.BUBBLE],
  props: {
    image: {
      ...IMAGE.props!.src,
    } as TComponentProp,
    ...COMMON_PROPS,
  },
  children: [
    {
      ...BUBBLE,
    },
  ],
};
