import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";

export const FRIENDLY_VIDEO: TComponent = {
  componentKey: EComponents.FRIENDLY_VIDEO,
  name: EComponents.FRIENDLY_VIDEO,
  type: EComponentTypes.FEATURE,
  displayName: 'Video',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    src: {
      fileType: "video",
      name: "Video",
      defaultValue: "/static/constructor/placeholders/video.mp4",
    },
    poster: {
      name: "Poster",
      defaultValue: null,
    },
    loop: {
      name: "Repeat",
      defaultValue: "off",
      items: ["off", "on"],
    },
    controls: {
      name: "Controls",
      defaultValue: "Off",
      items: ["off", "on"],
    },
    autoplay: {
      name: "Autoplay",
      defaultValue: "Off",
      items: ["off", "on"],
    },
    backgroundColor: {
      name: "Background color",
      defaultValue: "",
    },
    borderRadius: {
      name: "Border radius",
      defaultValue: "",
    },
  },
};
