import {TComponent} from "@/store/builder-pro/types";

type TFlattenComponentsOptions = {
  initialId?: number;
}

type TFlattenComponentsInternalPayload = {
  id: number;
}

const flattenComponentsInternal = (
  components: TComponent[],
  options: TFlattenComponentsOptions | undefined,
  internalPayload: TFlattenComponentsInternalPayload,
  parentId = 0
): TComponent[] => {
  const flatComponents: TComponent[] = [];

  components.forEach((component, index) => {
    const {
      id: unusedId,
      parentId: unusedParentId,
      order: unusedOrder,
      children,
      ...rawComponent
    } = component;

    const componentToAdd = {
      ...rawComponent,
      id: internalPayload.id + 1,
      parentId: parentId,
      order: index + 1,
    }

    flatComponents.push(componentToAdd);

    internalPayload.id += 1;

    // handle the children
    if (children) {
      flatComponents.push(...flattenComponentsInternal(children, options, internalPayload, componentToAdd.id))
    }
  })

  return flatComponents
};

const flattenComponents = (components: TComponent[], options?: TFlattenComponentsOptions): TComponent[] => {
  const internalPayload = {
    id: options?.initialId || 0
  }

  return flattenComponentsInternal(components, options, internalPayload)
}

export { flattenComponents }
