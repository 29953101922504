const deriveValueFromProp = (valueParam: any, size: string) => {
  if (typeof valueParam === 'string') {
    return valueParam;
  } else if (typeof valueParam === 'object') {
    return valueParam[size];
  }
  return undefined;
};

export { deriveValueFromProp };
