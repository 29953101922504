import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {FRIENDLY_PICTURE} from "./picture";
import {FRIENDLY_DESCRIPTION} from "./description";

export const FRIENDLY_SINGLE_LEFT_IMAGE: TComponent = {
  componentKey: EComponents.FRIENDLY_SINGLE_LEFT_IMAGE,
  name: EComponents.FRIENDLY_SINGLE_LEFT_IMAGE,
  type: EComponentTypes.FEATURE,
  displayName: 'Single Left Image',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    mediaColumnWidth: {
      name: 'Media Column Width',
      defaultValue: '50%',
      items: ['25%', '50%', '75%']
    },
    backgroundColor: {
      name: "Background color",
      defaultValue: "",
    },
  },
  children: [
    {
      ...FRIENDLY_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...FRIENDLY_PICTURE.props,
        src: {
          ...FRIENDLY_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/708x708.jpg",
            mobile: "/static/constructor/placeholders/900x900.jpg",
          },
        },
      },
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '6'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '4'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
  ]
};
