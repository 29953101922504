import axios from "axios";
import dayjs from "dayjs";
import _cloneDeep from "lodash/cloneDeep";
import _ from "lodash";
import {
  BuilderProActions,
  TComponent, TEpackData,
  TEpackDataProductDataMinisite, TEpackDataProductDataSis,
  TState
} from "@/store/builder-pro/types";
import {
  DATE_FORMAT,
  DEFAULT_EPACK_DATA,
  DEFAULT_STATE, EDITOR_SIZES_LIST,
  EEditorTabs
} from "@/store/builder-pro/consts";
import {EEpackDataTypes, EPACK_DATA_TYPES} from "@/store/builder-pro/consts/epack-data-types";
import {decorateComponents, syncComponents, SPECIAL_COMPONENTS_STRING} from "@/store/builder-pro/utils";

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL;

const request = async (
  axiosRequest: any,
  resolve: (value?: any) => void,
  reject: (value?: any) => void,
) => (
  axiosRequest
    .then((res: any) => {
      resolve(res);
    })
    .catch((e: any) => {
      reject(e);
    })
);

const epack: Partial<BuilderProActions> = {
  onEpackDataUpdate(payload) {
    const {
      addToUpdated,
      epackSavedInBackend
    } = {
      addToUpdated: true,
      ...payload,
    };

    if (!epackSavedInBackend) this.epackSavedInBackend = false
    this.epackData.manifest.updatedDate = dayjs().format(DATE_FORMAT)
    if (addToUpdated) {
      if (addToUpdated === true) {
        this.addToUpdatedComponents(decorateComponents(this.activeLocale, this.activeTemplate, this.activePage))
      } else if (addToUpdated.template) {
        Object.keys(this.epackData.components[addToUpdated.locale]?.[addToUpdated.template] || {}).forEach(page => {
          if (this.epackData.components[addToUpdated.locale][addToUpdated.template!][page]) {
            this.addToUpdatedComponents(decorateComponents(addToUpdated.locale, addToUpdated.template!, page))
          }
        })
      } else {
        // only locale is passed
        Object.keys(this.epackData.components[addToUpdated.locale] || {}).forEach(template => {
          Object.keys(this.epackData.components[addToUpdated.locale][template] || {}).forEach(page => {
            this.addToUpdatedComponents(decorateComponents(addToUpdated.locale, template, page))
          })
        })
      }
    }
  },

  // Used for creating new locales and templates through backend
  async createSection(payload) {
    this.loading = true;

    const { id, ...requestData } = payload;

    try {
      const createSectionResponse = await axios.post(`${BASE_URL}/api/constructor/epackages/${id}/copy-section`, {
        toId: id,
        ...requestData,
      }, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
        },
      })

      const newStructure: TEpackData['components'] = createSectionResponse?.data?.data?.components || {};
      const {toLang: updatedLocale, toTemplate: updatedTemplate} = requestData;

      // keeping in mind that createSection only creates new locales/templates,
      //  therefore updatedComponents are not going to be overwritten
      if (newStructure[updatedLocale]) {
        if (updatedLocale && updatedTemplate && newStructure[updatedLocale][updatedTemplate]) {
          this.epackData.components[updatedLocale] = {
            ...this.epackData.components[updatedLocale],
            [updatedTemplate]: newStructure[updatedLocale][updatedTemplate],
          }
        } else {
          this.epackData.components[updatedLocale] = newStructure[updatedLocale]
        }
      }
    } catch (error) {
      throw error;
    } finally {
      this.loading = false;
    }
  },

  async copySection (payload) { // { epackIdFrom, epackId, lang, template, page }
    return new Promise((resolve, reject) => {
      axios.post(
        `${BASE_URL}/api/constructor/epackages/${payload.epackIdFrom || payload.epackId}/copy-section`, {
          toId: payload.epackId,
          fromLang: payload.lang,
          toLang: this.activeLocale,
          fromTemplate: payload.template,
          toTemplate: payload.template && this.activeTemplate,
          fromPage: payload.page,
          toPage: payload.page && this.activePage,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        })
        .then(async (res) => {
          this.onEpackDataUpdate({ addToUpdated: false, epackSavedInBackend: true });
          this.updatedComponents = [];
          this.loadedComponents = [];
          this.resetHistoryMulti();

          // sync template and page with the copied section
          //  (if the section doesn't have the active locale or template or page, then reset them)
          const newStructure = res.data?.data?.components || {};
          const newStructureLocales = Object.keys(newStructure);
          const newStructureLocale0Templates = Object.keys(newStructure[newStructureLocales[0]] || {});
          const newStructureLocale0Template0Pages = Object.keys(newStructure[newStructureLocales[0]]?.[newStructureLocale0Templates[0]] || {});
          if (
            newStructureLocales.length
            && newStructureLocale0Templates.length
            && newStructureLocale0Template0Pages.length
          ) {
            if (!newStructure[this.activeLocale]) {
              this.activeLocale = newStructureLocales[0];
              this.activeTemplate = newStructureLocale0Templates[0];
              this.activePage = newStructureLocale0Template0Pages[0];
            } else if (!newStructure[this.activeLocale][this.activeTemplate]) {
              this.activeTemplate = Object.keys(newStructure[this.activeLocale])[0];
              this.activePage = Object.keys(newStructure[this.activeLocale][this.activeTemplate])[0];
            } else if (!newStructure[this.activeLocale][this.activeTemplate][this.activePage]) {
              this.activePage = Object.keys(newStructure[this.activeLocale][this.activeTemplate])[0];
            }
          }

          await this.loadComponents({
            epackId: payload.epackId,
            lang: this.activeLocale,
            template: this.activeTemplate,
            page: this.activePage,
            force: true,
          })

          resolve(true)
        })
        .catch(e => {
          reject(e)
        })
    })
  },

  /* *
   * Если есть id и он актуальный, ставит данные из бэка.
   * А если нет, то остаются default-ные данные.
   * */
  async getEpackage({ id, setIntoCurrent = false }) {
    let epackBackend: TState | { structure: TState } | undefined;

    if (id) {
      await axios
        .get(`${BASE_URL}/api/constructor/epackages/${id}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
          },
        })
        .then((res) => {
          epackBackend = res.data.data;
          this.activeEpackage = id;
          this.files = res.data.data?.files || [];
        })
        .catch((e) => {
          console.error(e);
        });
    }

    if (epackBackend) {
      if (!setIntoCurrent) {
        return epackBackend;
      }
      epackBackend = (epackBackend as { structure: TState }).structure;
    }

    if (setIntoCurrent) {
      let isDefault = false;
      if (epackBackend) {
        this.epackData = epackBackend.epackData;
        this.epackSavedInBackend = true;
        this.updatedComponents = [];
      } else {
        isDefault = true;
        this.epackSavedInBackend = false;
        this.activeEpackage = null;
        const manifest = _cloneDeep(DEFAULT_EPACK_DATA.manifest);
        try {
          manifest.author = JSON.parse(localStorage.getItem("ttlUserInfo") || "{}")?.username || "";
          // eslint-disable-next-line no-empty
        } catch {
        }
        manifest.createdDate = dayjs().format(DATE_FORMAT);
        manifest.updatedDate = dayjs().format(DATE_FORMAT);
        this.epackData = _cloneDeep({...DEFAULT_EPACK_DATA, manifest});
        this.updatedComponents = [];
      }

      // if the epackage is not default, traverse through all components and sync props
      if (!isDefault) {
        // custom-components
        syncComponents(this.epackData.customComponents);
        if (!this.epackData.type) this.epackData.type = EEpackDataTypes.sis;
        if (this.epackData.components instanceof Array) this.epackData.components = _cloneDeep(DEFAULT_EPACK_DATA.components);
      }

      // show Epack Data tab if creating a new epackage, otherwise show the Constructor tab
      this.activeTab = isDefault ? EEditorTabs.EPACK_DATA : EEditorTabs.EDITOR;
      // set default values
      this.activeSize = DEFAULT_STATE.activeSize;
      this.activeTemplate = DEFAULT_STATE.activeTemplate;
      this.activePage = DEFAULT_STATE.activePage;
      this.setActiveComponent({
        component: null
      })
      this.loadedComponents = [];

      // set the initial locale
      const epackLocales = Object.keys(this.epackData.components || {});
      if (epackLocales.length && !epackLocales.includes(this.activeLocale)) {
        this.activeLocale = epackLocales[0];
      } else {
        this.activeLocale = DEFAULT_STATE.activeLocale;
      }
    }

    return epackBackend;
  },

  async getEpackages({ page, perPage, search, type, }) {
    return new Promise((resolve, reject) => {
      request(axios.get(
        `${BASE_URL}/api/constructor/epackages?${
          search
            ? `criteria[search]=${search}&`
            : ''
        }${
          type
            ? `criteria[type]=${type}&`
            : ''
        }offset=${(page - 1) * perPage}&limit=${perPage}`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  },

  async deleteEpackage(id) {
    return new Promise((resolve, reject) => {
      request(axios.delete(
        `${BASE_URL}/api/constructor/epackages/${id}`,
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  },

  async downloadEpackage(id) {
    return new Promise((resolve, reject) => {
      axios({
        url: `${BASE_URL}/api/constructor/epackages/${id}/download`,
        method: 'GET',
        responseType: 'blob',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        }
      }).then((response) => {
        const url = window.URL.createObjectURL(new Blob([response.data]));
        const link = document.createElement('a');
        link.style.display = 'none'
        link.href = url;
        link.setAttribute('download', `epack-${id}.zip`);
        document.body.appendChild(link);
        link.click();
        resolve(true)
      }).catch(e => {
        reject(e)
      })
    })
  },

  async copyEpackage(id) {
    return new Promise((resolve, reject) => {
      request(axios.post(
        `${BASE_URL}/api/constructor/epackages/${id}/copy`, {}, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  },

  async publishEpackage(id, licenseId) {
    return new Promise((resolve, reject) => {
      axios
        .post(`${BASE_URL}/api/constructor/epackages/${id}/publish-to-stream`, licenseId ? { licenseId } :undefined, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then(() => {
          resolve(true);
        })
        .catch((e) => {
          reject(e);
        });
    });
  },

  async transferOwnership({ epackageIds, userId }) {
    return new Promise((resolve, reject) => {
      request(axios.put(
        `${BASE_URL}/api/constructor/epackages/change-owner`, {
          userId,
          epackageIds,
        }, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  },

  async editEpacksProps(epackId, props) {
    return new Promise((resolve, reject) => {
      request(axios.put(
        `${BASE_URL}/api/constructor/epackages/${epackId}/edit-props`,
        props, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      ), resolve, reject)
    })
  },

  /* *
    * Создает/Редактирует Epackage в зависимости от наличия id
   **/
  async saveEpackage(id) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const {
        components,
        templateConfiguration,
        ...epackData
      } = this.epackData
      let epackId: string | undefined
      let error: any
      await axios[id ? 'put' : 'post'](
        `${BASE_URL}/api/constructor/epackages${id ? `/${id}` : ''}`,
        {
          structure: {
            activeEpackage: this.activeEpackage,
            epackData,
          }
        },
        {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          }
        }
      )
        .then((res) => {
          if (!id) {
            this.activeEpackage = res.data?.data?.id || null
            epackId = res.data?.data?.id || null
          } else {
            epackId = id
          }
        })
        .catch(e => {
          console.error(e)
          error = e
        })

      if (!error && epackId) {
        for (let i = 0; i < this.updatedComponents.length; i += 1) {
          const updatedComponent = this.updatedComponents[i];
          // eslint-disable-next-line prefer-const
          let [_locale, _template, _page]: string[] = updatedComponent.split(SPECIAL_COMPONENTS_STRING)
          const locale: string | null = _locale;
          let template: string | null = _template;
          let page: string | null = _page;
          let currentComponents: TComponent[] | null

          if (!components[locale]) {
            template = null
            page = null
            currentComponents = null
          } else if (!components[locale][template]) {
            page = null
            currentComponents = null
          } else if (!components[locale][template][page]) {
            currentComponents = null
          } else {
            currentComponents = components[locale][template][page] ? _cloneDeep(components[locale][template][page]) : null
            // removing empty size values from value
            if (currentComponents) {
              // creating a map of ids to remove all components with non-existent parents
              const idsMap: Record<string, boolean> = {};

              // going through the current components
              currentComponents.forEach((component) => {
                if (component.id) {
                  idsMap[component.id] = true;
                }
                if (component.props) {
                  Object.entries(component.props).forEach(([, prop]) => {
                    if (prop?.value) {
                      EDITOR_SIZES_LIST.forEach(({ key: size }) => {
                        if (
                          typeof prop.value[size] !== 'boolean' &&
                          typeof prop.value[size] !== 'number' &&
                          _.isEmpty(prop.value[size])
                        ) {
                          delete prop.value[size]
                        }
                      })
                    }
                    if (prop && _.isEmpty(prop.value)) {
                      delete prop.value
                    }
                  })
                }
              })

              // removing components with non-existent id or parent
              for (let i = currentComponents.length - 1; i >= 0; i--) {
                if (!currentComponents[i].id || (currentComponents[i].parentId && !idsMap[currentComponents[i].parentId!])) {
                  currentComponents.splice(i, 1);
                }
              }

              syncComponents(currentComponents);
            }
          }

          let currentTemplateConfiguration: Record<string, any> | null = null

          if (template) {
            currentTemplateConfiguration = {}
            const templateConfigurationProps = _cloneDeep(templateConfiguration?.[locale]?.[template])
            for (const key in templateConfigurationProps) {
              const value = templateConfigurationProps[key].value
              currentTemplateConfiguration[key] = value || ''
            }
          }

          await axios
            .put(
              `${BASE_URL}/api/constructor/epackages/${epackId}/components`,
              {
                lang: locale,
                template: template,
                page: page,
                components: currentComponents,
                ...(currentTemplateConfiguration
                  ? { templateConfiguration: currentTemplateConfiguration }
                  : {}
                )
              },
              {
                headers: {
                  Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
                }
              }
            )
            .then(() => {
              this.loadComponents({
                force: true,
                epackId: epackId as string,
                lang: locale,
                template: template as string,
                page: page as string
              })
            })
            .catch(e => {
              error = e
              console.error(e)
            })
          }
      }

      if (!error) {
        resolve(epackId)
        this.updatedComponents = [];
        this.epackSavedInBackend = true
      } else {
        if (epackId) {
          error.epackId = epackId;
        }
        reject(error)
      }
    })
  },

  updateEpackDataManifest(payload) {
    this.epackData = {
      ...this.epackData,
      manifest: {
        ...this.epackData.manifest,
        ...(payload.isProductData ? {
          productData: {
            ...this.epackData.manifest.productData,
            [payload.propKey]: payload.propValue
          }
        } : {
          [payload.propKey]: payload.propValue
        })
      }
    }
    this.onEpackDataUpdate()
  },

  changeEpackDataType(type) {
    if ([EPACK_DATA_TYPES.sis.key, EPACK_DATA_TYPES.minisite.key].includes(type)) {
      if (type === EPACK_DATA_TYPES.sis.key) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { MPN, EAN, categories, ...productData } = this.epackData.manifest.productData as TEpackDataProductDataMinisite
        this.epackData.manifest.productData = {
          ...productData,
          mappingId: Date.now().toString()
        }
      } else if (type === EPACK_DATA_TYPES.minisite.key) {
        // eslint-disable-next-line @typescript-eslint/no-unused-vars
        const { mappingId, ...productData } = this.epackData.manifest.productData as TEpackDataProductDataSis
        this.epackData.manifest.productData = {
          ...productData,
          EAN: '',
          MPN: '',
          categories: []
        }
      }
      this.epackData.type = type
      this.onEpackDataUpdate({addToUpdated: false})
    }
  },
};

export { epack };
