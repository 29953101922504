import _cloneDeep from 'lodash/cloneDeep'
import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { OZON_PROPS } from "../../props";
import { OZON_DESCRIPTION } from "./description";
import { OZON_PICTURE } from "./base/picture";

export const OZON_COMPARISON_CHART_COL_IMAGE: TComponent = {
  componentKey: EComponents.OZON_COMPARISON_CHART_COL_IMAGE,
  name: EComponents.OZON_COMPARISON_CHART_COL_IMAGE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Image Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.OZON_COMPARISON_CHART_ROW],
  props: {
    altText: OZON_PICTURE.props!.altText,
    objectFit: OZON_PROPS.objectFit,
    showBackground: OZON_PROPS.showBackground,
    src: {
      ...OZON_PICTURE.props!.src,
      defaultValue: {
        desktop: "/static/constructor/placeholders/200x200.jpg",
        mobile: "/static/constructor/placeholders/200x200.jpg",
      },
    },
    url: OZON_PICTURE.props!.url
  },
}

export const OZON_COMPARISON_CHART_COL_TEXT: TComponent = {
  componentKey: EComponents.OZON_COMPARISON_CHART_COL_TEXT,
  name: EComponents.OZON_COMPARISON_CHART_COL_TEXT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Text Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.OZON_COMPARISON_CHART_ROW],
  props: {
    content: {
      ...OZON_DESCRIPTION.props!.content,
      defaultValue: 'Example text',
    },
    textAlign: {
      ...OZON_DESCRIPTION.props!.textAlign,
      defaultValue: 'left'
    }
  }
}

export const OZON_COMPARISON_CHART_ROW: TComponent = {
  componentKey: EComponents.OZON_COMPARISON_CHART_ROW,
  name: EComponents.OZON_COMPARISON_CHART_ROW,
  type: EComponentTypes.COMPONENT,
  displayName: 'Row',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.OZON_COMPARISON_CHART_TABLE]
}

export const OZON_COMPARISON_CHART_TABLE: TComponent = {
  componentKey: EComponents.OZON_COMPARISON_CHART_TABLE,
  name: EComponents.OZON_COMPARISON_CHART_TABLE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Table',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.OZON_COMPARISON_CHART]
}

export const OZON_COMPARISON_CHART: TComponent = {
  componentKey: EComponents.OZON_COMPARISON_CHART,
  name: EComponents.OZON_COMPARISON_CHART,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Comparison Chart',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        content: {
          ...OZON_DESCRIPTION.props!.content,
          defaultValue: 'Title',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...OZON_COMPARISON_CHART_TABLE,
      children: [
        {
          ...OZON_COMPARISON_CHART_ROW,
          children: Array.from({ length: 3 }, () => _cloneDeep(OZON_COMPARISON_CHART_COL_IMAGE))
        },
        ...Array.from({ length: 5 }, () => ({
          ...OZON_COMPARISON_CHART_ROW,
          children: Array.from({ length: 3 }, () => _cloneDeep(OZON_COMPARISON_CHART_COL_TEXT))
        })),
      ],
    },
  ],
};

