import { defineStore } from 'pinia';
import { Group, LicenseShort, LoginData, Permission, RetailerShort, State, UpdateProfile } from '@/store/auth/types';
import axios from '@/plugins/axios';
import router from '@/router/index';
import { getUniqArrayOfObjects, listToTree } from '@/helpers/HelperObject';

export const useAuthStore = defineStore('auth', {
  state(): State {
    return {
      isAdmin: false,
      profile: null,
      retailers: [],
      amazonRedirectData: {
        amazonCallbackUri: undefined,
        amazonState: undefined,
        sellingPartnerId: undefined,
      },
      profileFetched: false,
    };
  },
  actions: {
    async getUserProfile() {
      return new Promise((resolve, reject) => {
        axios
          .get('/profile')
          .then((res) => {
            this.profile = res.data;
            if (res.data.roles.includes('ROLE_ADMIN')) {
              this.isAdmin = true;
              Promise.allSettled([
                axios.get('/retailers/short-list').then((retailersRes) => {
                  if (this.profile) this.profile.retailers = retailersRes.data.data;
                }),
                axios.get('/licenses/short-list').then((licensesRes) => {
                  if (this.profile) this.profile.licenses = licensesRes.data.data;
                }),
              ]).finally(() => {
                this.profileFetched = true;
                resolve(res.data);
              });
            } else {
              this.isAdmin = false;
              Promise.allSettled([
                axios.get('/retailers/short-list').then((retailersRes) => {
                  if (this.profile) this.profile.retailers = retailersRes.data.data;
                }),
                axios.get('/licenses/short-list').then((licensesRes) => {
                  if (this.profile) this.profile.licenses = licensesRes.data.data;
                }),
                axios.get('/profile/statistics-groups').then((groups) => {
                  let permissions = [] as Permission[];
                  groups.data.data.forEach((group: Group) => {
                    permissions = getUniqArrayOfObjects(group.permissions, permissions);
                  });
                  if (this.profile) {
                    this.profile.permissions = permissions;
                  }
                }),
                this.getLicensesAndRetailer(),
              ]).finally(() => {
                this.fetchCategories().then(() => {
                  this.profileFetched = true;
                });
                resolve(res.data);
              });
            }
          })
          .catch((e) => {
            console.error(e);
            this.profileFetched = true;
            reject(e);
          });
      });
    },
    async fetchCategories(licenses?: LicenseShort[]) {
      return new Promise((resolve, reject) => {
        if (licenses && licenses.length) {
          const listOfLicenseKeys = licenses.map((item) => item.id).join(',') || '';
          axios
            .get(`/category/tree-for-licenses/${listOfLicenseKeys}`)
            .then((categories) => {
              if (this.profile) {
                this.profile.categories = listToTree(categories.data.data);
                this.profile.initialCategories = categories.data.data;
              }
              resolve(true);
            })
            .catch((e) => reject(e));
        } else {
          const listOfLicenseKeys = this.profile?.licenses?.map((item) => item.id).join(',') || '';
          axios
            .get(`/category/tree-for-licenses/${listOfLicenseKeys}`)
            .then((categories) => {
              if (this.profile) {
                this.profile.categories = listToTree(categories.data.data);
                this.profile.initialCategories = categories.data.data;
              }
              resolve(true);
            })
            .catch((e) => reject(e));
        }
      });
    },
    async login(data: LoginData) {
      return new Promise((resolve, reject) => {
        axios
          .post('/auth/getToken', data)
          .then((res) => {
            localStorage.setItem('accessToken', res.data.token);
            axios
              .post(`${process.env.VUE_APP_CONSTRUCTOR_API_URL}/login_check`, data)
              .then((response) => {
                if (response.data.token) {
                  localStorage.setItem('builderAccessToken', response.data.token);
                  resolve(true);
                }
              })
              .catch((e) => {
                reject(e);
              });
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    logout(withRouter = true) {
      localStorage.removeItem('accessToken');
      localStorage.removeItem('builderAccessToken');
      localStorage.removeItem('redirectUrl');
      this.isAdmin = false;
      this.profile = null;
      if (withRouter) {
        router.push({ name: 'Login' });
      }
    },
    updateProfile(data: UpdateProfile) {
      return new Promise((resolve, reject) => {
        axios
          .put('/profile/update-self', data)
          .then((response) => {
            const { email, firstName, lastName, username }: UpdateProfile = response.data.data;
            if (this.profile) {
              this.profile = {
                ...this.profile,
                email,
                firstName,
                lastName,
                username,
              };
            }

            resolve(true);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    updatePassword(password: string) {
      return new Promise((resolve, reject) => {
        axios
          .put('/profile/change-password', { password })
          .then(() => {
            resolve(true);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
    getLicensesAndRetailer() {
      return new Promise((resolve, reject) => {
        axios
          .get('/licenses/info-list')
          .then((res) => {
            const data = res.data.data;
            const retailers: (RetailerShort & {
              license: LicenseShort;
            })[] = [];
            data?.licenses?.forEach(
              (
                license: LicenseShort & {
                  retailers: RetailerShort[];
                }
              ) => {
                retailers.push(
                  ...license.retailers.map((rit) => ({
                    id: rit.id,
                    name: rit.name,
                    license: {
                      id: license.id,
                      name: license.name,
                    },
                  }))
                );
              }
            );
            this.retailers = retailers;
            resolve(data);
          })
          .catch((e) => {
            console.error(e);
            reject(e);
          });
      });
    },
  },
});
