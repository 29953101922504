import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS, TEXT_PROPS } from '../../props';
import { TEXT } from '../text';

export const DEFAULT_FORM_LABEL: TComponent = {
  ...TEXT,
  customRender: true,
  displayName: 'Label',
  props: {
    ...TEXT.props,
    margin: {
      ...TEXT.props!.margin,
      defaultValue: '0 0 0 6px',
    },
    textAlign: {
      ...TEXT_PROPS.textAlign,
      defaultValue: 'left',
    },
    width: {
      ...COMMON_PROPS.width,
      defaultValue: 'fit-content',
    },
  },
};
