import { TComponentProps } from '@/store/builder-pro/types';

export const COMMON_PROPS: TComponentProps = {
  hide: {
    name: 'Hidden',
    defaultValue: 'off',
    items: ['on', 'off'],
  },
  width: {
    name: 'Width',
    defaultValue: '',
  },
  height: {
    name: 'Height',
    defaultValue: '',
  },
  minWidth: {
    defaultValue: '',
  },
  maxWidth: {
    defaultValue: '',
  },
  minHeight: {
    defaultValue: '',
  },
  maxHeight: {
    defaultValue: '',
  },
  margin: {
    name: 'Margin',
    defaultValue: '',
  },
  padding: {
    name: 'Padding',
    defaultValue: '',
  },
  border: {
    name: 'Border',
    defaultValue: '',
  },
  borderRadius: {
    name: 'Border radius',
    defaultValue: '',
  },
  borderTop: {
    name: 'Border top',
    defaultValue: '',
  },
  borderRight: {
    name: 'Border right',
    defaultValue: '',
  },
  borderBottom: {
    name: 'Border bottom',
    defaultValue: '',
  },
  borderLeft: {
    name: 'Border left',
    defaultValue: '',
  },
  background: {
    name: 'Background',
    defaultValue: '',
  },
  backgroundColor: {
    name: 'Background color',
    defaultValue: '',
  },
  backgroundImage: {
    name: 'Background Image',
    defaultValue: '',
  },
  backgroundRepeat: {
    name: 'Background Repeat',
    defaultValue: 'repeat',
    items: ['repeat', 'no-repeat'],
  },
  backgroundPosition: {
    name: 'Background Position',
    defaultValue: '',
  },
  backgroundSize: {
    name: 'Background Size',
    defaultValue: '',
  },
  cursor: {
    name: 'Cursor',
    defaultValue: undefined,
    clearable: true,
    items: [
      'auto',
      'pointer',
      'not-allowed',
      'copy',
      'progress',
      'text',
      'wait',
      'grab',
      'move',
      'zoom-in',
      'zoom-out',
    ],
  },
  attributes: {
    name: 'Data Attributes',
    defaultValue: [], // will be key-value objects, e.g. {key: 'data-smth', value: 'smth-else'}
  },
  cssStop: {
    name: 'Ignore global style',
    defaultValue: 'off',
    items: ['on', 'off'],
  },
  classNames: {
    name: 'Class names',
    defaultValue: '',
  },
  transition: {
    name: 'Transition',
    defaultValue: '',
  },
  availableToForms: {
    name: 'Make available to forms',
    defaultValue: 'off',
    items: ['on', 'off'],
  },
};
