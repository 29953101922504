import {TGroupsebTemplateConfig} from "@/store/builder-pro/types";

const WMF_TEMPLATE_CONFIG: TGroupsebTemplateConfig = {
  propManipulations: [
    {
      propKey: 'fontFamily',
      replaceValue: true,
      replaceDefaultValue: true,
      newValue: 'Rotis'
    }
  ]
}

export { WMF_TEMPLATE_CONFIG }
