import _cloneDeep from 'lodash/cloneDeep'
import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {OZON_PICTURE} from "./base/picture";

export const OZON_PICTURE_WITHOUT_MARGINS_LIST_ITEM: TComponent = {
  ...OZON_PICTURE,
  props: {
    ...OZON_PICTURE.props,
    objectFit: undefined,
  },
  type: EComponentTypes.COMPONENT,
  componentKey: EComponents.OZON_PICTURE_WITHOUT_MARGINS_LIST_ITEM,
  name: EComponents.OZON_PICTURE_WITHOUT_MARGINS_LIST_ITEM,
  displayName: 'Picture',
  requiredUpperElements: [EComponents.OZON_PICTURE_WITHOUT_MARGINS],
};

export const OZON_PICTURE_WITHOUT_MARGINS: TComponent = {
  componentKey: EComponents.OZON_PICTURE_WITHOUT_MARGINS,
  name: EComponents.OZON_PICTURE_WITHOUT_MARGINS,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Picture Without Margins',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    _cloneDeep(OZON_PICTURE_WITHOUT_MARGINS_LIST_ITEM),
  ]
};
