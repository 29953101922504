import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS, POSITIONING_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";
import { TEXT } from './text';
import { COLUMN } from './column';

export const POPOVER_HEADER: TComponent = {
  componentKey: EComponents.POPOVER_HEADER,
  name: EComponents.POPOVER_HEADER,
  type: EComponentTypes.COMPONENT,
  displayName: 'Popover Header',
  noManualRemove: true,
  nonDraggable: true,
  withChildren: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined,
  },
  children: [
    {
      ...TEXT,
    },
  ],
};

export const POPOVER_CONTENT: TComponent = {
  componentKey: EComponents.POPOVER_CONTENT,
  name: EComponents.POPOVER_CONTENT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Popover Content',
  noManualRemove: true,
  nonDraggable: true,
  withChildren: true,
  props: {
    ...COLUMN.props,
    ...COMMON_PROPS,
    hide: undefined,
  },
  children: new Array(3).fill({
    ...TEXT,
    props: {
      ...TEXT.props,
      margin: {
        ...TEXT.props!.margin,
        defaultValue: '10px 0',
      },
    },
  }),
};

export const POPOVER: TComponent = {
  componentKey: EComponents.POPOVER,
  name: EComponents.POPOVER,
  type: EComponentTypes.COMPONENT,
  staticChildren: true,
  props: {
    trigger: {
      name: 'Trigger',
      defaultValue: 'hover',
      items: ['hover', 'click'],
    },
    triggered: {
      name: 'Triggered (for visual)',
      defaultValue: 'off',
      items: ['on', 'off'],
    },
    ...COMMON_PROPS,
    ...POSITIONING_PROPS,
    position: {
      ...POSITIONING_PROPS.position,
      defaultValue: 'relative',
    },
  },
  children: [POPOVER_HEADER, POPOVER_CONTENT],
};
