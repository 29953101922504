import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {AMAZON_DESCRIPTION} from "./description";
import {AMAZON_PICTURE} from "./picture";

// StandardSingleSideImageModule

export const AMAZON_SINGLE_LEFT_IMAGE: TComponent = {
  componentKey: EComponents.AMAZON_SINGLE_LEFT_IMAGE,
  name: EComponents.AMAZON_SINGLE_LEFT_IMAGE,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Single Left Image',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...AMAZON_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...AMAZON_PICTURE.props,
        src: {
          ...AMAZON_PICTURE.props!.src,
          defaultValue: "/static/constructor/placeholders/300x300.jpg",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
    },
  ]
};
