import _cloneDeep from 'lodash/cloneDeep'
import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {FRIENDLY_DESCRIPTION} from "./description";
import {FRIENDLY_PICTURE} from "./picture";

export const FRIENDLY_MULTIPLE_IMAGE_TEXT_SLIDE: TComponent = {
  componentKey: EComponents.FRIENDLY_MULTIPLE_IMAGE_TEXT_SLIDE,
  name: EComponents.FRIENDLY_MULTIPLE_IMAGE_TEXT_SLIDE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Slide',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_MULTIPLE_IMAGE_TEXT],
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...FRIENDLY_PICTURE.props,
        src: {
          ...FRIENDLY_PICTURE.props!.src,
          defaultValue: "/static/constructor/placeholders/300x300.jpg",
        },
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontWeight: {
          ...FRIENDLY_DESCRIPTION.props!.fontWeight,
          defaultValue: '700'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },

    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Caption',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '1'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
  ],
}

export const FRIENDLY_MULTIPLE_IMAGE_TEXT: TComponent = {
  componentKey: EComponents.FRIENDLY_MULTIPLE_IMAGE_TEXT,
  name: EComponents.FRIENDLY_MULTIPLE_IMAGE_TEXT,
  type: EComponentTypes.FEATURE,
  displayName: 'Multiple Image Text',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: Array.from({ length: 4 }, () => _cloneDeep(FRIENDLY_MULTIPLE_IMAGE_TEXT_SLIDE)),
};

