import { TComponentProps } from '@/store/builder-pro/types';

export const FLEX_PROPS: TComponentProps = {
  flexDirection: {
    name: 'Flex Direction',
    defaultValue: 'row',
    items: ['row', 'column', 'row-reverse', 'column-reverse'],
  },
  flexWrap: {
    name: 'Flex wrap',
    defaultValue: 'nowrap',
    items: ['nowrap', 'wrap', 'wrap-reverse', 'initial'],
  },
  justifyContent: {
    name: 'Justify Content',
    defaultValue: 'flex-start',
    items: ['flex-start', 'center', 'flex-end'],
  },
  alignItems: {
    name: 'Align Items',
    defaultValue: 'flex-start',
    items: ['flex-start', 'center', 'flex-end'],
  },
};
