import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { OZON_PROPS } from "../../props";

export const OZON_DESCRIPTION: TComponent = {
  componentKey: EComponents.OZON_DESCRIPTION,
  name: EComponents.OZON_DESCRIPTION,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Description',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    content: {
      name: "Text",
      defaultValue: "Example text",
    },
    fontSize: OZON_PROPS.fontSize,
    textAlign: OZON_PROPS.textAlign,
    textColor: OZON_PROPS.textColor,
  },
};
