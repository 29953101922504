import {
  FRIENDLY_COL4,
  FRIENDLY_COL_PICTURE,
  FRIENDLY_COL_PICTURE_WITH_TEXT,
  FRIENDLY_DESCRIPTION,
  FRIENDLY_SPACER
} from "@/store/builder-pro/consts/components/elements";
import {EComponents, EEditorSizes} from "@/store/builder-pro/consts";
import {cloneDeep} from "lodash";
import {TGroupsebComponent} from "@/store/builder-pro/types";

const TEFAL_COMPONENTS: TGroupsebComponent[] = [
  {
    ...FRIENDLY_DESCRIPTION,
    props: {
      ...FRIENDLY_DESCRIPTION.props,
      textAlign: {
        ...FRIENDLY_DESCRIPTION.props!.textAlign,
        overrideWithGroupsebProp: true,
        defaultValue: 'center',
        value: {
          [EEditorSizes.DESKTOP]: 'center',
          [EEditorSizes.MOBILE]: 'center',
        }
      },
      fontSize: {
        ...FRIENDLY_DESCRIPTION.props!.fontSize,
        overrideWithGroupsebProp: true,
        defaultValue: '4',
        value: {
          [EEditorSizes.DESKTOP]: '4',
        }
      },
      color: {
        ...FRIENDLY_DESCRIPTION.props!.color,
        overrideWithGroupsebProp: true,
        defaultValue: '#000000',
        value: {
          [EEditorSizes.DESKTOP]: '#000000',
        }
      },
      backgroundColor: {
        ...FRIENDLY_DESCRIPTION.props!.backgroundColor,
        overrideWithGroupsebProp: true,
        defaultValue: '#ffffff',
        value: {
          [EEditorSizes.DESKTOP]: '#ffffff',
        }
      },
      fontFamily: {
        ...FRIENDLY_DESCRIPTION.props!.fontFamily,
        overrideWithGroupsebProp: true,
        defaultValue: 'DINPro',
        value: {
          [EEditorSizes.DESKTOP]: 'DINPro',
        }
      },
      fontStyle: {
        ...FRIENDLY_DESCRIPTION.props!.fontStyle,
        overrideWithGroupsebProp: true,
        defaultValue: 'normal',
        value: {
          [EEditorSizes.DESKTOP]: 'normal',
        }
      },
      fontWeight: {
        ...FRIENDLY_DESCRIPTION.props!.fontWeight,
        overrideWithGroupsebProp: true,
        defaultValue: '700',
        value: {
          [EEditorSizes.DESKTOP]: '700',
        }
      },
    }
  },
  {
    ...FRIENDLY_SPACER,
    props: {
      ...FRIENDLY_SPACER.props,
      backgroundColor: {
        ...FRIENDLY_SPACER.props!.backgroundColor,
        overrideWithGroupsebProp: true,
        defaultValue: '#ffffff',
        value: {
          [EEditorSizes.DESKTOP]: '#ffffff',
        }
      },
      space: {
        ...FRIENDLY_SPACER.props!.space,
        overrideWithGroupsebProp: true,
        defaultValue: 'small',
        value: {
          [EEditorSizes.DESKTOP]: 'small',
        }
      },
    }
  },
  {
    ...FRIENDLY_DESCRIPTION,
    props: {
      ...FRIENDLY_DESCRIPTION.props,
      textAlign: {
        ...FRIENDLY_DESCRIPTION.props!.textAlign,
        overrideWithGroupsebProp: true,
        defaultValue: 'left',
        value: {
          [EEditorSizes.DESKTOP]: 'left',
          [EEditorSizes.MOBILE]: 'left',
        }
      },
      fontSize: {
        ...FRIENDLY_DESCRIPTION.props!.fontSize,
        overrideWithGroupsebProp: true,
        defaultValue: '2',
        value: {
          [EEditorSizes.DESKTOP]: '2',
        }
      },
      color: {
        ...FRIENDLY_DESCRIPTION.props!.color,
        overrideWithGroupsebProp: true,
        defaultValue: '#000000',
        value: {
          [EEditorSizes.DESKTOP]: '#000000',
        }
      },
      backgroundColor: {
        ...FRIENDLY_DESCRIPTION.props!.backgroundColor,
        overrideWithGroupsebProp: true,
        defaultValue: '#ffffff',
        value: {
          [EEditorSizes.DESKTOP]: '#ffffff',
        }
      },
      fontFamily: {
        ...FRIENDLY_DESCRIPTION.props!.fontFamily,
        overrideWithGroupsebProp: true,
        defaultValue: 'DINPro',
        value: {
          [EEditorSizes.DESKTOP]: 'DINPro',
        }
      },
      fontStyle: {
        ...FRIENDLY_DESCRIPTION.props!.fontStyle,
        overrideWithGroupsebProp: true,
        defaultValue: 'normal',
        value: {
          [EEditorSizes.DESKTOP]: 'normal',
        }
      },
      fontWeight: {
        ...FRIENDLY_DESCRIPTION.props!.fontWeight,
        overrideWithGroupsebProp: true,
        defaultValue: '400',
        value: {
          [EEditorSizes.DESKTOP]: '400',
        }
      },
    }
  },
  {
    possibleMatchComponents: [EComponents.FRIENDLY_COL2, EComponents.FRIENDLY_COL3, EComponents.FRIENDLY_COL4],
    matchInitialChildrenCount: true,
    ...FRIENDLY_COL4,
    props: {
      ...FRIENDLY_COL4.props,
      align: {
        ...FRIENDLY_COL4.props!.align,
        overrideWithGroupsebProp: true,
        defaultValue: 'flex-start',
        value: {
          [EEditorSizes.DESKTOP]: 'flex-start',
          [EEditorSizes.MOBILE]: 'flex-start',
        }
      },
    },
    children: Array.from({ length: 4 }, () => ({
      ...cloneDeep(FRIENDLY_COL_PICTURE_WITH_TEXT),
      children: [
        {
          // FRIENDLY_COL_PICTURE
          ...FRIENDLY_COL_PICTURE_WITH_TEXT.children![0],
          props: {
            ...FRIENDLY_COL_PICTURE_WITH_TEXT.children![0].props,
            borderRadius: {
              ...FRIENDLY_COL_PICTURE.props!.borderRadius,
              overrideWithGroupsebProp: true,
              defaultValue: '7px',
              value: {
                [EEditorSizes.DESKTOP]: '7px',
              }
            }
          }
        },
        {
          // FRIENDLY_DESCRIPTION
          ...FRIENDLY_COL_PICTURE_WITH_TEXT.children![1],
          props: {
            ...FRIENDLY_COL_PICTURE_WITH_TEXT.children![1].props,
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              overrideWithGroupsebProp: true,
              defaultValue: 'left',
              value: {
                [EEditorSizes.DESKTOP]: 'left',
                [EEditorSizes.MOBILE]: 'left',
              }
            },
            fontSize: {
              ...FRIENDLY_DESCRIPTION.props!.fontSize,
              overrideWithGroupsebProp: true,
              defaultValue: '2',
              value: {
                [EEditorSizes.DESKTOP]: '2',
              }
            },
            color: {
              ...FRIENDLY_DESCRIPTION.props!.color,
              overrideWithGroupsebProp: true,
              defaultValue: '#000000',
              value: {
                [EEditorSizes.DESKTOP]: '#000000',
              }
            },
            backgroundColor: {
              ...FRIENDLY_DESCRIPTION.props!.backgroundColor,
              overrideWithGroupsebProp: true,
              defaultValue: '#ffffff',
              value: {
                [EEditorSizes.DESKTOP]: '#ffffff',
              }
            },
            fontFamily: {
              ...FRIENDLY_DESCRIPTION.props!.fontFamily,
              overrideWithGroupsebProp: true,
              defaultValue: 'DINPro',
              value: {
                [EEditorSizes.DESKTOP]: 'DINPro',
              }
            },
            fontStyle: {
              ...FRIENDLY_DESCRIPTION.props!.fontStyle,
              overrideWithGroupsebProp: true,
              defaultValue: 'normal',
              value: {
                [EEditorSizes.DESKTOP]: 'normal',
              }
            },
            fontWeight: {
              ...FRIENDLY_DESCRIPTION.props!.fontWeight,
              overrideWithGroupsebProp: true,
              defaultValue: '700',
              value: {
                [EEditorSizes.DESKTOP]: '700',
              }
            },
          }
        },
        {
          // FRIENDLY_DESCRIPTION
          ...FRIENDLY_COL_PICTURE_WITH_TEXT.children![2],
          props: {
            ...FRIENDLY_COL_PICTURE_WITH_TEXT.children![2].props,
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              overrideWithGroupsebProp: true,
              defaultValue: 'left',
              value: {
                [EEditorSizes.DESKTOP]: 'left',
                [EEditorSizes.MOBILE]: 'left',
              }
            },
            fontSize: {
              ...FRIENDLY_DESCRIPTION.props!.fontSize,
              overrideWithGroupsebProp: true,
              defaultValue: '2',
              value: {
                [EEditorSizes.DESKTOP]: '2',
              }
            },
            color: {
              ...FRIENDLY_DESCRIPTION.props!.color,
              overrideWithGroupsebProp: true,
              defaultValue: '#000000',
              value: {
                [EEditorSizes.DESKTOP]: '#000000',
              }
            },
            backgroundColor: {
              ...FRIENDLY_DESCRIPTION.props!.backgroundColor,
              overrideWithGroupsebProp: true,
              defaultValue: '#ffffff',
              value: {
                [EEditorSizes.DESKTOP]: '#ffffff',
              }
            },
            fontFamily: {
              ...FRIENDLY_DESCRIPTION.props!.fontFamily,
              overrideWithGroupsebProp: true,
              defaultValue: 'DINPro',
              value: {
                [EEditorSizes.DESKTOP]: 'DINPro',
              }
            },
            fontStyle: {
              ...FRIENDLY_DESCRIPTION.props!.fontStyle,
              overrideWithGroupsebProp: true,
              defaultValue: 'normal',
              value: {
                [EEditorSizes.DESKTOP]: 'normal',
              }
            },
            fontWeight: {
              ...FRIENDLY_DESCRIPTION.props!.fontWeight,
              overrideWithGroupsebProp: true,
              defaultValue: '400',
              value: {
                [EEditorSizes.DESKTOP]: '400',
              }
            }
          },
        },
      ]
    })),
  },
  {
    ...FRIENDLY_DESCRIPTION,
    props: {
      ...FRIENDLY_DESCRIPTION.props,
      textAlign: {
        ...FRIENDLY_DESCRIPTION.props!.textAlign,
        overrideWithGroupsebProp: true,
        defaultValue: 'center',
        value: {
          [EEditorSizes.DESKTOP]: 'center',
          [EEditorSizes.MOBILE]: 'center',
        }
      },
      fontSize: {
        ...FRIENDLY_DESCRIPTION.props!.fontSize,
        overrideWithGroupsebProp: true,
        defaultValue: '1',
        value: {
          [EEditorSizes.DESKTOP]: '1',
        }
      },
      color: {
        ...FRIENDLY_DESCRIPTION.props!.color,
        overrideWithGroupsebProp: true,
        defaultValue: '#000000',
        value: {
          [EEditorSizes.DESKTOP]: '#000000',
        }
      },
      backgroundColor: {
        ...FRIENDLY_DESCRIPTION.props!.backgroundColor,
        overrideWithGroupsebProp: true,
        defaultValue: '#ffffff',
        value: {
          [EEditorSizes.DESKTOP]: '#ffffff',
        }
      },
      fontFamily: {
        ...FRIENDLY_DESCRIPTION.props!.fontFamily,
        overrideWithGroupsebProp: true,
        defaultValue: 'DINPro',
        value: {
          [EEditorSizes.DESKTOP]: 'DINPro',
        }
      },
      fontStyle: {
        ...FRIENDLY_DESCRIPTION.props!.fontStyle,
        overrideWithGroupsebProp: true,
        defaultValue: 'normal',
        value: {
          [EEditorSizes.DESKTOP]: 'normal',
        }
      },
      fontWeight: {
        ...FRIENDLY_DESCRIPTION.props!.fontWeight,
        overrideWithGroupsebProp: true,
        defaultValue: '400',
        value: {
          [EEditorSizes.DESKTOP]: '400',
        }
      },
    }
  },
]

export { TEFAL_COMPONENTS }
