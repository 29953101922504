import { TComponentProps } from "@/store/builder-pro/types";

export const OZON_PROPS: TComponentProps = {
  fontSize: {
    name: "Text Size",
    defaultValue: "4",
    items: ["1", "2", "3", "4", "5", "6", "7"],
  },
  listBlockTheme: {
    name: "Theme",
    defaultValue: "bullet",
    items: ["bullet", "number", "image"],
  },
  objectFit: {
    name: "Object fit",
    defaultValue: "cover",
    items: ["contain", "cover"],
  },
  showBackground: {
    name: "Show background",
    defaultValue: "on",
  },
  textAlign: {
    name: "Text Align",
    defaultValue: "center",
    items: ["left", "center", "right"],
  },
  textBlockTheme: {
    name: "Theme",
    defaultValue: "primary",
    items: ["default", "primary", "secondary", "tertiary"],
  },
  textBlockGapSize: {
    name: "Gap between text",
    defaultValue: "m",
    items: ["m", "s"],
  },
  textBlockPadding: {
    name: "Padding around the text",
    defaultValue: "2",
    items: ["1", "2"],
  },
  textColor: {
    name: "Text Color",
    defaultValue: "#001a34",
    items: ["#001a34", "#3d5165", "#808d9a", "#fff"],
  },
  width: {
    name: "Width",
    defaultValue: "full",
    items: ["full", "half", "one-third", "one-fourth"],
  },
};
