import { TComponent, TComponentProp } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";
import { IMAGE } from './image';
import { ROW } from './row';
import { COLUMN } from './column';
import { TEXT } from './text';

export const ACCORDION_HEADER: TComponent = {
  componentKey: EComponents.ACCORDION_HEADER,
  name: EComponents.COLUMN,
  type: EComponentTypes.COMPONENT,
  displayName: 'Accordion Header',
  noManualRemove: true,
  nonDraggable: true,
  withChildren: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined,
  },
  children: [
    {
      ...IMAGE,
    },
  ],
};

export const ACCORDION_CONTENT: TComponent = {
  componentKey: EComponents.ACCORDION_CONTENT,
  name: EComponents.COLUMN,
  type: EComponentTypes.COMPONENT,
  displayName: 'Accordion Content',
  noManualRemove: true,
  nonDraggable: true,
  withChildren: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined,
  },
  children: [
    {
      ...ROW,
      children: [
        {
          ...COLUMN,
          props: {
            ...COLUMN.props,
            padding: {
              ...COLUMN.props!.padding,
              defaultValue: '0 40px',
            },
            flexDirection: {
              ...COLUMN.props!.flexDirection,
              defaultValue: 'column',
            },
            justifyContent: {
              ...COLUMN.props!.justifyContent,
              defaultValue: 'center',
            },
            width: {
              ...COLUMN.props!.width,
              value: {
                desktop: '50%',
              },
            } as TComponentProp,
          },
          children: [
            {
              ...TEXT,
              props: {
                ...TEXT.props,
                content: {
                  ...TEXT.props!.content,
                  defaultValue: 'This is an example of a description',
                },
                fontSize: {
                  ...TEXT.props!.fontSize,
                  defaultValue: '16px',
                },
                fontWeight: {
                  ...TEXT.props!.fontWeight,
                  defaultValue: '700',
                },
                textAlign: {
                  ...TEXT.props!.textAlign,
                  defaultValue: 'left',
                },
              },
            },
            {
              ...TEXT,
              props: {
                ...TEXT.props,
                content: {
                  ...TEXT.props!.content,
                  defaultValue: 'This is an example of a description. Here the font is smaller and there is more content.',
                },
                fontSize: {
                  ...TEXT.props!.fontSize,
                  defaultValue: '12px',
                },
                textAlign: {
                  ...TEXT.props!.textAlign,
                  defaultValue: 'left',
                },
              },
            },
          ],
        },
        {
          ...COLUMN,
          props: {
            ...COLUMN.props,
            width: {
              ...COLUMN.props!.width,
              value: {
                desktop: '50%',
              },
            } as TComponentProp,
          },
          children: [
            {
              ...IMAGE,
            },
          ],
        },
      ],
    },
  ],
};

export const ACCORDION: TComponent = {
  componentKey: EComponents.ACCORDION,
  name: EComponents.ACCORDION,
  type: EComponentTypes.COMPONENT,
  staticChildren: true,
  props: {
    iconSvgCodeCollapsed: {
      name: 'Svg-code of icon (collapsed)',
      defaultValue: '',
    },
    iconSvgCodeExpanded: {
      name: 'Svg-code of icon (expanded)',
      defaultValue: '',
    },
    ...COMMON_PROPS,
  },
  children: [
    {
      ...ACCORDION_HEADER,
    },
    {
      ...ACCORDION_CONTENT,
    },
  ],
};
