import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";
import { COLUMN } from '../column';
import { FORM_ERROR } from './error';
import { DEFAULT_FORM_LABEL } from './default-label';

export const FORM_RADIO_GROUP: TComponent = {
  componentKey: EComponents.FORM_RADIO_GROUP,
  name: EComponents.FORM_RADIO_GROUP,
  type: EComponentTypes.COMPONENT,
  displayName: 'Form Radio Group',
  withChildren: true,
  noDynamic: true,
  requiredUpperElements: [EComponents.FORM],
  prohibitedUpperElements: [EComponents.FORM_RADIO_GROUP],
  props: {
    name: {
      name: 'Name',
      defaultValue: '',
    },
    defaultValue: {
      name: 'Default value',
      defaultValue: '',
    },
    rules: {
      name: 'Rules',
      defaultValue: [],
    },
    ...COLUMN.props,
  },
  children: [FORM_ERROR],
};

export const FORM_RADIO_BUTTON: TComponent = {
  componentKey: EComponents.FORM_RADIO_BUTTON,
  name: EComponents.FORM_RADIO_BUTTON,
  type: EComponentTypes.COMPONENT,
  displayName: 'Form Radio Button',
  staticChildren: true,
  requiredUpperElements: [EComponents.FORM_RADIO_GROUP],
  props: {
    value: {
      name: 'Value',
      defaultValue: '',
    },
    activeColor: {
      name: 'Active color',
      defaultValue: '#000000',
    },
    inactiveColor: {
      name: 'Inactive color',
      defaultValue: '#ffffff',
    },
    dotColor: {
      name: 'Dot color',
      defaultValue: '#ffffff',
    },
    dotWidth: {
      ...COMMON_PROPS.width,
      defaultValue: '10px',
    },
    dotHeight: {
      ...COMMON_PROPS.height,
      defaultValue: '10px',
    },
    radioBorder: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #d9d9d9',
    },
    radioWidth: {
      ...COMMON_PROPS.width,
      defaultValue: '24px',
    },
    radioHeight: {
      ...COMMON_PROPS.height,
      defaultValue: '24px',
    },
    ...COMMON_PROPS,
  },
  children: [DEFAULT_FORM_LABEL],
};
