import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {FRIENDLY_PICTURE} from "./picture";
import {FRIENDLY_DESCRIPTION} from "./description";

export const FRIENDLY_PICTURE_WITH_TEXT: TComponent = {
  componentKey: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
  name: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
  type: EComponentTypes.FEATURE,
  displayName: 'Picture With Text',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...FRIENDLY_PICTURE.props,
        src: {
          ...FRIENDLY_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/1416x708.jpg",
            mobile: "/static/constructor/placeholders/900x900.jpg"
          },
        },
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
  ]
};
