import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const SCRIPT: TComponent = {
  componentKey: EComponents.SCRIPT,
  name: EComponents.SCRIPT,
  type: EComponentTypes.COMPONENT,
  noHover: true,
  noAdaptive: true,
  props: {
    code: {
      name: 'Code',
      defaultValue: '',
    },
    auto: {
      name: 'Run the code automatically (in editor)',
      defaultValue: 'off',
      items: ['on', 'off'],
    },
  },
};
