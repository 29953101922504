import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {FRIENDLY_DESCRIPTION} from "./description";

export const FRIENDLY_TITLE_DESCRIPTION: TComponent = {
  componentKey: EComponents.FRIENDLY_TITLE_DESCRIPTION,
  name: EComponents.FRIENDLY_TITLE_DESCRIPTION,
  type: EComponentTypes.FEATURE,
  displayName: 'Title & Description',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
      }
    },
  ]
};
