import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {OZON_PROPS} from "../../props";
import {OZON_DESCRIPTION} from "./description";
import {OZON_PICTURE} from "./base/picture";

export const OZON_SINGLE_LEFT_IMAGE: TComponent = {
  componentKey: EComponents.OZON_SINGLE_LEFT_IMAGE,
  name: EComponents.OZON_SINGLE_LEFT_IMAGE,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Single Left Image',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...OZON_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      props: {
        altText: OZON_PICTURE.props!.altText,
        objectFit: OZON_PROPS.objectFit,
        showBackground: OZON_PROPS.showBackground,
        src: {
          ...OZON_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/708x708.jpg",
            mobile: "/static/constructor/placeholders/640x640.jpg",
          },
        },
        url: OZON_PICTURE.props!.url
      },
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        content: {
          ...OZON_DESCRIPTION.props!.content,
          defaultValue: 'Title',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        fontSize: {
          ...OZON_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
  ]
};
