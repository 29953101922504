import _cloneDeep from 'lodash/cloneDeep'
import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { OZON_DESCRIPTION } from "./description";

export const OZON_SPECIFICATIONS_COL: TComponent = {
  componentKey: EComponents.OZON_SPECIFICATIONS_COL,
  name: EComponents.OZON_SPECIFICATIONS_COL,
  type: EComponentTypes.COMPONENT,
  displayName: 'Text Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.OZON_SPECIFICATIONS_ROW],
  props: {
    content: {
      ...OZON_DESCRIPTION.props!.content,
      defaultValue: 'Example text',
    },
    textAlign: {
      ...OZON_DESCRIPTION.props!.textAlign,
      defaultValue: 'left'
    }
  }
}

export const OZON_SPECIFICATIONS_ROW: TComponent = {
  componentKey: EComponents.OZON_SPECIFICATIONS_ROW,
  name: EComponents.OZON_SPECIFICATIONS_ROW,
  type: EComponentTypes.COMPONENT,
  displayName: 'Row',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.OZON_SPECIFICATIONS_TABLE]
}

export const OZON_SPECIFICATIONS_TABLE: TComponent = {
  componentKey: EComponents.OZON_SPECIFICATIONS_TABLE,
  name: EComponents.OZON_SPECIFICATIONS_TABLE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Table',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.OZON_SPECIFICATIONS]
}

export const OZON_SPECIFICATIONS: TComponent = {
  componentKey: EComponents.OZON_SPECIFICATIONS,
  name: EComponents.OZON_SPECIFICATIONS,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Specifications',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        content: {
          ...OZON_DESCRIPTION.props!.content,
          defaultValue: 'Title',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...OZON_SPECIFICATIONS_TABLE,
      children: Array.from({ length: 6 }, () => ({
        ...OZON_SPECIFICATIONS_ROW,
        children: Array.from({ length: 5 }, () => _cloneDeep(OZON_SPECIFICATIONS_COL))
      }))
    },
  ],
};

