import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { AMAZON_PROPS } from "../../props";
import { AMAZON_DESCRIPTION } from "./description";
import { AMAZON_PICTURE } from "./picture";

// StandardImageTextOverlayModule

export const AMAZON_IMAGE_TEXT_OVERLAY: TComponent = {
  componentKey: EComponents.AMAZON_IMAGE_TEXT_OVERLAY,
  name: EComponents.AMAZON_IMAGE_TEXT_OVERLAY,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Image Text Overlay',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    overlayColorType: AMAZON_PROPS.overlayColorType,
  },
  children: [
    {
      ...AMAZON_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...AMAZON_PICTURE.props,
        src: {
          ...AMAZON_PICTURE.props!.src,
          defaultValue: "/static/constructor/placeholders/970x300.jpg",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true
    },
  ]
};
