import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {FRIENDLY_DESCRIPTION} from "./description";
import {FRIENDLY_PICTURE} from "./picture";
import {FRIENDLY_PICTURE_WITH_TEXT} from "./picture-with-text";
import {FRIENDLY_TEXT_LIST} from './base/text-list';
import {FRIENDLY_TITLE_DESCRIPTION} from "./title-description";

export const FRIENDLY_IMAGE_SIDEBAR: TComponent = {
  componentKey: EComponents.FRIENDLY_IMAGE_SIDEBAR,
  name: EComponents.FRIENDLY_IMAGE_SIDEBAR,
  type: EComponentTypes.FEATURE,
  displayName: 'Image Sidebar',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_PICTURE_WITH_TEXT,
      type: EComponentTypes.COMPONENT,
      displayName: 'Image Caption Block',
      noManualRemove: true,
      children: [
        {
          ...FRIENDLY_PICTURE,
          type: EComponentTypes.COMPONENT,
          displayName: 'Picture',
          noManualRemove: true,
          props: {
            ...FRIENDLY_PICTURE.props,
            src: {
              ...FRIENDLY_PICTURE.props!.src,
              defaultValue: {
                desktop: "/static/constructor/placeholders/300x400.jpg",
                mobile: "/static/constructor/placeholders/900x900.jpg",
              },
            },
          },
        },
        {
          ...FRIENDLY_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Caption',
          noManualRemove: true,
          props: {
            ...FRIENDLY_DESCRIPTION.props,
            fontSize: {
              ...FRIENDLY_DESCRIPTION.props!.fontSize,
              defaultValue: '1'
            },
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              defaultValue: 'left'
            }
          }
        },
      ],
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontWeight: {
          ...FRIENDLY_DESCRIPTION.props!.fontWeight,
          defaultValue: '700'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...FRIENDLY_TITLE_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Text Block',
      noManualRemove: true,
      children: [
        {
          ...FRIENDLY_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Title',
          noManualRemove: true,
          props: {
            ...FRIENDLY_DESCRIPTION.props,
            fontStyle: {
              ...FRIENDLY_DESCRIPTION.props!.fontStyle,
              defaultValue: 'italic'
            },
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              defaultValue: 'left'
            }
          }
        },
        {
          ...FRIENDLY_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Description',
          noManualRemove: true,
          props: {
            ...FRIENDLY_DESCRIPTION.props,
            fontSize: {
              ...FRIENDLY_DESCRIPTION.props!.fontSize,
              defaultValue: '2'
            },
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              defaultValue: 'left'
            }
          }
        },
      ]
    },
    {
      ...FRIENDLY_TEXT_LIST,
      type: EComponentTypes.COMPONENT,
      displayName: 'List',
    },
    {
      ...FRIENDLY_PICTURE_WITH_TEXT,
      type: EComponentTypes.COMPONENT,
      displayName: 'Image Text Block',
      noManualRemove: true,
      children: [
        {
          ...FRIENDLY_PICTURE,
          type: EComponentTypes.COMPONENT,
          displayName: 'Picture',
          noManualRemove: true,
          props: {
            ...FRIENDLY_PICTURE.props,
            src: {
              ...FRIENDLY_PICTURE.props!.src,
              defaultValue: {
                desktop: "/static/constructor/placeholders/350x175.jpg",
                mobile: "/static/constructor/placeholders/900x900.jpg",
              },
            },
          },
        },
        {
          ...FRIENDLY_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Title',
          noManualRemove: true,
          props: {
            ...FRIENDLY_DESCRIPTION.props,
            fontSize: {
              ...FRIENDLY_DESCRIPTION.props!.fontSize,
              defaultValue: '2'
            },
            fontWeight: {
              ...FRIENDLY_DESCRIPTION.props!.fontWeight,
              defaultValue: '700'
            },
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              defaultValue: 'left'
            }
          }
        },
        {
          ...FRIENDLY_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Description',
          noManualRemove: true,
          props: {
            ...FRIENDLY_DESCRIPTION.props,
            fontSize: {
              ...FRIENDLY_DESCRIPTION.props!.fontSize,
              defaultValue: '1'
            },
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              defaultValue: 'left'
            }
          }
        },
      ],
    },
    {
      ...FRIENDLY_TEXT_LIST,
      type: EComponentTypes.COMPONENT,
      displayName: 'List',
    },
  ]
};
