import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../../components";
import {EComponentTypes} from "../../../types";
import { OZON_PROPS } from "../../../props";

export const OZON_PICTURE: TComponent = {
  componentKey: EComponents.OZON_PICTURE,
  name: EComponents.OZON_PICTURE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Ozon Picture',
  isBaseComponent: true,
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noParent: true,
  noSave: true,
  props: {
    altText: {
      name: "Alt Text",
      defaultValue: "",
    },
    width: {
      ...OZON_PROPS.width,
      name: 'Image Width',
      optional: true,
    },
    objectFit: {
      ...OZON_PROPS.objectFit,
      optional: true,
    },
    showBackground: {
      ...OZON_PROPS.showBackground,
      optional: true,
    },
    src: {
      fileType: "image",
      name: "Image",
      defaultValue: {
            desktop: "/static/constructor/placeholders/1416x708.jpg",
            mobile: "/static/constructor/placeholders/640x640.jpg",
          },
    },
    url: {
      name: "Image Link (should contain ozon.ru)",
      defaultValue: "",
    },
  },
};
