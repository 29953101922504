import axios, { AxiosInstance } from 'axios';

const headers = {
  'Content-type': 'application/json',
};

const options = {
  baseURL: process.env.VUE_APP_API_URL,
  headers,
};

const apiClient: AxiosInstance = axios.create(options);

apiClient.interceptors.request.use((config) => {
  const token = localStorage.getItem('accessToken');
  if (token && config.headers) config.headers.Authorization = `Bearer ${token}`;
  return config;
});

export default apiClient;
