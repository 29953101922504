import {
  EHistoryStepActionType,
  THistoryStepAction,
  THistoryStepActionAddRemoveCustomComponent,
  THistoryStepActionMakeDynamicComponent,
  THistoryStepActionAddRemoveComponent,
  THistoryStepActionRemoveDynamicComponent,
  TStore,
  THistoryStepActionAddComponent,
  TComponent,
  THistoryStepActionReorderComponent,
  THistoryStepActionUpdateComponentProp,
  THistoryStepActionUpdateFont,
} from "@/store/builder-pro/types";
import _cloneDeep from "lodash/cloneDeep";
import {
  reorderComponentsGetComponentAndDesiredParentChildren
} from "@/store/builder-pro/actions/history/utils";

export const HISTORY_REDO_HANDLERS: Record<EHistoryStepActionType, (action: THistoryStepAction, store: TStore) => void> = {
  addComponent (action, store) {
    const components = store.addedComponents;

    if ((action as THistoryStepActionAddComponent).componentIdsWithIncreasedOrders.length) {
      const indexesToChangeOrderOf: number[] = [];

      const componentsToChangeOrderOfIdMap = (action as THistoryStepActionAddComponent).componentIdsWithIncreasedOrders
        .reduce<Record<string, boolean>>((acc, item) => ({ ...acc, [item]: true }), {})

      for (let i = 0; i < components.length; i += 1) {
        if (componentsToChangeOrderOfIdMap[components[i].id!]) {
          indexesToChangeOrderOf.push(i);
        }

        if (indexesToChangeOrderOf.length === (action as THistoryStepActionAddComponent).componentIdsWithIncreasedOrders.length) {
          break;
        }
      }

      indexesToChangeOrderOf.forEach(index => {
        components[index].order! += 1;
      })
    }

    (action as THistoryStepActionAddComponent).components.forEach((component, index) => {
      components.push(_cloneDeep(component))

      if (index === 0) {
        store.setActiveComponent({
          component: components[components.length - 1]
        });
      }
    });

    store.onEpackDataUpdate();
  },

  removeComponent (action, store) {
    const indexesToRemove: number[] = [];

    const removedComponentIds = (action as THistoryStepActionAddRemoveComponent).components.map(removedComponent => removedComponent.id!);
    const components = store.addedComponents;

    for (let i = 0; i < components.length; i += 1) {
      removedComponentIds.slice().forEach((id, index) => {
        if (id === components[i].id) {
          indexesToRemove.push(i);
          removedComponentIds.splice(index, 1);
        }
      })
    }

    indexesToRemove.reverse().forEach(indexToRemove => {
      components.splice(indexToRemove, 1);
    })
  },

  saveCustomComponent (action, store) {
    store.epackData.customComponents.splice(
      (action as THistoryStepActionAddRemoveCustomComponent).orderIndex, 0,
      (action as THistoryStepActionAddRemoveCustomComponent).component
    );
    store.onEpackDataUpdate();
  },

  removeCustomComponent (action, store) {
    store.epackData.customComponents.splice(
      (action as THistoryStepActionAddRemoveCustomComponent).orderIndex, 1
    );
    store.onEpackDataUpdate();
  },

  reorderComponent (action, store) {
    const {
      component,
      desiredParentChildren,
      foundAllDesiredParentChildren
    } = reorderComponentsGetComponentAndDesiredParentChildren(action, store);

    if (component && foundAllDesiredParentChildren) {
      component.parentId = (action as THistoryStepActionReorderComponent).newParentId;

      // sort the children to add the dragged component at the correct index
      desiredParentChildren.sort((a, b) => !!a.order && !!b.order && b.order < a.order ? 1 : -1)
      // adding the dragged component at the correct index to fix the orders after
      //  (note that desiredParentChildren is a new instance, so it doesn't change components)
      desiredParentChildren.splice((action as THistoryStepActionReorderComponent).newOrder - 1, 0, component)
      // fix the orders of the children inside the component
      //  taking into account that there's a new child
      desiredParentChildren.forEach((child, index) => {
        child.order = index + 1
      })

      store.setActiveComponent({
        component
      });
      store.onEpackDataUpdate();
    }
  },

  addRawComponent (action, store) {
    const components = store.addedComponents;

    (action as THistoryStepActionAddRemoveComponent).components.forEach(component => {
      components.push(_cloneDeep(component))
    })

    store.setActiveComponent({
      component: components[components.length - (action as THistoryStepActionAddRemoveComponent).components.length]
    });
    store.onEpackDataUpdate();
  },

  makeComponentDynamic (action, store) {
    const components = store.addedComponents

    const component = components.find(component =>
      component.id === (action as THistoryStepActionMakeDynamicComponent).componentId);

    if (component) {
      components.push(_cloneDeep((action as THistoryStepActionMakeDynamicComponent).dynamicDataComponent));

      const dynamicComponent = components[components.length - 1];

      component.parentId = dynamicComponent.id
      component.order = 1

      store.setActiveComponent({
        component
      });
      store.onEpackDataUpdate();
    }
  },

  removeDynamicDataComponent (action, store) {
    const components = store.addedComponents;

    const componentPairsMap =
      (action as THistoryStepActionRemoveDynamicComponent).componentPairs
        .reduce<Record<string, THistoryStepActionRemoveDynamicComponent['componentPairs'][number]
          & {
          indexInComponents: number | undefined,
          dynamicIndexInComponents: number | undefined,
        }
        >>(
          (acc, item) => ({
            ...acc,
            [item.componentId!]: {
              ...item,
              indexInComponents: undefined,
              dynamicIndexInComponents: undefined,
            }
          }), {});
    const componentsDynamicIdToComponentIdMap =
      (action as THistoryStepActionRemoveDynamicComponent).componentPairs
        .reduce<Record<string, string>>((acc, item) => ({
          ...acc,
          [item.dynamicComponent.id!]: item.componentId!
        }), {})

    for (let i = 0; i < components.length; i += 1) {
      if (componentPairsMap[components[i].id!]) {
        componentPairsMap[components[i].id!].indexInComponents = i;
      }
      if (componentsDynamicIdToComponentIdMap[components[i].id!]) {
        componentPairsMap[componentsDynamicIdToComponentIdMap[components[i].id!]].dynamicIndexInComponents = i;
      }
    }

    if (
      Object.values(componentPairsMap)
        .reduce((len, item) =>
          len + (item.indexInComponents !== undefined && item.dynamicIndexInComponents !== undefined ? 1 : 0), 0)
        === (action as THistoryStepActionRemoveDynamicComponent).componentPairs.length
    ) {
      Object.values(componentPairsMap).forEach((pair, index) => {
        const component = components[pair.indexInComponents!];

        component.parentId = pair.dynamicComponent.parentId;
        component.order = pair.dynamicComponent.order;

        if (index === 0) {
          store.setActiveComponent({
            component,
          });
        }
      })

      // splicing after because it adjusts the indexes
      Object.values(componentPairsMap)
        .sort((a, b) => a.dynamicIndexInComponents! > b.dynamicIndexInComponents! ? -1 : 1)
        .forEach((pair) => {
          components.splice(pair.dynamicIndexInComponents!, 1)
        })

      store.onEpackDataUpdate();
    }
  },

  addTableRow (action, store) {
    this.addRawComponent(action, store);
  },

  updateComponentProp (action, store) {
    const component =
      store.addedComponents.find(component => component.id === (action as THistoryStepActionUpdateComponentProp).componentId);

    if (component) {
      component.props![(action as THistoryStepActionUpdateComponentProp).propKey] =
        _cloneDeep((action as THistoryStepActionUpdateComponentProp).newProp);

      if ((action as THistoryStepActionUpdateComponentProp).prevUsedFonts && (action as THistoryStepActionUpdateComponentProp).newUsedFonts) {
        store.epackData.usedFonts = _cloneDeep((action as THistoryStepActionUpdateComponentProp).newUsedFonts!);
      }

      store.setActiveComponent({
        component,
      })
      store.onEpackDataUpdate();
    }
  },

  updateFont (action, store) {
    const historyComponents = (action as THistoryStepActionUpdateFont).components;

    store.epackData.components[store.activeLocale][store.activeTemplate][store.activePage].forEach(component => {
      if (component.props?.fontFamily && component.props?.fontWeight && component.props?.fontStyle) {
        const componentFromHistory = historyComponents.find(historyComponent => historyComponent.id === component.id);
        if (componentFromHistory?.props) {
          component.props.fontFamily = _cloneDeep(componentFromHistory.props.fontFamily);
          component.props.fontWeight = _cloneDeep(componentFromHistory.props.fontWeight);
          component.props.fontStyle = _cloneDeep(componentFromHistory.props.fontStyle);
        }
      }
    });

    if ((action as THistoryStepActionUpdateComponentProp).prevUsedFonts && (action as THistoryStepActionUpdateComponentProp).newUsedFonts) {
      store.epackData.usedFonts = _cloneDeep((action as THistoryStepActionUpdateComponentProp).prevUsedFonts!);
    }
    store.updateUsedFonts();
    store.onEpackDataUpdate();
  }
};
