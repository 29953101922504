<script lang="ts" setup>
import { useQueryProvider } from 'vue-query';

useQueryProvider();
</script>

<template>
  <router-view />
</template>

<style lang="scss">
.capitalize {
  text-transform: capitalize;
}
</style>
