import _cloneDeep from 'lodash/cloneDeep'
import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { OZON_DESCRIPTION } from './description';
import { OZON_SINGLE_LEFT_IMAGE } from './single-left-image';
import { OZON_SINGLE_RIGHT_IMAGE } from './single-right-image';

export const OZON_IMAGE_SIDEBAR: TComponent = {
  componentKey: EComponents.OZON_IMAGE_SIDEBAR,
  name: EComponents.OZON_IMAGE_SIDEBAR,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Image Sidebar',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...OZON_SINGLE_LEFT_IMAGE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Image Text Block',
      noManualRemove: true,
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: _cloneDeep(OZON_DESCRIPTION.props),
    },
    {
      ...OZON_SINGLE_RIGHT_IMAGE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Image Text Block',
      noManualRemove: true,
    },
    {
      ...OZON_DESCRIPTION,
      displayName: 'Description',
      noManualRemove: true,
      props: _cloneDeep(OZON_DESCRIPTION.props),
    },
  ],
};

