import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const IFRAME: TComponent = {
  componentKey: EComponents.IFRAME,
  name: EComponents.IFRAME,
  type: EComponentTypes.COMPONENT,
  props: {
    src: {
      name: 'Iframe src',
      defaultValue: '',
    },
    ...COMMON_PROPS,
  },
};
