import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {AMAZON_DESCRIPTION} from "./description";
import {AMAZON_PICTURE} from "./picture";
import {AMAZON_PICTURE_WITH_TEXT} from "./picture-with-text";
import {AMAZON_TEXT_LIST} from './base/text-list';
import {AMAZON_TITLE_DESCRIPTION} from "./title-description";

// StandardImageSidebarModule

export const AMAZON_IMAGE_SIDEBAR: TComponent = {
  componentKey: EComponents.AMAZON_IMAGE_SIDEBAR,
  name: EComponents.AMAZON_IMAGE_SIDEBAR,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Image Sidebar',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...AMAZON_PICTURE_WITH_TEXT,
      type: EComponentTypes.COMPONENT,
      displayName: 'Image Caption Block',
      noManualRemove: true,
      children: [
        {
          ...AMAZON_PICTURE,
          type: EComponentTypes.COMPONENT,
          displayName: 'Picture',
          noManualRemove: true,
          props: {
            ...AMAZON_PICTURE.props,
            src: {
              ...AMAZON_PICTURE.props!.src,
              defaultValue: "/static/constructor/placeholders/300x400.jpg",
            },
          },
        },
        {
          ...AMAZON_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Caption',
          noManualRemove: true,
          props: {
            ...AMAZON_DESCRIPTION.props,
            content: {
              ...AMAZON_DESCRIPTION.props!.content,
              defaultValue: "Enter caption",
            },
          },
        },
      ],
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    {
      ...AMAZON_TITLE_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Text Block',
      noManualRemove: true,
    },
    {
      ...AMAZON_TEXT_LIST,
      type: EComponentTypes.COMPONENT,
      displayName: 'List',
    },
    {
      ...AMAZON_PICTURE_WITH_TEXT,
      type: EComponentTypes.COMPONENT,
      displayName: 'Image Text Block',
      noManualRemove: true,
      children: [
        {
          ...AMAZON_PICTURE,
          type: EComponentTypes.COMPONENT,
          displayName: 'Picture',
          noManualRemove: true,
          props: {
            ...AMAZON_PICTURE.props,
            src: {
              ...AMAZON_PICTURE.props!.src,
              defaultValue: "/static/constructor/placeholders/350x175.jpg",
            },
          },
        },
        {
          ...AMAZON_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Title',
          noManualRemove: true,
          props: {
            ...AMAZON_DESCRIPTION.props,
            content: {
              ...AMAZON_DESCRIPTION.props!.content,
              defaultValue: "Enter title",
            },
          },
        },
        {
          ...AMAZON_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Description',
          noManualRemove: true,
          props: {
            ...AMAZON_DESCRIPTION.props,
            content: {
              ...AMAZON_DESCRIPTION.props!.content,
              defaultValue: "Enter description",
            },
          },
        },
      ],
    },
    {
      ...AMAZON_TEXT_LIST,
      type: EComponentTypes.COMPONENT,
      displayName: 'List',
    },
  ]
};
