import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import { EComponentTypes } from "../types";
import {TEXT} from "./text";
import {COLUMN} from "./column";

export const TAB_BUTTON: TComponent = {
  componentKey: EComponents.TAB_BUTTON,
  name: EComponents.TAB_BUTTON,
  type: EComponentTypes.COMPONENT,
  displayName: 'Tab Button',
  withChildren: true,
  props: {
    ...COLUMN.props,
    justifyContent: {
      ...COLUMN.props!.justifyContent,
      defaultValue: 'center'
    },
    alignItems: {
      ...COLUMN.props!.alignItems,
      defaultValue: 'center'
    },

    // must have values of type int
    tabGroup: {
      defaultValue: 1,
    },
    // must have values of type int
    tabContent: {},
    allowToggle: {
      defaultValue: 'off',
      items: ['on', 'off']
    }
  },
  children: [
    {
      ...TEXT,
      props: {
        ...TEXT.props,
        content: {
          ...TEXT.props!.content,
          defaultValue: 'Tab Button'
        }
      }
    }
  ]
}

export const TAB_BUTTON_WITH_STATE_ACTIVE: TComponent = {
  componentKey: EComponents.TAB_BUTTON_WITH_STATE_ACTIVE,
  name: EComponents.TAB_BUTTON_WITH_STATE_ACTIVE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Active state',
  withChildren: true,
  isBaseComponent: true,
  noManualRemove: true,
  noSave: true,
  nonDraggable: true,
  props: {
    ...COLUMN.props,
    justifyContent: {
      ...COLUMN.props!.justifyContent,
      defaultValue: 'center'
    },
    alignItems: {
      ...COLUMN.props!.alignItems,
      defaultValue: 'center'
    },
  },
  children: [
    {
      ...TEXT,
      props: {
        ...TEXT.props,
        content: {
          ...TEXT.props!.content,
          defaultValue: 'Tab Button Active'
        }
      }
    }
  ]
}

export const TAB_BUTTON_WITH_STATE_INACTIVE: TComponent = {
  componentKey: EComponents.TAB_BUTTON_WITH_STATE_INACTIVE,
  name: EComponents.TAB_BUTTON_WITH_STATE_INACTIVE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Inactive state',
  withChildren: true,
  isBaseComponent: true,
  noManualRemove: true,
  noSave: true,
  nonDraggable: true,
  props: {
    ...COLUMN.props,
    justifyContent: {
      ...COLUMN.props!.justifyContent,
      defaultValue: 'center'
    },
    alignItems: {
      ...COLUMN.props!.alignItems,
      defaultValue: 'center'
    },
  },
  children: [
    {
      ...TEXT,
      props: {
        ...TEXT.props,
        content: {
          ...TEXT.props!.content,
          defaultValue: 'Tab Button Inactive'
        }
      }
    }
  ]
}

export const TAB_BUTTON_WITH_STATE: TComponent = {
  componentKey: EComponents.TAB_BUTTON_WITH_STATE,
  name: EComponents.TAB_BUTTON_WITH_STATE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Tab Button',
  staticChildren: true,
  isBaseComponent: true,
  props: {
    // must have values of type int
    tabGroup: {
      defaultValue: 1,
    },
    // must have values of type int
    tabContent: {},
    allowToggle: {
      defaultValue: 'off',
      items: ['on', 'off']
    }
  },
  children: [
    TAB_BUTTON_WITH_STATE_INACTIVE,
    TAB_BUTTON_WITH_STATE_ACTIVE,
  ]
}

export const TAB_CONTENT: TComponent = {
  componentKey: EComponents.TAB_CONTENT,
  name: EComponents.TAB_CONTENT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Tab Content',
  withChildren: true,
  prohibitedUpperElements: [EComponents.TAB_BUTTON],
  props: {
    ...COLUMN.props,

    // must have values of type int
    uniqueId: {},
    openByDefault: {
      defaultValue: 'off',
      items: ['on', 'off']
    }
  }
}
