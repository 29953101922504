import { defineStore } from 'pinia';
import _cloneDeep from 'lodash/cloneDeep';
import { actions } from './actions';
import { getters } from './getters';
import { DEFAULT_STATE } from './consts';
import { TState, BuilderProGetters, BuilderProActions } from './types';

export const useBuilderProStore = defineStore('builder-pro', {
  state: (): TState => _cloneDeep(DEFAULT_STATE),
  getters: getters as BuilderProGetters,
  actions: actions as BuilderProActions,
});
