import {EHistoryStepActionType} from "@/store/builder-pro/types";

export const HISTORY_STEP_LIMIT = 64;

// in ms
export const HISTORY_ACTION_UPDATE_DELAY_MAP: Record<EHistoryStepActionType, number> = {
  [EHistoryStepActionType.ADD_COMPONENT]: 3000,
  [EHistoryStepActionType.REMOVE_COMPONENT]: 3000,
  [EHistoryStepActionType.SAVE_CUSTOM_COMPONENT]: 3000,
  [EHistoryStepActionType.REMOVE_CUSTOM_COMPONENT]: 3000,
  [EHistoryStepActionType.REORDER_COMPONENT]: 3000,
  [EHistoryStepActionType.ADD_RAW_COMPONENT]: 3000,
  [EHistoryStepActionType.MAKE_COMPONENT_DYNAMIC]: 3000,
  [EHistoryStepActionType.REMOVE_DYNAMIC_DATA_COMPONENT]: 3000,
  [EHistoryStepActionType.ADD_TABLE_ROW]: 3000,
  [EHistoryStepActionType.UPDATE_COMPONENT_PROP]: 5000,
  [EHistoryStepActionType.UPDATE_FONT]: 3000,
}
