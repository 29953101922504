import { EComponents } from '@/store/builder-pro/consts';
import { TComponent } from '@/store/builder-pro/types';

const getComponentNestedChildrenIds = (
  cid: number | undefined,
  components: TComponent[],
  idsToRemove: number[],
  childrenNamesToLookFor: EComponents[] | null = null
) => {
  const children = components.filter((cc) => cc.parentId === cid);
  children.forEach((c) => {
    if (components.some((cc) => cc.parentId === c.id)) {
      getComponentNestedChildrenIds(c.id, components, idsToRemove, childrenNamesToLookFor);
    }
  });
  if (childrenNamesToLookFor instanceof Array && childrenNamesToLookFor.length)
    idsToRemove.push(...children.filter((c) => childrenNamesToLookFor.includes(c.name)).map((c) => c.id!));
  else idsToRemove.push(...children.map((c) => c.id!));
};

export { getComponentNestedChildrenIds };
