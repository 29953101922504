import _cloneDeep from 'lodash/cloneDeep'
import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../../components";
import {EComponentTypes} from "../../types";
import { OZON_PROPS } from "../../props";
import { OZON_DESCRIPTION } from "./description";
import { OZON_PICTURE } from "./base/picture";

export const OZON_TEXT_LIST_ITEM_PICTURE: TComponent = {
  ...OZON_PICTURE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Picture',
  requiredUpperElements: [EComponents.OZON_TEXT_LIST_ITEM],
  props: {
    altText: OZON_PICTURE.props!.altText,
    objectFit: OZON_PROPS.objectFit,
    showBackground: OZON_PROPS.showBackground,
    src: {
      ...OZON_PICTURE.props!.src,
      defaultValue: {
        desktop: "https://cdn1.ozone.ru/s3/rich-content/placeholder/50x50_v2.png",
        mobile: "https://cdn1.ozone.ru/s3/rich-content/placeholder/40x40_v2.png"
      },
    },
  },
}

export const OZON_TEXT_LIST_ITEM: TComponent = {
  componentKey: EComponents.OZON_TEXT_LIST_ITEM,
  name: EComponents.OZON_TEXT_LIST_ITEM,
  type: EComponentTypes.COMPONENT,
  displayName: 'List Item',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.OZON_TEXT_LIST],
  staticChildren: true,
  children: [
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        content: {
          ...OZON_DESCRIPTION.props!.content,
          defaultValue: 'Title',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left',
        },
      }
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        fontSize: {
          ...OZON_DESCRIPTION.props!.fontSize,
          defaultValue: '2',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left',
        },
      }
    },
  ],
}

export const OZON_TEXT_LIST: TComponent = {
  componentKey: EComponents.OZON_TEXT_LIST,
  name: EComponents.OZON_TEXT_LIST,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Text List',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    listBlockTheme: OZON_PROPS.listBlockTheme,
  },
  children: Array.from({ length: 3 }, () => _cloneDeep(OZON_TEXT_LIST_ITEM)),
}
