import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS, POSITIONING_PROPS, FLEX_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";
import {TEXT} from "./text";

export const DOWNLOAD_BUTTON: TComponent = {
  componentKey: EComponents.DOWNLOAD_BUTTON,
  name: EComponents.DOWNLOAD_BUTTON,
  displayName: 'Download Button',
  type: EComponentTypes.COMPONENT,
  withChildren: true,
  props: {
    ...FLEX_PROPS,
    ...POSITIONING_PROPS,
    ...COMMON_PROPS,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: 'fit-content',
    },
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '16px 24px',
    },
    backgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#000000',
    },
    src: {
      fileType: 'file',
      name: 'File',
      defaultValue: '',
    },
  },
  children: [
    {
      ...TEXT,
      props: {
        ...TEXT.props,
        color: {
          ...TEXT.props!.color,
          defaultValue: '#ffffff',
        },
        content: {
          ...TEXT.props!.content,
          defaultValue: 'Download'
        }
      }
    }
  ]
};
