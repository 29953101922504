import axios, { AxiosError } from 'axios';
import { BuilderProActions, TDataSource } from '@/store/builder-pro/types';
import _cloneDeep from 'lodash/cloneDeep';

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL;

const dataSource: Partial<BuilderProActions> = {
  setDataSource(payload: TDataSource) {
    if (payload) {
      const dataSourceIndex = this.dataSources.findIndex((ds) => ds.id === payload.id);
      if (dataSourceIndex !== -1) {
        this.dataSources.splice(dataSourceIndex, 1, {
          ...payload,
          items: payload.items.map((item: any) => {
            const { data, ...restArgs } = item;
            return { ...data, ...restArgs };
          }),
        });
      }
    }
  },

  toggleEpackDataSourceItemById({ dataSource: _dataSource, item }) {
    if (this.epackData.dataSources instanceof Array) this.epackData.dataSources = {};
    const selectedDataSources = this.epackData.dataSources ? _cloneDeep(this.epackData.dataSources) : {};
    if (selectedDataSources[_dataSource.id] && !selectedDataSources[_dataSource.id].items[item.id]) {
      selectedDataSources[_dataSource.id].items[item.id] = item;
    } else if (!selectedDataSources[_dataSource.id]) {
      selectedDataSources[_dataSource.id] = { ..._dataSource, items: { [item.id]: item } };
    } else {
      delete selectedDataSources[_dataSource.id].items[item.id];
      if (!Object.keys(selectedDataSources[_dataSource.id].items).length) {
        delete selectedDataSources[_dataSource.id];
      }
    }
    this.epackData.dataSources = selectedDataSources;
    this.onEpackDataUpdate();
  },

  async loadDataSources() {
    return new Promise<TDataSource[] | AxiosError>((resolve, reject) => {
      axios
        .get(`${BASE_URL}/api/shop-in-shop/data-sources`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then(async (res) => {
          const dataSources = res.data?.data || [];
          this.dataSources = dataSources;
          resolve(dataSources as TDataSource[]);
        })
        .catch((e: AxiosError) => {
          this.dataSources = [];
          reject(e);
        });
    });
  },

  async loadDetailedDataSource(_dataSource) {
    await axios
      .get(`${BASE_URL}/api/shop-in-shop/data-sources/${_dataSource.id}`, {
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
      })
      .then((res) => {
        this.setDataSource(res.data?.data);
      })
      .catch((e) => {
        console.error(e);
      });
  },
};

export { dataSource };
