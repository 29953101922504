import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const VIDEO: TComponent = {
  componentKey: EComponents.VIDEO,
  name: EComponents.VIDEO,
  type: EComponentTypes.COMPONENT,
  props: {
    src: {
      fileType: 'video',
      name: 'Video',
      defaultValue: '/static/constructor/placeholders/video.mp4',
    },
    poster: {
      name: 'Poster',
      defaultValue: null,
    },
    loop: {
      name: 'Loop',
      defaultValue: 'off',
      items: ['off', 'on'],
    },
    autoplay: {
      name: 'Autoplay',
      defaultValue: 'off',
      items: ['off', 'on'],
    },
    ...COMMON_PROPS,
  },
};
