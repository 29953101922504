import _cloneDeep from 'lodash/cloneDeep'
import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {OZON_PROPS} from "../../props";
import {OZON_DESCRIPTION} from "./description";
import {OZON_PICTURE} from "./base/picture";

export const OZON_ICONS_ITEM: TComponent = {
  componentKey: EComponents.OZON_ICONS_ITEM,
  name: EComponents.OZON_ICONS_ITEM,
  type: EComponentTypes.COMPONENT,
  displayName: 'Item',
  noManualRemove: true,
  noHover: true,
  noDynamic: true,
  noParent: true,
  noSave: true,
  staticChildren: true,
  requiredUpperElements: [EComponents.OZON_ICONS],
  children: [
    {
      ...OZON_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      props: {
        altText: OZON_PICTURE.props!.altText,
        objectFit: OZON_PROPS.objectFit,
        showBackground: OZON_PROPS.showBackground,
        src: {
          ...OZON_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/200x200.jpg",
            mobile: "/static/constructor/placeholders/200x200.jpg",
          },
        },
        url: OZON_PICTURE.props!.url
      },
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        content: {
          ...OZON_DESCRIPTION.props!.content,
          defaultValue: 'Title',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        fontSize: {
          ...OZON_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
  ]
};

export const OZON_ICONS: TComponent = {
  componentKey: EComponents.OZON_ICONS,
  name: EComponents.OZON_ICONS,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Icons',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: Array.from({ length: 4 }, () => _cloneDeep(OZON_ICONS_ITEM))
};
