import { TFont } from '../types';

export const DEFAULT_FONTS: TFont[] = [
  {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Arial',
    fontWeight: '400',
    fontStyle: 'italic',
  },
  {
    fontFamily: 'Arial',
    fontWeight: '700',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'Arial',
    fontWeight: '700',
    fontStyle: 'italic',
  },
  {
    fontFamily: 'TimesNewRoman',
    fontWeight: '400',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'TimesNewRoman',
    fontWeight: '400',
    fontStyle: 'italic',
  },
  {
    fontFamily: 'TimesNewRoman',
    fontWeight: '700',
    fontStyle: 'normal',
  },
  {
    fontFamily: 'TimesNewRoman',
    fontWeight: '700',
    fontStyle: 'italic',
  },
];
