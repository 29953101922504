import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const IMAGE: TComponent = {
  componentKey: EComponents.IMAGE,
  name: EComponents.IMAGE,
  type: EComponentTypes.COMPONENT,
  props: {
    src: {
      fileType: 'image',
      name: 'Image',
      defaultValue: { 
        desktop: '/static/constructor/placeholders/1416x708.jpg',
        mobile: '/static/constructor/placeholders/900x900.jpg',
      },
    },
    objectFit: {
      name: 'Object fit',
      defaultValue: 'unset',
      items: ['unset', 'contain', 'cover', 'stretch'],
    },
    ...COMMON_PROPS,
  },
};
