import {TComponent} from "@/store/builder-pro/types";
import _cloneDeep from "lodash/cloneDeep";

export const constructNestedComponentsForComponent = (component: TComponent, components: TComponent[], isTopLevel = true) => {
  const constructedComponent = isTopLevel ? _cloneDeep(component) : component;
  const children: TComponent[] = [];
  const restComponents: TComponent[] = [];
  const currentComponents = isTopLevel ? _cloneDeep(components) : components;
  currentComponents.forEach(c => {
    if (c.parentId === constructedComponent.id) children.push(c);
    else restComponents.push(c);
  });
  if (children.length) {
    constructedComponent.children = children
      .map(child =>
        child = constructNestedComponentsForComponent(child, restComponents, false)
      )
      // NOTE: If component was changed it will pushed to the end of the array
      .sort((a, b) => (a.order || 1) - (b.order || 1))
  }
  return constructedComponent;
}
