import { TComponentProps } from '@/store/builder-pro/types';
import { DEFAULT_FONTS } from '../../default-fonts';

export const TEXT_PROPS: TComponentProps = {
  textAlign: {
    name: 'Text Align',
    defaultValue: 'center',
    items: ['left', 'center', 'right', 'justify'],
  },
  color: {
    name: 'Text Color',
    defaultValue: '#000000',
  },
  lineHeight: {
    name: 'Line Height',
    defaultValue: '150%',
  },
  fontSize: {
    name: 'Text Size',
    defaultValue: '16px',
  },
  fontFamily: {
    name: 'Font Family',
    defaultValue: DEFAULT_FONTS[0].fontFamily,
  },
  fontWeight: {
    name: 'Font Weight',
    defaultValue: DEFAULT_FONTS[0].fontWeight,
  },
  fontStyle: {
    name: 'Font Style',
    defaultValue: DEFAULT_FONTS[0].fontStyle,
  },
};
