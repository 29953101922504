import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";

export const DYNAMIC_DATA_PROVIDER: TComponent = {
  componentKey: EComponents.DYNAMIC_DATA_PROVIDER,
  name: EComponents.DYNAMIC_DATA_PROVIDER,
  type: EComponentTypes.COMPONENT,
  displayName: 'Dynamic Data Provider',
  staticChildren: true,
  possibleChildren: [
    EComponents.ROW,
    EComponents.COLUMN,
    EComponents.ACCORDION,
    EComponents.SLIDER,
    EComponents.POPOVER,
  ],
  props: {
    dataSource: {},
    dataSourceItems: {
      name: 'Data Source Items',
    },
    providedDataSourceItems: {
      name: 'Provided Data Source Items',
      orderable: true,
    },
  },
};
