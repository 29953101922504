import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";
import { COLUMN } from './column';

export const THE360VIEWER_LOADER: TComponent = {
  ...COLUMN,
  componentKey: EComponents.THE360VIEWER_LOADER,
  noManualRemove: true,
  nonDraggable: true,
  customRender: true,
  noDynamic: true,
  displayName: 'Loader',
};

export const THE360VIEWER_ADDITIONAL_CONTENT: TComponent = {
  ...COLUMN,
  componentKey: EComponents.THE360VIEWER_ADDITIONAL_CONTENT,
  props: {
    ...COLUMN.props,
    hide: {
      ...COLUMN.props!.hide,
      defaultValue: 'on',
    },
  },
  noManualRemove: true,
  nonDraggable: true,
  noDynamic: true,
  displayName: 'Additional Content',
};

export const THE360VIEWER: TComponent = {
  componentKey: EComponents.THE360VIEWER,
  name: EComponents.THE360VIEWER,
  type: EComponentTypes.COMPONENT,
  noHover: true,
  noAdaptive: true,
  staticChildren: true,
  displayName: '360 Viewer',
  props: {
    images: {
      name: 'Images',
      defaultValue: '',
    },
  },
  children: [THE360VIEWER_LOADER, THE360VIEWER_ADDITIONAL_CONTENT],
};
