import _cloneDeep from 'lodash/cloneDeep'
import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { AMAZON_PROPS } from "../../props";
import { AMAZON_DESCRIPTION } from "./description";
import { AMAZON_PICTURE } from "./picture";

// StandardComparisonTableModule

export const AMAZON_COMPARISON_CHART_COL_IMAGE: TComponent = {
  componentKey: EComponents.AMAZON_COMPARISON_CHART_COL_IMAGE,
  name: EComponents.AMAZON_COMPARISON_CHART_COL_IMAGE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Image Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_COMPARISON_CHART_ROW],
  props: {
    altText: AMAZON_PICTURE.props!.altText,
    asin: AMAZON_PROPS.asin,
    highlight: AMAZON_PROPS.highlight,
    src: {
      ...AMAZON_PICTURE.props!.src,
      // todo replace with 150x300 placeholder image
      defaultValue: "/static/constructor/placeholders/300x400.jpg",
    },
  },
}

export const AMAZON_COMPARISON_CHART_COL_TEXT: TComponent = {
  componentKey: EComponents.AMAZON_COMPARISON_CHART_COL_TEXT,
  name: EComponents.AMAZON_COMPARISON_CHART_COL_TEXT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Text Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_COMPARISON_CHART_ROW],
  props: {
    content: {
      ...AMAZON_DESCRIPTION.props!.content,
      defaultValue: 'Enter text',
    },
  }
}

export const AMAZON_COMPARISON_CHART_ROW: TComponent = {
  componentKey: EComponents.AMAZON_COMPARISON_CHART_ROW,
  name: EComponents.AMAZON_COMPARISON_CHART_ROW,
  type: EComponentTypes.COMPONENT,
  displayName: 'Row',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_COMPARISON_CHART_TABLE]
}

export const AMAZON_COMPARISON_CHART_TABLE: TComponent = {
  componentKey: EComponents.AMAZON_COMPARISON_CHART_TABLE,
  name: EComponents.AMAZON_COMPARISON_CHART_TABLE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Table',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_COMPARISON_CHART]
}

export const AMAZON_COMPARISON_CHART: TComponent = {
  componentKey: EComponents.AMAZON_COMPARISON_CHART,
  name: EComponents.AMAZON_COMPARISON_CHART,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Comparison Chart',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...AMAZON_COMPARISON_CHART_TABLE,
      children: [
        {
          ...AMAZON_COMPARISON_CHART_ROW,
          children: [
            {
              ...AMAZON_COMPARISON_CHART_COL_TEXT,
              displayName: 'Empty Column',
              props: {},
            },
            ...Array.from({ length: 5 }, () => _cloneDeep(AMAZON_COMPARISON_CHART_COL_IMAGE)),
          ],
        },
        {
          ...AMAZON_COMPARISON_CHART_ROW,
          children: [
            {
              ...AMAZON_COMPARISON_CHART_COL_TEXT,
              displayName: 'Empty Column',
              props: {},
            },
            ...Array.from({ length: 5 }, () => _cloneDeep({
              ...AMAZON_COMPARISON_CHART_COL_TEXT,
              displayName: 'Title Column',
              props: {
                content: {
                  ...AMAZON_DESCRIPTION.props!.content,
                  defaultValue: 'Enter title',
                },
              }
            })),
          ],
        },
        {
          ...AMAZON_COMPARISON_CHART_ROW,
          children: [
            {
              ...AMAZON_COMPARISON_CHART_COL_TEXT,
              displayName: 'Metric Column',
              props: {
                content: {
                  ...AMAZON_DESCRIPTION.props!.content,
                  defaultValue: 'Enter label',
                },
              }
            },
            ...Array.from({ length: 5 }, () => _cloneDeep(AMAZON_COMPARISON_CHART_COL_TEXT))
          ]
        },
      ],
    },
  ],
};

