import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../components';
import { EComponentTypes } from '../types';
import { COLUMN } from './column';
import { TEXT } from './text';

export const AR_BANNER_BUTTON: TComponent = {
  ...COLUMN,
  componentKey: EComponents.AR_BANNER_BUTTON,
  name: EComponents.AR_BANNER_BUTTON,
  type: EComponentTypes.COMPONENT,
  displayName: 'Button',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  staticChildren: true,
  isBaseComponent: true,
  props: {
    ...COLUMN.props,
    flexDirection: {
      ...COLUMN.props!.flexDirection,
      defaultValue: 'row',
    },
    margin: {
      ...COLUMN.props!.margin,
      defaultValue: '30px 0 0',
    },
    width: {
      ...COLUMN.props!.width,
      defaultValue: '100%',
    },
    maxWidth: {
      ...COLUMN.props!.maxWidth,
      defaultValue: '300px',
    },
    padding: {
      ...COLUMN.props!.padding,
      defaultValue: '15px 24px',
    },
    cursor: {
      ...COLUMN.props!.cursor,
      defaultValue: 'pointer',
    },
    background: {
      ...COLUMN.props!.background,
      defaultValue: 'rgb(21, 21, 21)',
    },
    borderRadius: {
      ...COLUMN.props!.borderRadius,
      defaultValue: '8px',
    },
    alignItems: {
      ...COLUMN.props!.alignItems,
      defaultValue: 'center',
    },
    justifyContent: {
      ...COLUMN.props!.justifyContent,
      defaultValue: 'center',
    },
  },
  children: [
    {
      ...TEXT,
      noManualRemove: true,
      props: {
        ...TEXT.props,
        content: {
          name: 'Text',
          defaultValue: 'See in the interior',
        },
        color: {
          ...TEXT.props!.color,
          defaultValue: '#ffffff',
        },
        fontSize: {
          ...TEXT.props!.fontSize,
          defaultValue: '14px',
        },
        fontFamily: {
          ...TEXT.props!.fontFamily,
          defaultValue: 'TimesNewRoman',
        },
      },
    },
  ],
};
