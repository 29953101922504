import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS, TEXT_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const TEXT: TComponent = {
  componentKey: EComponents.TEXT,
  name: EComponents.TEXT,
  type: EComponentTypes.COMPONENT,
  props: {
    element: {
      name: 'Element',
      defaultValue: 'p',
      items: ['p', 'h1', 'h2', 'h3', 'h4', 'h5', 'h6'],
    },
    maxLines: {
      inputType: 'number',
      name: 'Max Lines',
      defaultValue: '',
    },
    content: {
      name: 'Text',
      defaultValue: 'Example text',
    },
    ...TEXT_PROPS,
    ...COMMON_PROPS,
  },
};
