import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {OZON_DESCRIPTION} from "./description";
import {OZON_SINGLE_LEFT_IMAGE} from "./single-left-image";
import {OZON_TITLE_DESCRIPTION} from "./title-description";

export const OZON_SINGLE_IMAGE_SPECS: TComponent = {
  componentKey: EComponents.OZON_SINGLE_IMAGE_SPECS,
  name: EComponents.OZON_SINGLE_IMAGE_SPECS,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Single Image Specs',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        content: {
          ...OZON_DESCRIPTION.props!.content,
          defaultValue: 'Title',
        },
        fontSize: {
          ...OZON_DESCRIPTION.props!.fontSize,
          defaultValue: '5',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left',
        },
      }
    },
    {
      ...OZON_SINGLE_LEFT_IMAGE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Image Text Block',
      noManualRemove: true,
    },
    ...Array.from({ length: 2 }, (_, i) => ({
      ...OZON_TITLE_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Text Block',
      noManualRemove: true,
      props: {
        ...OZON_TITLE_DESCRIPTION.props,
        textBlockGapSize: {
          ...OZON_TITLE_DESCRIPTION.props!.textBlockGapSize,
          defaultValue: 's',
        },
        textBlockPadding: {
          ...OZON_TITLE_DESCRIPTION.props!.textBlockPadding,
          defaultValue: '1',
        },
        textBlockTheme: {
          ...OZON_TITLE_DESCRIPTION.props!.textBlockTheme,
          defaultValue: 'default',
        },
      },
      children: [
        {
          ...OZON_TITLE_DESCRIPTION.children![0],
          type: EComponentTypes.COMPONENT,
          props: {
            ...OZON_TITLE_DESCRIPTION.children![0].props,
            fontSize: {
              ...OZON_TITLE_DESCRIPTION.children![0].props!.fontSize,
              defaultValue: i === 0 ? '4' : '3',
            },
          }
        },
        {
          ...OZON_TITLE_DESCRIPTION.children![1],
          type: EComponentTypes.COMPONENT,
        },
      ],
    })),
  ],
};

