import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import { AMAZON_SINGLE_LEFT_IMAGE } from "./single-left-image";

// StandardSingleSideImageModule

export const AMAZON_SINGLE_RIGHT_IMAGE: TComponent = {
  ...AMAZON_SINGLE_LEFT_IMAGE,
  componentKey: EComponents.AMAZON_SINGLE_RIGHT_IMAGE,
  name: EComponents.AMAZON_SINGLE_RIGHT_IMAGE,
  displayName: 'Amazon Single Right Image',
};
