import _cloneDeep from 'lodash/cloneDeep'
import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../../components";
import { EComponentTypes } from "../../../types";
import { FRIENDLY_DESCRIPTION } from "../description";

export const FRIENDLY_TEXT_LIST_ITEM: TComponent = {
  componentKey: EComponents.FRIENDLY_TEXT_LIST_ITEM,
  name: EComponents.FRIENDLY_TEXT_LIST_ITEM,
  type: EComponentTypes.COMPONENT,
  displayName: 'List Item',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_TEXT_LIST],
  props: {
    ...FRIENDLY_DESCRIPTION.props,
    fontSize: {
      ...FRIENDLY_DESCRIPTION.props!.fontSize,
      defaultValue: '2'
    },
    textAlign: {
      ...FRIENDLY_DESCRIPTION.props!.textAlign,
      defaultValue: 'left'
    }
  }
}

export const FRIENDLY_TEXT_LIST: TComponent = {
  componentKey: EComponents.FRIENDLY_TEXT_LIST,
  name: EComponents.FRIENDLY_TEXT_LIST,
  type: EComponentTypes.COMPONENT,
  displayName: 'Text List',
  isBaseComponent: true,
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  staticChildren: true,
  children: [
    _cloneDeep(FRIENDLY_TEXT_LIST_ITEM),
  ],
}
