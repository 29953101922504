import { TComponentProps } from "@/store/builder-pro/types";

export const AMAZON_PROPS: TComponentProps = {
  asin: {
    name: "ASIN",
    defaultValue: "Enter ASIN",
  },
  highlight: {
    name: "Highlight",
    defaultValue: "off",
  },
  overlayColorType: {
    name: "Overlay Color Type",
    defaultValue: "dark",
    items: ["dark", "light"],
  },
  tableCount: {
    name: "Number of tables",
    defaultValue: "1",
    items: ["1", "2"],
  },
};
