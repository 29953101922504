import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";

export const FRIENDLY_SPACER: TComponent = {
  componentKey: EComponents.FRIENDLY_SPACER,
  name: EComponents.FRIENDLY_SPACER,
  type: EComponentTypes.FEATURE,
  displayName: 'Spacer',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    backgroundColor: {
      name: "Background color",
      defaultValue: "",
    },
    space: {
      name: "Space",
      defaultValue: "medium",
      items: ['extra-small', 'small', 'medium', 'large']
    }
  }
};
