import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";

export const FORM: TComponent = {
  componentKey: EComponents.FORM,
  name: EComponents.FORM,
  type: EComponentTypes.COMPONENT,
  displayName: 'Form',
  noHover: true,
  noAdaptive: true,
  withChildren: true,
  showId: true,
  prohibitedUpperElements: [EComponents.FORM],
  props: {
    submissionLink: {
      name: 'Submission link',
      defaultValue: '',
    },
    scrollToFirstValidationError: {
      name: 'Scroll to first validation error',
      defaultValue: 'on',
      items: ['on', 'off'],
    },
    afterSubmitAction: {
      name: 'After submit action',
      defaultValue: 'redirect',
      items: ['redirect', 'manipulateElements'],
    },
    redirectLink: {
      name: 'Redirect link',
      defaultValue: '',
    },
    redirectLinkTarget: {
      name: 'Target',
      defaultValue: '_blank',
      items: ['_self', '_blank'],
    },
    redirectLinkInternal: {
      defaultValue: false,
    },
    hideElementsOnSuccessfulSubmit: {
      name: 'Hide elements on successful submit',
      defaultValue: [],
    },
    showElementsOnSuccessfulSubmit: {
      name: 'Show elements on successful submit',
      defaultValue: [],
    },
    hideElementsOnFailedSubmit: {
      name: 'Hide elements on failed submit',
      defaultValue: [],
    },
    showElementsOnFailedSubmit: {
      name: 'Show elements on failed submit',
      defaultValue: [],
    },
  },
};
