import {TComponent} from '@/store/builder-pro/types';
import {COMMON_PROPS} from '../props';
import {EComponents} from '../components';
import {EComponentTypes} from "../types";

export const TABLE_ROW: TComponent = {
  componentKey: EComponents.TABLE_ROW,
  name: EComponents.TABLE_ROW,
  type: EComponentTypes.COMPONENT,
  displayName: 'Table Row',
  possibleChildren: [EComponents.TABLE_COLUMN],
  possibleParents: [EComponents.TABLE],
  noHover: true,
};

export const TABLE_COLUMN: TComponent = {
  componentKey: EComponents.TABLE_COLUMN,
  name: EComponents.TABLE_COLUMN,
  type: EComponentTypes.COMPONENT,
  displayName: 'Table Column',
  withChildren: true,
  possibleParents: [EComponents.TABLE_ROW],
  noHover: true,
  props: {
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '20px',
    },
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #dddddd',
    },
    borderTop: {
      name: 'Border top',
      defaultValue: '',
    },
    borderRight: {
      name: 'Border right',
      defaultValue: '',
    },
    borderBottom: {
      name: 'Border bottom',
      defaultValue: '',
    },
    borderLeft: {
      name: 'Border left',
      defaultValue: '',
    },
    colspan: {
      inputType: 'number',
      defaultValue: '',
    },
  },
};

export const TABLE: TComponent = {
  componentKey: EComponents.TABLE,
  name: EComponents.TABLE,
  type: EComponentTypes.COMPONENT,
  possibleChildren: [EComponents.TABLE_ROW],
  props: {
    ...COMMON_PROPS,
  },
  children: new Array(3).fill({}).map((_, rowIndex) => ({
    order: rowIndex + 1,
    ...TABLE_ROW,
    children: new Array(5).fill({}).map((__, colIndex) => ({
      order: colIndex + 1,
      ...TABLE_COLUMN,
    })),
  })),
};
