import { DYNAMIC_DATA_PROVIDER } from './provider';
import { DYNAMIC_DATA_RECEIVER } from './receiver';

export const DYNAMIC_DATA_COMPONENTS = {
  DynamicDataProvider: DYNAMIC_DATA_PROVIDER,
  DynamicDataReceiver: DYNAMIC_DATA_RECEIVER,
};

export const DYNAMIC_DATA_COMPONENT_KEYS = Object.keys(DYNAMIC_DATA_COMPONENTS);
export const DYNAMIC_DATA_COMPONENT_KEYS_OBJ = DYNAMIC_DATA_COMPONENT_KEYS.reduce(
  (a, c) => ({ ...a, [c]: true }),
  {} as Record<string, boolean>
);

export { DYNAMIC_DATA_PROVIDER, DYNAMIC_DATA_RECEIVER };
