import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";

export const FRIENDLY_PICTURE: TComponent = {
  componentKey: EComponents.FRIENDLY_PICTURE,
  name: EComponents.FRIENDLY_PICTURE,
  type: EComponentTypes.FEATURE,
  displayName: 'Picture',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    src: {
      fileType: "image",
      name: "Image",
      defaultValue: {
        desktop: "/static/constructor/placeholders/1416x708.jpg",
        mobile: "/static/constructor/placeholders/900x900.jpg"
      },
    },
    backgroundColor: {
      name: "Background color",
      defaultValue: "",
    },
    borderRadius: {
      name: "Border radius",
      defaultValue: "",
    },
    align: {
      name: "Align",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
  },
};
