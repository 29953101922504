export enum EComponents {
  FEATURE = 'Feature',
  ROW = 'Row',
  COLUMN = 'Column',
  IMAGE = 'Image',
  TEXT = 'Text',
  VIDEO = 'Video',
  YOUTUBE = 'Youtube',
  IFRAME = 'Iframe',
  ACCORDION_HEADER = 'AccordionHeader',
  ACCORDION_CONTENT = 'AccordionContent',
  ACCORDION = 'Accordion',
  BUBBLE = 'Bubble',
  BUBBLES = 'Bubbles',
  TABLE_ROW = 'TableRow',
  TABLE_COLUMN = 'TableColumn',
  TABLE = 'Table',
  SLIDE = 'Slide',
  SLIDER = 'Slider',
  LINK = 'Link',
  POPOVER_HEADER = 'PopoverHeader',
  POPOVER_CONTENT = 'PopoverContent',
  POPOVER = 'Popover',
  SCRIPT = 'Script',
  THE360VIEWER_LOADER = 'The360ViewerLoader',
  THE360VIEWER_ADDITIONAL_CONTENT = 'The360ViewerAdditionalContent',
  THE360VIEWER = 'The360Viewer',
  TAB_BUTTON = 'TabButton',
  TAB_BUTTON_WITH_STATE = 'TabButtonWithState',
  TAB_BUTTON_WITH_STATE_ACTIVE = 'TabButtonWithStateActive',
  TAB_BUTTON_WITH_STATE_INACTIVE = 'TabButtonWithStateInactive',
  TAB_CONTENT = 'TabContent',
  DOWNLOAD_BUTTON = 'DownloadButton',
  FORM = 'Form',
  FORM_ERROR = 'FormError',
  FORM_INPUT = 'FormInput',
  FORM_BUTTON = 'FormButton',
  FORM_CHECKBOX = 'FormCheckbox',
  FORM_RADIO_GROUP = 'FormRadioGroup',
  FORM_RADIO_BUTTON = 'FormRadioButton',
  FORM_SELECT_SELECTOR = 'FormSelectSelector',
  FORM_SELECT_OPTION = 'FormSelectOption',
  FORM_SELECT_DROPDOWN = 'FormSelectDropdown',
  FORM_SELECT = 'FormSelect',
  DYNAMIC_DATA_PROVIDER = 'DynamicDataProvider',
  DYNAMIC_DATA_RECEIVER = 'DynamicDataReceiver',
  AR_BANNER = 'ArBanner',
  AR_BANNER_BUTTON = 'ArBannerButton',

  FRIENDLY_ACCORDION = 'FriendlyAccordion',
  FRIENDLY_ACCORDION_CONTENT = 'FriendlyAccordionContent',
  FRIENDLY_ACCORDION_CONTENT_ITEM = 'FriendlyAccordionContentItem',
  FRIENDLY_ACCORDION_HEADER = 'FriendlyAccordionHeader',
  FRIENDLY_BUBBLES = 'FriendlyBubbles',
  FRIENDLY_BUBBLES_LIST = 'FriendlyBubblesList',
  FRIENDLY_BUBBLES_LIST_ITEM = 'FriendlyBubblesListItem',
  FRIENDLY_COL_PICTURE = 'FriendlyColPicture',
  FRIENDLY_COL2 = 'FriendlyCol2',
  FRIENDLY_COL3 = 'FriendlyCol3',
  FRIENDLY_COL4 = 'FriendlyCol4',
  FRIENDLY_COMPARISON_CHART = 'FriendlyComparisonChart',
  FRIENDLY_COMPARISON_CHART_COL_IMAGE = 'FriendlyComparisonChartColImage',
  FRIENDLY_COMPARISON_CHART_COL_TEXT = 'FriendlyComparisonChartColText',
  FRIENDLY_COMPARISON_CHART_ROW = 'FriendlyComparisonChartRow',
  FRIENDLY_DESCRIPTION = 'FriendlyDescription',
  FRIENDLY_ICONS = 'FriendlyIcons',
  FRIENDLY_IMAGE_SIDEBAR = 'FriendlyImageSidebar',
  FRIENDLY_IMAGE_TEXT_OVERLAY = 'FriendlyImageTextOverlay',
  FRIENDLY_IMAGE_TEXT_OVERLAY_LIST = 'FriendlyImageTextOverlayList',
  FRIENDLY_IMAGE_TEXT_OVERLAY_LIST_ITEM = 'FriendlyImageTextOverlayListItem',
  FRIENDLY_MULTIPLE_IMAGE_TEXT = 'FriendlyMultipleImageText',
  FRIENDLY_MULTIPLE_IMAGE_TEXT_SLIDE = 'FriendlyMultipleImageTextSlide',
  FRIENDLY_PICTURE = 'FriendlyPicture',
  FRIENDLY_PICTURE_WITH_TEXT = 'FriendlyPictureWithText',
  FRIENDLY_SINGLE_IMAGE_HIGHLIGHTS = 'FriendlySingleImageHighlights',
  FRIENDLY_SINGLE_IMAGE_SPECS = 'FriendlySingleImageSpecs',
  FRIENDLY_SINGLE_LEFT_IMAGE = 'FriendlySingleLeftImage',
  FRIENDLY_SINGLE_RIGHT_IMAGE = 'FriendlySingleRightImage',
  FRIENDLY_SPACER = 'FriendlySpacer',
  FRIENDLY_SPECIFICATIONS = 'FriendlySpecifications',
  FRIENDLY_SPECIFICATIONS_COL = 'FriendlySpecificationsCol',
  FRIENDLY_SPECIFICATIONS_ROW = 'FriendlySpecificationsRow',
  FRIENDLY_SPECIFICATIONS_TABLE = 'FriendlySpecificationsTable',
  FRIENDLY_TEXT_LIST = 'FriendlyTextList',
  FRIENDLY_TEXT_LIST_ITEM = 'FriendlyTextListItem',
  FRIENDLY_TITLE_DESCRIPTION = 'FriendlyTitleDescription',
  FRIENDLY_VIDEO = 'FriendlyVideo',
  FRIENDLY_YOUTUBE = 'FriendlyYoutube',
  FRIENDLY_SLIDER = 'FriendlySlider',
  FRIENDLY_SLIDE = 'FriendlySlide',
  FRIENDLY_SLIDE_PICTURE = 'FriendlySlidePicture',
  FRIENDLY_SLIDE_VIDEO = 'FriendlySlideVideo',
  FRIENDLY_SLIDE_YOUTUBE = 'FriendlySlideYoutube',
  FRIENDLY_SLIDE_TEXT = 'FriendlySlideText',

  OZON_COL2 = 'OzonCol2',
  OZON_COL3 = 'OzonCol3',
  OZON_COL4 = 'OzonCol4',
  OZON_COMPARISON_CHART = 'OzonComparisonChart',
  OZON_COMPARISON_CHART_COL_IMAGE = 'OzonComparisonChartColImage',
  OZON_COMPARISON_CHART_COL_TEXT = 'OzonComparisonChartColText',
  OZON_COMPARISON_CHART_ROW = 'OzonComparisonChartRow',
  OZON_COMPARISON_CHART_TABLE = 'OzonComparisonChartTable',
  OZON_DESCRIPTION = 'OzonDescription',
  OZON_ICONS = 'OzonIcons',
  OZON_ICONS_ITEM = 'OzonIconsItem',
  OZON_IMAGE_SIDEBAR = 'OzonImageSidebar',
  OZON_MULTIPLE_IMAGE_TEXT = 'OzonMultipleImageText',
  OZON_PICTURE = 'OzonPicture',
  OZON_PICTURE_WITH_TEXT = 'OzonPictureWithText',
  OZON_PICTURE_WITHOUT_MARGINS = 'OzonPictureWithoutMargins',
  OZON_PICTURE_WITHOUT_MARGINS_LIST_ITEM = 'OzonPictureWithoutMarginsListItem',
  OZON_SINGLE_IMAGE_HIGHLIGHTS = 'OzonSingleImageHighlights',
  OZON_SINGLE_IMAGE_SPECS = 'OzonSingleImageSpecs',
  OZON_SINGLE_LEFT_IMAGE = 'OzonSingleLeftImage',
  OZON_SINGLE_RIGHT_IMAGE = 'OzonSingleRightImage',
  OZON_SPECIFICATIONS = 'OzonSpecifications',
  OZON_SPECIFICATIONS_COL = 'OzonSpecificationsCol',
  OZON_SPECIFICATIONS_ROW = 'OzonSpecificationsRow',
  OZON_SPECIFICATIONS_TABLE = 'OzonSpecificationsTable',
  OZON_TEXT_LIST = 'OzonTextList',
  OZON_TEXT_LIST_ITEM = 'OzonTextListItem',
  OZON_TITLE_DESCRIPTION = 'OzonTitleDescription',
  OZON_VIDEO = 'OzonVideo',
  OZON_YOUTUBE = 'OzonYoutube',

  AMAZON_COL2 = 'AmazonCol2',
  AMAZON_COL3 = 'AmazonCol3',
  AMAZON_COL4 = 'AmazonCol4',
  AMAZON_COMPARISON_CHART = 'AmazonComparisonChart',
  AMAZON_COMPARISON_CHART_COL_IMAGE = 'AmazonComparisonChartColImage',
  AMAZON_COMPARISON_CHART_COL_TEXT = 'AmazonComparisonChartColText',
  AMAZON_COMPARISON_CHART_ROW = 'AmazonComparisonChartRow',
  AMAZON_COMPARISON_CHART_TABLE = 'AmazonComparisonChartTable',
  AMAZON_DESCRIPTION = 'AmazonDescription',
  AMAZON_ICONS = 'AmazonIcons',
  AMAZON_IMAGE_SIDEBAR = 'AmazonImageSidebar',
  AMAZON_IMAGE_TEXT_OVERLAY = 'AmazonImageTextOverlay',
  AMAZON_MULTIPLE_IMAGE_TEXT = 'AmazonMultipleImageText',
  AMAZON_MULTIPLE_IMAGE_TEXT_SLIDE = 'AmazonMultipleImageTextSlide',
  AMAZON_PICTURE = 'AmazonPicture',
  AMAZON_PICTURE_WITH_TEXT = 'AmazonPictureWithText',
  AMAZON_SINGLE_IMAGE_HIGHLIGHTS = 'AmazonSingleImageHighlights',
  AMAZON_SINGLE_IMAGE_SPECS = 'AmazonSingleImageSpecs',
  AMAZON_SINGLE_LEFT_IMAGE = 'AmazonSingleLeftImage',
  AMAZON_SINGLE_RIGHT_IMAGE = 'AmazonSingleRightImage',
  AMAZON_SPECIFICATIONS = 'AmazonSpecifications',
  AMAZON_SPECIFICATIONS_COL = 'AmazonSpecificationsCol',
  AMAZON_SPECIFICATIONS_ROW = 'AmazonSpecificationsRow',
  AMAZON_SPECIFICATIONS_TABLE = 'AmazonSpecificationsTable',
  AMAZON_TEXT_LIST = 'AmazonTextList',
  AMAZON_TEXT_LIST_ITEM = 'AmazonTextListItem',
  AMAZON_TITLE_DESCRIPTION = 'AmazonTitleDescription',
}
