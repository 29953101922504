import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";

// StandardProductDescriptionModule

export const AMAZON_DESCRIPTION: TComponent = {
  componentKey: EComponents.AMAZON_DESCRIPTION,
  name: EComponents.AMAZON_DESCRIPTION,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Description',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    content: {
      name: "Text",
      defaultValue: "Enter text",
    },
  },
};
