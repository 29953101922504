import {BuilderProActions, EHistoryStepActionType} from '@/store/builder-pro/types';
import _cloneDeep from 'lodash/cloneDeep';
import { ALL_ELEMENTS, EComponents } from '@/store/builder-pro/consts';
import { fixComponentChildrenIds } from '@/store/builder-pro/utils';

const table: Partial<BuilderProActions> = {
  addTableRow() {
    if (this.activeComponent?.name === 'Table') {
      const epackDataComponents = _cloneDeep(this.epackData.components);
      const components = epackDataComponents?.[this.activeLocale]?.[this.activeTemplate]?.[this.activePage] || [];
      const componentToAdd = _cloneDeep(ALL_ELEMENTS.TableRow);
      componentToAdd.name = EComponents.TABLE_ROW;
      componentToAdd.id = components.reduce((prev, cur) => (prev < cur.id! ? cur.id! : prev), 0) + 1;
      componentToAdd.parentId = this.activeComponent.id;
      const activeTableRows = components.filter((c) => c.parentId === this.activeComponent!.id).slice();
      componentToAdd.order = activeTableRows.reduce((prev, cur) => (prev < cur.order! ? cur.order! : prev), 0) + 1;
      const activeTableColumns = activeTableRows[0]
        ? components.filter((c) => c.parentId === activeTableRows[0].id).slice()
        : null;
      if (activeTableColumns?.length) {
        componentToAdd.children = activeTableColumns.map((col) =>
          _cloneDeep({
            ...ALL_ELEMENTS.TableColumn,
            name: EComponents.TABLE_COLUMN,
            order: col.order,
          })
        );
      }
      components.push(componentToAdd);
      const children = fixComponentChildrenIds(componentToAdd, components);

      this.addToHistory({
        type: EHistoryStepActionType.ADD_TABLE_ROW,
        components: [componentToAdd, ...children],
      })

      this.epackData.components = epackDataComponents;
      this.onEpackDataUpdate();
    }
  },
};

export { table };
