import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {FRIENDLY_DESCRIPTION,} from "./description";
import {FRIENDLY_PICTURE} from "./picture";

export const FRIENDLY_ICONS: TComponent = {
  componentKey: EComponents.FRIENDLY_ICONS,
  name: EComponents.FRIENDLY_ICONS,
  type: EComponentTypes.FEATURE,
  displayName: 'Icons',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: Array.from({ length: 4 }, () => ({
    componentKey: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
    name: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
    type: EComponentTypes.COMPONENT,
    displayName: 'Item',
    noManualRemove: true,
    noHover: true,
    noDynamic: true,
    noParent: true,
    staticChildren: true,
    children: [
      {
        ...FRIENDLY_PICTURE,
        type: EComponentTypes.COMPONENT,
        displayName: 'Picture',
        noManualRemove: true,
        props: {
          ...FRIENDLY_PICTURE.props,
          src: {
            ...FRIENDLY_PICTURE.props!.src,
            defaultValue: "/static/constructor/placeholders/200x200.jpg",
          },
        }
      },
      {
        ...FRIENDLY_DESCRIPTION,
        type: EComponentTypes.COMPONENT,
        displayName: 'Title',
        noManualRemove: true,
        props: {
          ...FRIENDLY_DESCRIPTION.props,
          fontWeight: {
            ...FRIENDLY_DESCRIPTION.props!.fontWeight,
            defaultValue: '700'
          }
        }
      },
      {
        ...FRIENDLY_DESCRIPTION,
        type: EComponentTypes.COMPONENT,
        displayName: 'Description',
        noManualRemove: true,
        props: {
          ...FRIENDLY_DESCRIPTION.props,
          textAlign: {
            ...FRIENDLY_DESCRIPTION.props!.textAlign,
            defaultValue: 'left'
          }
        }
      },
    ],
  })),
};
