import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "@/store/builder-pro/consts";
import {OZON_PICTURE} from "@/store/builder-pro/consts/components/elements";

const syncOzonProps = (updatedComponent: TComponent) => {
  switch (updatedComponent?.componentKey) {
    case EComponents.OZON_PICTURE_WITHOUT_MARGINS_LIST_ITEM:
      if (
        updatedComponent.props
        && (updatedComponent.props?.objectFit || !updatedComponent.props?.width)
      ) {
        updatedComponent.props.objectFit = undefined;
        updatedComponent.props.width = OZON_PICTURE.props!.width;
      }
      break;
    case EComponents.OZON_PICTURE_WITH_TEXT:
      updatedComponent.children?.forEach(child => {
        if (
          updatedComponent.props
          && child?.componentKey === EComponents.OZON_PICTURE
          && (updatedComponent.props?.objectFit || !updatedComponent.props?.width)
        ) {
          updatedComponent.props.objectFit = undefined;
          updatedComponent.props.width = OZON_PICTURE.props!.width;
        }
      })
      break;
    default:
  }
}

export { syncOzonProps }
