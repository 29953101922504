import {TEFAL_COMPONENTS} from "@/store/builder-pro/consts/groupseb-templates/tefal";
import {MOULINEX_COMPONENTS} from "@/store/builder-pro/consts/groupseb-templates/moulinex";
import {ROWENTA_COMPONENTS} from "@/store/builder-pro/consts/groupseb-templates/rowenta";
import {KRUPS_COMPONENTS} from "@/store/builder-pro/consts/groupseb-templates/krups";
import {WMF_TEMPLATE_CONFIG} from "@/store/builder-pro/consts/groupseb-templates/wmf";
import {TGroupsebComponent, TGroupsebTemplateConfig} from "@/store/builder-pro/types";

enum EGroupsebTemplates {
  TEFAL_TEMPLATE = 'tefal_template',
  MOULINEX_TEMPLATE = 'moulinex_template',
  ROWENTA_TEMPLATE = 'rowenta_template',
  KRUPS_TEMPLATE = 'krups_template',
  WMF_TEMPLATE = 'wmf_template',
}

const GROUPSEB_TEMPLATES: Record<EGroupsebTemplates, TGroupsebTemplateConfig | TGroupsebComponent[]> = {
  [EGroupsebTemplates.TEFAL_TEMPLATE]: TEFAL_COMPONENTS,
  [EGroupsebTemplates.MOULINEX_TEMPLATE]: MOULINEX_COMPONENTS,
  [EGroupsebTemplates.ROWENTA_TEMPLATE]: ROWENTA_COMPONENTS,
  [EGroupsebTemplates.KRUPS_TEMPLATE]: KRUPS_COMPONENTS,
  [EGroupsebTemplates.WMF_TEMPLATE]: WMF_TEMPLATE_CONFIG,
}

const GROUPSEB_TEMPLATES_LIST = Object.keys(GROUPSEB_TEMPLATES)

export { GROUPSEB_TEMPLATES, GROUPSEB_TEMPLATES_LIST, EGroupsebTemplates }
