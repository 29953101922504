import { locale } from './locale';
import { template } from './template';
import { page } from './page';
import { component } from './component';
import { dataSource } from './data-source';
import { history } from './history';

export const getters = {
  ...locale,
  ...template,
  ...page,
  ...component,
  ...dataSource,
  ...history,
};
