import _cloneDeep from 'lodash/cloneDeep'
import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../../components";
import { EComponentTypes } from "../../../types";
import { AMAZON_DESCRIPTION } from "../description";

// StandardTextListBlock

export const AMAZON_TEXT_LIST_ITEM: TComponent = {
  componentKey: EComponents.AMAZON_TEXT_LIST_ITEM,
  name: EComponents.AMAZON_TEXT_LIST_ITEM,
  type: EComponentTypes.COMPONENT,
  displayName: 'List Item',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_TEXT_LIST],
  props: {
    content: {
      ...AMAZON_DESCRIPTION.props!.content,
      defaultValue: 'Enter bullet point',
    },
  }
}

export const AMAZON_TEXT_LIST: TComponent = {
  componentKey: EComponents.AMAZON_TEXT_LIST,
  name: EComponents.AMAZON_TEXT_LIST,
  type: EComponentTypes.COMPONENT,
  displayName: 'Amazon Text List',
  isBaseComponent: true,
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  staticChildren: true,
  children: [
    _cloneDeep(AMAZON_TEXT_LIST_ITEM),
  ],
}
