import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import { OZON_SINGLE_LEFT_IMAGE } from "./single-left-image";

export const OZON_SINGLE_RIGHT_IMAGE: TComponent = {
  ...OZON_SINGLE_LEFT_IMAGE,
  componentKey: EComponents.OZON_SINGLE_RIGHT_IMAGE,
  name: EComponents.OZON_SINGLE_RIGHT_IMAGE,
  displayName: 'Ozon Single Right Image',
};
