import {useBuilderProStore} from "@/store/builder-pro";
import {NavigationGuardNext, RouteLocationNormalized} from "vue-router";

// returns true if allowed to change the page,
//  returns false if it's not allowed to change the page
const builderProBeforeRouteUpdateChecks = (to: RouteLocationNormalized, from: RouteLocationNormalized, next: NavigationGuardNext) => {
  const builderProStore = useBuilderProStore();

  if (from.name === 'builder-pro-editor' && !builderProStore.epackSavedInBackend && !builderProStore.allowedToLeavePage) {
    builderProStore.modalUnsavedChangesOnLeaveRedirectPath = to.path;
    next(false);
    return false;
  } else {
    return true;
  }
}

export { builderProBeforeRouteUpdateChecks }
