import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";
import { FORM_ERROR } from './error';
import { DEFAULT_FORM_LABEL } from './default-label';

export const FORM_CHECKBOX: TComponent = {
  componentKey: EComponents.FORM_CHECKBOX,
  name: EComponents.FORM_CHECKBOX,
  type: EComponentTypes.COMPONENT,
  displayName: 'Form Checkbox',
  staticChildren: true,
  requiredUpperElements: [EComponents.FORM],
  props: {
    name: {
      name: 'Name',
      defaultValue: '',
    },
    activeColor: {
      name: 'Active color',
      defaultValue: '#000000',
    },
    inactiveColor: {
      name: 'Inactive color',
      defaultValue: '#ffffff',
    },
    tickColor: {
      name: 'Tick color',
      defaultValue: '#ffffff',
    },
    boxBorderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px',
    },
    boxBorder: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #000000',
    },
    boxWidth: {
      ...COMMON_PROPS.width,
      defaultValue: '24px',
    },
    boxHeight: {
      ...COMMON_PROPS.height,
      defaultValue: '24px',
    },
    tickWidth: {
      ...COMMON_PROPS.width,
      defaultValue: '24px',
    },
    tickHeight: {
      ...COMMON_PROPS.height,
      defaultValue: '24px',
    },
    rules: {
      name: 'Rules',
      defaultValue: [],
    },
    ...COMMON_PROPS,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: 'fit-content',
    },
  },
  children: [DEFAULT_FORM_LABEL, FORM_ERROR],
};
