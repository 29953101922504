import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";

export const FRIENDLY_YOUTUBE: TComponent = {
  componentKey: EComponents.FRIENDLY_YOUTUBE,
  name: EComponents.FRIENDLY_YOUTUBE,
  displayName: "Youtube",
  type: EComponentTypes.FEATURE,
  props: {
    youtubeId: {
      name: 'Youtube Link / ID',
      defaultValue: 'MUdJyNMx8kc',
    },
    backgroundColor: COMMON_PROPS.backgroundColor,
    borderRadius: COMMON_PROPS.borderRadius,
  },
};
