import axios, { AxiosError, AxiosResponse } from 'axios';
import { BuilderProActions } from '@/store/builder-pro/types';

const BASE_URL = process.env.VUE_APP_CONSTRUCTOR_2_BASE_URL;

const font: Partial<BuilderProActions> = {
  async loadFonts() {
    return new Promise<AxiosResponse | AxiosError>((resolve, reject) => {
      axios
        .get(`${BASE_URL}/api/constructor/fonts`, {
          headers: {
            Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
          },
        })
        .then(
          (
            res: AxiosResponse<{
              code: number;
              data: { family: string; weight: string; style: string; formats: string[] }[];
            }>
          ) => {
            if (res.data.code === 200 && res.data.data?.length) {
              this.availableFonts = [
                ...this.availableFonts,
                ...res.data.data.map((f) => ({
                  fontFamily: f.family,
                  fontWeight: f.weight,
                  fontStyle: f.style,
                  fonts: f.formats,
                })),
              ] as any;
            }
            resolve(res);
          }
        )
        .catch((error) => {
          reject(error);
        });
    });
  },
};

export { font };
