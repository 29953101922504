export enum EEpackDataTypes {
  'sis' = 'sis',
  'minisite' = 'minisite',
}

export const EPACK_DATA_TYPES: Record<EEpackDataTypes, { key: EEpackDataTypes; name: string }> = {
  [EEpackDataTypes.sis]: {
    key: EEpackDataTypes.sis,
    name: 'Shop In Shop',
  },
  [EEpackDataTypes.minisite]: {
    key: EEpackDataTypes.minisite,
    name: 'Minisite',
  },
};
