export enum EEditorSizes {
  DESKTOP = 'desktop',
  LAPTOP = 'laptop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}

export const EDITOR_SIZES: Record<EEditorSizes, number> = {
  [EEditorSizes.DESKTOP]: 1200,
  [EEditorSizes.LAPTOP]: 1100,
  [EEditorSizes.TABLET]: 992,
  [EEditorSizes.MOBILE]: 768,
};

export const EDITOR_MEDIA_RESIZE_VALUES: Record<EEditorSizes, number> = {
  [EEditorSizes.DESKTOP]: 1416,
  [EEditorSizes.LAPTOP]: 1100,
  [EEditorSizes.TABLET]: 992,
  [EEditorSizes.MOBILE]: 900,
};

export const EDITOR_SIZES_LABELS: Record<EEditorSizes, string> = {
  [EEditorSizes.DESKTOP]: 'Desktop',
  [EEditorSizes.LAPTOP]: 'Laptop',
  [EEditorSizes.TABLET]: 'Tablet',
  [EEditorSizes.MOBILE]: 'Mobile',
};

export const EDITOR_SIZES_LIST = Object.values(EEditorSizes).map((size) => ({
  key: size,
  name: EDITOR_SIZES_LABELS[size],
  value: EDITOR_SIZES[size],
}));
