import {
  createRouter,
  createWebHistory,
  NavigationGuardNext,
  RouteLocationNormalized,
  RouteRecordRaw,
} from 'vue-router';
import { useAuthStore } from '@/store/auth';
import { handleLogoutRedirect } from '@/helpers/handle-logout-redirect';
import { builderProBeforeRouteUpdateChecks } from '@/components/builder-pro/editor/helpers/builder-pro-before-route-update-checks';

const routes: Array<RouteRecordRaw> = [
  {
    path: '/auth',
    name: 'Auth',
    meta: {
      isGlobalPage: true,
    },
    component: () => import('@/templates/TemplateAuth/index.vue'),
    children: [
      {
        path: 'login',
        name: 'Login',
        meta: {
          isGlobalPage: true,
        },
        component: () => import('@/pages/auth/page-login/index.vue'),
      },
      {
        path: 'amazon',
        name: 'AmazonLogin',
        meta: {
          isGlobalPage: true,
        },
        component: () => import('@/pages/auth/page-login/index.vue'),
      },
      {
        path: 'recover-password',
        name: 'RecoverPassword',
        meta: {
          isGlobalPage: true,
        },
        component: () => import('@/pages/auth/page-recover-password/index.vue'),
      },
    ],
  },
  {
    path: '/panel',
    name: 'AmazonRedirect',
    component: () => import('@/templates/TemplateAuth/index.vue'),
    children: [
      {
        path: 'amazon',
        name: 'amazon',
        component: () => import('@/pages/panel/page-amazon/index.vue'),
        meta: {
          requiredPermission: 'User',
        },
      },
    ],
  },
  {
    path: '/panel',
    name: 'Panel',
    component: () => import('@/templates/TemplateApp/index.vue'),
    children: [
      {
        path: 'dashboard',
        name: 'dashboard',
        component: () => import('@/pages/panel/page-dashboard/index.vue'),
        meta: {
          requiredPermission: 'User',
        },
      },
      {
        path: 'sisDashboard',
        name: 'sisDashboard',
        component: () => import('@/pages/panel/page-sis-dashboard/index.vue'),
        meta: {
          requiredPermission: 'User',
        },
      },
      {
        path: 'reports',
        name: 'reports',
        component: () => import('@/pages/panel/page-reports/index.vue'),
        meta: {
          requiredPermission: 'User',
        },
      },
      {
        name: 'statistics',
        path: 'statistics',
        children: [
          {
            name: 'liveMonitor',
            path: 'liveMonitor',
            meta: {
              requiredPermission: 'Live_monitor',
            },
            component: () => import('@/pages/panel/page-live-monitor/index.vue'),
          },
        ],
      },
      {
        name: 'trends',
        path: 'trends',
        meta: {
          requiredPermission: 'Trends',
        },
        component: () => import('@/pages/panel/page-trends/index.vue'),
      },
      {
        name: 'profile',
        path: 'profile',
        meta: {
          requiredPermission: 'User',
        },
        component: () => import('@/pages/panel/page-profile/index.vue'),
      },
      {
        name: 'content-manager',
        path: 'content-manager',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-content-manager/index.vue'),
      },
      {
        name: 'retail-manager',
        path: 'retail-manager',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-retail-manager/index.vue'),
      },
      {
        name: 'notifications',
        path: 'notifications',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-notifications/index.vue'),
      },
      {
        name: 'users',
        path: 'users',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-users/index.vue'),
      },
      {
        name: 'usersAdd',
        path: 'users/add',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-users/Editor/index.vue'),
      },
      {
        name: 'usersEdit',
        path: 'users/edit/:id',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-users/Editor/index.vue'),
      },
      {
        name: 'groups',
        path: 'groups',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-user-groups/index.vue'),
      },
      {
        name: 'groupsAdd',
        path: 'groups/add',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-user-groups/Editor/index.vue'),
      },
      {
        name: 'groupsEdits',
        path: 'groups/edit/:id',
        meta: {
          requiredPermission: 'Admin',
        },
        component: () => import('@/pages/panel/page-user-groups/Editor/index.vue'),
      },
      {
        name: 'builderList',
        path: 'builder',
        component: () => import('@/pages/panel/builder/index.vue'),
        meta: {
          requiredPermission: 'Builder',
        },
      },
      {
        name: 'builderEdit',
        path: 'builder/:id',
        component: () => import('@/pages/panel/builder/PageBuilderEdit/index.vue'),
        meta: {
          requiredPermission: 'Builder',
        },
      },
      {
        name: 'builder-pro-epacks',
        path: 'builder-pro',
        meta: {
          requiredPermission: 'BuilderPro',
        },
        component: () => import('@/pages/panel/builder-pro/epacks/index.vue'),
      },
    ],
  },
  {
    name: 'builder-pro-preview',
    path: `/panel/builder-pro/preview/:epackId`,
    meta: {
      globalPage: true,
      noAuthRequired: true,
    },
    component: () => import('@/pages/panel/builder-pro/preview/index.vue'),
  },
  {
    name: 'builder-pro-editor',
    path: '/panel/builder-pro/:id',
    meta: {
      requiredPermission: 'BuilderPro',
    },
    component: () => import('@/pages/panel/builder-pro/editor/index.vue'),
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

const checkIfDashboardAccessible = (to: RouteLocationNormalized, next: NavigationGuardNext): boolean => {
  const authStore = useAuthStore();

  if (
    to.name === 'dashboard' &&
    authStore.profile?.permissions &&
    !authStore.profile?.permissions.map((p) => p.name).includes('Dashboard')
  ) {
    next({ name: 'reports' });
    return false;
  }

  return true;
};

router.beforeEach((to, from, next) => {
  const authStore = useAuthStore();

  if (!builderProBeforeRouteUpdateChecks(to, from, next)) return;

  if (!to.name) {
    next({ name: 'dashboard' });
  }

  if (!to.meta.isGlobalPage && !to.meta.noAuthRequired && !authStore.profile) {
    if (localStorage.getItem('accessToken')) {
      authStore
        .getUserProfile()
        .then(() => {
          if (
            to.meta.requiredPermission === 'Admin' &&
            !authStore.profile?.roles.includes('ROLE_ADMIN') &&
            ![...(authStore.profile?.permissions?.map((p) => p.name) || []), 'User'].includes(
              to.meta.requiredPermission as string
            )
          ) {
            next({ name: 'dashboard' });
          } else {
            if (checkIfDashboardAccessible(to, next)) {
              next();
            }
          }
        })
        .catch(() => {
          handleLogoutRedirect(from, next);
        });
    } else {
      handleLogoutRedirect(from, next);
    }
  } else if (
    !to.meta.isGlobalPage &&
    authStore.profile &&
    to.meta.requiredPermission === 'Admin' &&
    !authStore.profile?.roles.includes('ROLE_ADMIN') &&
    ![...(authStore.profile?.permissions?.map((p) => p.name) || []), 'User'].includes(
      to.meta.requiredPermission as string
    )
  ) {
    next({ name: 'dashboard' });
  } else {
    if (checkIfDashboardAccessible(to, next)) {
      next();
    }
  }
});
export default router;
