import {TComponent} from "@/store/builder-pro/types";
import {syncOzonProps} from "./sync-ozon-props";

const syncPropsByTemplate = (updatedComponent: TComponent | undefined) => {
  if (updatedComponent?.componentKey && /^Ozon[A-Z].*/.test(updatedComponent?.componentKey)) {
    syncOzonProps(updatedComponent);
  }
}

export { syncPropsByTemplate }
