import { shared } from './shared';
import { localeActions } from './locale';
import { templateActions } from './template';
import { size } from './size';
import { epack } from './epack';
import { component } from './component';
import { dataSource } from './data-source';
import { font } from './font';
import { page } from './page';
import { table } from './table';
import { history } from './history';

export const actions = {
  ...shared,
  ...localeActions,
  ...templateActions,
  ...size,
  ...epack,
  ...component,
  ...dataSource,
  ...font,
  ...page,
  ...table,
  ...history,
};
