import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
// import {FRIENDLY_COL_PICTURE_WITH_TEXT} from "./base/col-picture-with-text";
// import {cloneDeep} from "lodash";
import { FRIENDLY_DESCRIPTION } from './description';
import { FRIENDLY_COL_PICTURE } from './base';

export const FRIENDLY_COL3: TComponent = {
  componentKey: EComponents.FRIENDLY_COL3,
  name: EComponents.FRIENDLY_COL3,
  type: EComponentTypes.FEATURE,
  displayName: 'Col3',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    backgroundColor: {
      name: "Background color",
      defaultValue: "",
    },
    align: {
      name: "Align",
      defaultValue: "flex-start",
      items: ["flex-start", "center", "flex-end", "space-between", "space-around"]
    },
    imgWidth: {
      name: 'Image width',
      defaultValue: ''
    }
  },
  children: Array.from({ length: 3 }, () => ({
    componentKey: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
    name: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
    type: EComponentTypes.COMPONENT,
    displayName: 'Col',
    noHover: true,
    noDynamic: true,
    noParent: true,
    staticChildren: true,
    children: [
      {
        ...FRIENDLY_COL_PICTURE,
        type: EComponentTypes.COMPONENT,
        displayName: 'Picture',
        noManualRemove: true,
        props: {
          ...FRIENDLY_COL_PICTURE.props,
          src: {
            ...FRIENDLY_COL_PICTURE.props!.src,
            defaultValue: "/static/constructor/placeholders/440x440.jpg",
          },
        }
      },
      {
        ...FRIENDLY_DESCRIPTION,
        type: EComponentTypes.COMPONENT,
        displayName: 'Title',
        noManualRemove: true,
        props: {
          ...FRIENDLY_DESCRIPTION.props,
          fontSize: {
            ...FRIENDLY_DESCRIPTION.props!.fontSize,
            defaultValue: '2'
          },
          fontWeight: {
            ...FRIENDLY_DESCRIPTION.props!.fontWeight,
            defaultValue: '700'
          },
          textAlign: {
            ...FRIENDLY_DESCRIPTION.props!.textAlign,
            defaultValue: 'center'
          }
        }
      },
      {
        ...FRIENDLY_DESCRIPTION,
        type: EComponentTypes.COMPONENT,
        displayName: 'Description',
        noManualRemove: true,
        props: {
          ...FRIENDLY_DESCRIPTION.props,
          fontSize: {
            ...FRIENDLY_DESCRIPTION.props!.fontSize,
            defaultValue: '2'
          },
          textAlign: {
            ...FRIENDLY_DESCRIPTION.props!.textAlign,
            defaultValue: 'center'
          }
        }
      },
    ]
  }))
};
