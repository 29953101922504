import {
  EHistoryStepActionType,
  THistoryStepAction,
  THistoryStepActionAddRemoveCustomComponent,
  THistoryStepActionMakeDynamicComponent,
  THistoryStepActionAddRemoveComponent,
  THistoryStepActionRemoveDynamicComponent,
  TStore,
  TComponent,
  THistoryStepActionAddComponent,
  THistoryStepActionReorderComponent,
  THistoryStepActionUpdateComponentProp,
  THistoryStepActionUpdateFont
} from "@/store/builder-pro/types";
import _cloneDeep from "lodash/cloneDeep";
import {
  reorderComponentsGetComponentAndDesiredParentChildren
} from "@/store/builder-pro/actions/history/utils";

export const HISTORY_UNDO_HANDLERS: Record<EHistoryStepActionType, (action: THistoryStepAction, store: TStore) => void> = {
  addComponent (action, store) {
    const indexesToRemove: number[] = [];
    const indexesToChangeOrderOf: number[] = [];

    const components = store.addedComponents

    const componentsIdMap = (action as THistoryStepActionAddComponent).components
      .reduce<Record<string, TComponent>>((acc, item) => ({
        ...acc,
        [item.id!]: item
      }), {});
    const componentsToChangeOrderOfIdMap = (action as THistoryStepActionAddComponent).componentIdsWithIncreasedOrders
      .reduce<Record<string, boolean>>((acc, item) => ({ ...acc, [item]: true }), {})

    for (let i = 0; i < components.length; i += 1) {
      if (componentsIdMap[components[i].id!]) {
        indexesToRemove.push(i);
      }
      if (componentsToChangeOrderOfIdMap[components[i].id!]) {
        indexesToChangeOrderOf.push(i);
      }

      if (
        indexesToRemove.length === (action as THistoryStepActionAddComponent).components.length
        && (
          !(action as THistoryStepActionAddComponent).componentIdsWithIncreasedOrders.length
          || indexesToChangeOrderOf.length === (action as THistoryStepActionAddComponent).componentIdsWithIncreasedOrders.length
        )
      ) {
        break;
      }
    }

    indexesToChangeOrderOf.forEach(index => {
      components[index].order! -= 1;
    })
    indexesToRemove.reverse().forEach(index => {
      components.splice(index, 1);
    })

    store.setActiveComponent({
      component: null
    });
    store.onEpackDataUpdate();
  },

  removeComponent (action, store) {
    (action as THistoryStepActionAddRemoveComponent).components.forEach(removedComponent => {
      store.addedComponents.push(removedComponent);
    });
    store.setActiveComponent({
      component: null
    });
    store.onEpackDataUpdate();
  },

  saveCustomComponent (action, store) {
    store.epackData.customComponents.splice(
      (action as THistoryStepActionAddRemoveCustomComponent).orderIndex, 1
    );
    store.onEpackDataUpdate();
  },

  removeCustomComponent (action, store) {
    store.epackData.customComponents.splice(
      (action as THistoryStepActionAddRemoveCustomComponent).orderIndex, 0,
      (action as THistoryStepActionAddRemoveCustomComponent).component
    );
    store.onEpackDataUpdate();
  },

  reorderComponent (action, store) {
    const {
      component,
      desiredParentChildren,
      desiredRawParentChildrenMap,
      foundAllDesiredParentChildren
    } = reorderComponentsGetComponentAndDesiredParentChildren(action, store);

    if (component && foundAllDesiredParentChildren) {
      component.parentId = (action as THistoryStepActionReorderComponent).prevParentId;
      component.order = (action as THistoryStepActionReorderComponent).prevOrder;

      desiredParentChildren.forEach(child => {
        child.order = desiredRawParentChildrenMap[child.id!].order;
      })

      store.setActiveComponent({
        component
      });
      store.onEpackDataUpdate();
    }
  },

  addRawComponent (action, store) {
    const indexesToRemove: number[] = [];

    const addedComponentsIdMap = (action as THistoryStepActionAddRemoveComponent).components
      .reduce<Record<string, TComponent>>((acc, item) => ({
        ...acc,
        [item.id!]: item
      }), {})

    const components = store.addedComponents;

    for (let i = 0; i < components.length; i += 1) {
      if (addedComponentsIdMap[components[i].id!]) {
        indexesToRemove.push(i);
      }

      if (indexesToRemove.length === (action as THistoryStepActionAddRemoveComponent).components.length) {
        break;
      }
    }

    indexesToRemove.reverse().forEach(index => {
      components.splice(index, 1);
    })

    store.setActiveComponent({
      component: null
    });
    store.onEpackDataUpdate();
  },

  makeComponentDynamic (action, store) {
    let dynamicDataComponentIndex: number | undefined;
    let componentIndex: number | undefined;

    const components = store.addedComponents

    for (let i = 0; i < components.length; i += 1) {
      if (components[i].id === (action as THistoryStepActionMakeDynamicComponent).dynamicDataComponent.id) {
        dynamicDataComponentIndex = i;
      }
      if (components[i].id === (action as THistoryStepActionMakeDynamicComponent).componentId) {
        componentIndex = i;
      }

      if (dynamicDataComponentIndex !== undefined && componentIndex !== undefined) {
        break;
      }
    }

    if (dynamicDataComponentIndex !== undefined && componentIndex !== undefined) {
      const component = components[componentIndex];

      component.parentId = (action as THistoryStepActionMakeDynamicComponent).dynamicDataComponent.parentId;
      component.order = (action as THistoryStepActionMakeDynamicComponent).dynamicDataComponent.order;

      components.splice(dynamicDataComponentIndex, 1);

      store.setActiveComponent({
        component
      });
      store.onEpackDataUpdate();
    }
  },

  removeDynamicDataComponent (action, store) {
    let foundComponentPairs = 0;

    const components = store.addedComponents;

    const componentPairsMap =
      (action as THistoryStepActionRemoveDynamicComponent).componentPairs
        .reduce<Record<string, THistoryStepActionRemoveDynamicComponent['componentPairs'][number]
          & {
            index: number,
            indexInComponents: number | undefined
          }
        >>(
          (acc, item, index) => ({
        ...acc,
        [item.componentId]: {
          ...item,
          index,
          indexInComponents: undefined
        }
      }), {})

    for (let i = 0; i < components.length; i += 1) {
      if (componentPairsMap[components[i].id!]) {
        componentPairsMap[components[i].id!].indexInComponents = i;
        foundComponentPairs += 1
      }

      if (foundComponentPairs === (action as THistoryStepActionRemoveDynamicComponent).componentPairs.length) {
        break;
      }
    }

    if (foundComponentPairs === (action as THistoryStepActionRemoveDynamicComponent).componentPairs.length) {
      Object.values(componentPairsMap).forEach((pair, index) => {
        const component = components[pair.indexInComponents!];

        components.push(_cloneDeep((action as THistoryStepActionRemoveDynamicComponent).componentPairs[pair.index].dynamicComponent))

        const dynamicComponent = components[components.length - 1];

        component.parentId = dynamicComponent.id;
        component.order = 1;

        if (index === 0) {
          store.setActiveComponent({
            component,
          });
        }
      })

      store.onEpackDataUpdate();
    }
  },

  addTableRow (action, store) {
    this.addRawComponent(action, store);
  },

  updateComponentProp (action, store) {
    const component =
      store.addedComponents.find(component => component.id === (action as THistoryStepActionUpdateComponentProp).componentId);

    if (component) {
      component.props![(action as THistoryStepActionUpdateComponentProp).propKey] =
        _cloneDeep((action as THistoryStepActionUpdateComponentProp).prevProp);

      if ((action as THistoryStepActionUpdateComponentProp).prevUsedFonts && (action as THistoryStepActionUpdateComponentProp).newUsedFonts) {
        store.epackData.usedFonts = _cloneDeep((action as THistoryStepActionUpdateComponentProp).prevUsedFonts!);
      }

      store.setActiveComponent({
        component,
      })
      store.onEpackDataUpdate();
    }
  },

  updateFont (action, store) {
    const historyComponents = (action as THistoryStepActionUpdateFont).components;

    store.epackData.components[store.activeLocale][store.activeTemplate][store.activePage].forEach(component => {
      if (component.props?.fontFamily && component.props?.fontWeight && component.props?.fontStyle) {
        const componentFromHistory = historyComponents.find(historyComponent => historyComponent.id === component.id);
        if (componentFromHistory?.props) {
          component.props.fontFamily = _cloneDeep(componentFromHistory.props.fontFamily);
          component.props.fontWeight = _cloneDeep(componentFromHistory.props.fontWeight);
          component.props.fontStyle = _cloneDeep(componentFromHistory.props.fontStyle);
        }
      }
    });

    if ((action as THistoryStepActionUpdateComponentProp).prevUsedFonts && (action as THistoryStepActionUpdateComponentProp).newUsedFonts) {
      store.epackData.usedFonts = _cloneDeep((action as THistoryStepActionUpdateComponentProp).prevUsedFonts!);
    }
    store.updateUsedFonts();
    store.onEpackDataUpdate();
  }
};
