import _cloneDeep from 'lodash/cloneDeep'
import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {AMAZON_DESCRIPTION} from "./description";
import {AMAZON_PICTURE} from "./picture";
import {AMAZON_TEXT_LIST} from './base/text-list';
import {AMAZON_TITLE_DESCRIPTION} from "./title-description";

// StandardSingleImageSpecsDetailModule

export const AMAZON_SINGLE_IMAGE_SPECS: TComponent = {
  componentKey: EComponents.AMAZON_SINGLE_IMAGE_SPECS,
  name: EComponents.AMAZON_SINGLE_IMAGE_SPECS,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Single Image Specs',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    {
      ...AMAZON_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...AMAZON_PICTURE.props,
        src: {
          ...AMAZON_PICTURE.props!.src,
          defaultValue: "/static/constructor/placeholders/300x300.jpg",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    ...Array.from({ length: 2 }, () => _cloneDeep({
      ...AMAZON_TITLE_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Text Block',
      noManualRemove: true,
    })),
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'List Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter list title",
        },
      },
    },
    {
      ...AMAZON_TEXT_LIST,
      type: EComponentTypes.COMPONENT,
      displayName: 'List',
    },
    {
      ...AMAZON_TITLE_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Text Block',
      noManualRemove: true,
    },
  ]
};
