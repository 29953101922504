import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import { EComponentTypes } from "../../types";
import { AMAZON_DESCRIPTION } from "./description";

// StandardTextModule

export const AMAZON_TITLE_DESCRIPTION: TComponent = {
  componentKey: EComponents.AMAZON_TITLE_DESCRIPTION,
  name: EComponents.AMAZON_TITLE_DESCRIPTION,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Title & Description',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true
    },
  ]
};
