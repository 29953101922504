import _cloneDeep from 'lodash/cloneDeep'
import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";
import { AMAZON_PROPS } from "../../props";
import { AMAZON_DESCRIPTION } from "./description";

// StandardTechSpecsModule

export const AMAZON_SPECIFICATIONS_COL: TComponent = {
  componentKey: EComponents.AMAZON_SPECIFICATIONS_COL,
  name: EComponents.AMAZON_SPECIFICATIONS_COL,
  type: EComponentTypes.COMPONENT,
  displayName: 'Text Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_SPECIFICATIONS_ROW],
  props: {
    content: {
      ...AMAZON_DESCRIPTION.props!.content,
      defaultValue: 'Enter text',
    },
  }
}

export const AMAZON_SPECIFICATIONS_ROW: TComponent = {
  componentKey: EComponents.AMAZON_SPECIFICATIONS_ROW,
  name: EComponents.AMAZON_SPECIFICATIONS_ROW,
  type: EComponentTypes.COMPONENT,
  displayName: 'Row',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_SPECIFICATIONS_TABLE]
}

export const AMAZON_SPECIFICATIONS_TABLE: TComponent = {
  componentKey: EComponents.AMAZON_SPECIFICATIONS_TABLE,
  name: EComponents.AMAZON_SPECIFICATIONS_TABLE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Table',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_SPECIFICATIONS]
}

export const AMAZON_SPECIFICATIONS: TComponent = {
  componentKey: EComponents.AMAZON_SPECIFICATIONS,
  name: EComponents.AMAZON_SPECIFICATIONS,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Specifications',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    tableCount: AMAZON_PROPS.tableCount,
  },
  children: [
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: 'Enter title',
        },
      }
    },
    {
      ...AMAZON_SPECIFICATIONS_TABLE,
      type: EComponentTypes.COMPONENT,
      children: Array.from({ length: 4 }, () => ({
        ...AMAZON_SPECIFICATIONS_ROW,
        type: EComponentTypes.COMPONENT,
        children: [
          {
            ..._cloneDeep(AMAZON_SPECIFICATIONS_COL),
            type: EComponentTypes.COMPONENT,
            props: {
              ...AMAZON_SPECIFICATIONS_COL.props,
              content: {
                ...AMAZON_SPECIFICATIONS_COL.props!.content,
                defaultValue: 'Enter specification',
              },
            }
          },
          {
            ..._cloneDeep(AMAZON_SPECIFICATIONS_COL),
            type: EComponentTypes.COMPONENT,
            props: {
              ...AMAZON_SPECIFICATIONS_COL.props,
              content: {
                ...AMAZON_SPECIFICATIONS_COL.props!.content,
                defaultValue: 'Enter definition',
              },
            }
          }
        ]
      }))
    },
  ],
};

