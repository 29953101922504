import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";
import { TEXT } from '../text';

export const FORM_BUTTON: TComponent = {
  componentKey: EComponents.FORM_BUTTON,
  name: EComponents.FORM_BUTTON,
  type: EComponentTypes.COMPONENT,
  displayName: 'Form Button',
  withChildren: true,
  props: {
    type: {
      name: 'Type',
      defaultValue: 'submit',
      items: ['submit', 'button'],
    },
    connectedForm: {
      name: 'Connected form',
      defaultValue: '',
    },
    ...COMMON_PROPS,
    backgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#000000',
    },
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '4px 12px',
    },
    borderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px',
    },
    cursor: {
      ...COMMON_PROPS.cursor,
      defaultValue: 'pointer',
    },
  },
  children: [
    {
      ...TEXT,
      props: {
        ...TEXT.props,
        color: {
          ...TEXT.props!.color,
          defaultValue: '#ffffff',
        },
      },
    },
  ],
};
