import { TComponentProp } from "../types";

export const DEFAULT_TEMPLATE_CONFIGURATIONS: Record<string, Record<string, TComponentProp>> = {
  mvideo_template: {
    altAttrText: {
      name: 'Alt Attr Text',
      defaultValue: 'Промо обложка',
      value: 'Промо обложка',
    },
    buttonText: {
      name: 'Button Text',
      defaultValue: 'Читать описание товара',
      value: 'Читать описание товара',
    },
    srcDesktop: {
      // todo use recommended width/height after feature is implemented in file input (3000x652)
      name: 'Desktop Image',
      fileType: 'image',
      defaultValue: 'https://app.constructor.24ttl.stream/placeholders/promo-cover-pdp.png',
      value: 'https://app.constructor.24ttl.stream/placeholders/promo-cover-pdp.png',
    },
    srcTablet: {
      // todo use recommended width/height after feature is implemented in file input (3000x652)
      name: 'Tablet Image',
      fileType: 'image',
      defaultValue: 'https://app.constructor.24ttl.stream/placeholders/promo-cover-pdp.png',
      value: 'https://app.constructor.24ttl.stream/placeholders/promo-cover-pdp.png',
    },
    srcMob: {
      // todo use recommended width/height after feature is implemented in file input (656x800)
      name: 'Mobile Image',
      fileType: 'image',
      defaultValue: 'https://app.constructor.24ttl.stream/placeholders/promo-cover-mobile-pdp.png',
      value: 'https://app.constructor.24ttl.stream/placeholders/promo-cover-mobile-pdp.png',
    },
  },
}