import { TComponent, TComponentProp } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";
import { IMAGE } from './image';

export const SLIDE: TComponent = {
  componentKey: EComponents.SLIDE,
  name: EComponents.SLIDE,
  type: EComponentTypes.COMPONENT,
  withChildren: true,
  possibleParents: [EComponents.SLIDER],
  props: {
    padding: {
      ...COMMON_PROPS.padding,
    } as TComponentProp,
  },
  children: [
    {
      ...IMAGE,
    },
  ],
};

export const SLIDER: TComponent = {
  componentKey: EComponents.SLIDER,
  name: EComponents.SLIDER,
  type: EComponentTypes.COMPONENT,
  possibleChildren: [EComponents.SLIDE],
  props: {
    slidesPerView: {
      inputType: 'number',
      minValue: 1,
      name: 'Slides Per View',
      defaultValue: 1,
    },
    leftArrowSvgCode: {
      name: 'Svg-code of arrow (left)',
      defaultValue: '',
    },
    arrowColor: {
      name: 'Navigation Arrow Color (default only)',
      defaultValue: '#000000',
    },
    arrowWidth: {
      name: 'Navigation Arrow Width',
      defaultValue: '46px',
    },
    arrowHeight: {
      name: 'Navigation Arrow Height',
      defaultValue: '46px',
    },
    bulletColor: {
      name: 'Pagination Bullet Color',
      defaultValue: '#cccccc',
    },
    showBullets: {
      name: 'Enable pagination',
      defaultValue: 'on',
      items: ['on', 'off'],
    },
    activeBulletColor: {
      name: 'Pagination Bullet Color (active)',
      defaultValue: '#16c7dc',
    },
    ...COMMON_PROPS,
  },
  children: [
    {
      ...SLIDE,
    },
  ],
};
