import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS, POSITIONING_PROPS, FLEX_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const COLUMN: TComponent = {
  componentKey: EComponents.COLUMN,
  name: EComponents.COLUMN,
  type: EComponentTypes.COMPONENT,
  withChildren: true,
  props: {
    ...FLEX_PROPS,
    flexDirection: {
      ...FLEX_PROPS.flexDirection,
      defaultValue: 'column',
    },
    ...POSITIONING_PROPS,
    ...COMMON_PROPS,
  },
};
