import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";

// StandardCompanyLogoModule

export const AMAZON_PICTURE: TComponent = {
  componentKey: EComponents.AMAZON_PICTURE,
  name: EComponents.AMAZON_PICTURE,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Picture',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    altText: {
      name: "Alt Text",
      defaultValue: "",
    },
    src: {
      fileType: "image",
      name: "Image",
      defaultValue: "/static/constructor/placeholders/600x180.jpg",
    },
    // todo image crop specifications
  },
};
