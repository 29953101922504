import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {AMAZON_DESCRIPTION} from "./description";
import {AMAZON_PICTURE} from "./picture";

// StandardFourImageTextQuadrantModule

export const AMAZON_ICONS: TComponent = {
  componentKey: EComponents.AMAZON_ICONS,
  name: EComponents.AMAZON_ICONS,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Icons',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: Array.from({ length: 4 }, () => ({
    componentKey: EComponents.AMAZON_PICTURE_WITH_TEXT,
    name: EComponents.AMAZON_PICTURE_WITH_TEXT,
    type: EComponentTypes.COMPONENT,
    displayName: 'Item',
    noManualRemove: true,
    noHover: true,
    noDynamic: true,
    noParent: true,
    staticChildren: true,
    children: [
      {
        ...AMAZON_PICTURE,
        type: EComponentTypes.COMPONENT,
        displayName: 'Picture',
        noManualRemove: true,
        props: {
          ...AMAZON_PICTURE.props,
          src: {
            ...AMAZON_PICTURE.props!.src,
            defaultValue: "/static/constructor/placeholders/135x135.jpg",
          },
        },
      },
      {
        ...AMAZON_DESCRIPTION,
        type: EComponentTypes.COMPONENT,
        displayName: 'Title',
        noManualRemove: true,
        props: {
          ...AMAZON_DESCRIPTION.props,
          content: {
            ...AMAZON_DESCRIPTION.props!.content,
            defaultValue: "Enter title",
          },
        }
      },
      {
        ...AMAZON_DESCRIPTION,
        type: EComponentTypes.COMPONENT,
        displayName: 'Description',
        noManualRemove: true,
      },
    ]
  }))
};
