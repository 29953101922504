import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import { EComponentTypes } from "../../types";
import { FRIENDLY_PICTURE } from "./picture";
import { FRIENDLY_COL_PICTURE } from "./base/col-picture";
import { FRIENDLY_DESCRIPTION } from "./description";
import { COMMON_PROPS } from "../../props";

export const FRIENDLY_ACCORDION_CONTENT_ITEM: TComponent = {
  componentKey: EComponents.FRIENDLY_ACCORDION_CONTENT_ITEM,
  name: EComponents.FRIENDLY_ACCORDION_CONTENT_ITEM,
  type: EComponentTypes.COMPONENT,
  displayName: 'Item',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_ACCORDION],
  staticChildren: true,
  props: {
    imgPosition: {
      name: 'Picture position',
      defaultValue: 'right',
      items: ['top', 'bottom', 'right', 'left'],
    },
    backgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff'
    },
  },
  children: [
    {
      ...FRIENDLY_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noSave: true,
      noManualRemove: true,
      props: {
        ...FRIENDLY_PICTURE.props,
        src: {
          ...FRIENDLY_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/708x708.jpg",
            mobile: "/static/constructor/placeholders/900x900.jpg",
          },
        },
      },
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noSave: true,
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontWeight: {
          ...FRIENDLY_DESCRIPTION.props!.fontWeight,
          defaultValue: '700'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noSave: true,
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Disclaimer',
      noSave: true,
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '1'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
  ],
};

export const FRIENDLY_ACCORDION_CONTENT: TComponent = {
  componentKey: EComponents.FRIENDLY_ACCORDION_CONTENT,
  name: EComponents.FRIENDLY_ACCORDION_CONTENT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Accordion Content',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_ACCORDION],
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_ACCORDION_CONTENT_ITEM,
    },
  ],
};

export const FRIENDLY_ACCORDION_HEADER: TComponent = {
  componentKey: EComponents.FRIENDLY_ACCORDION_HEADER,
  name: EComponents.FRIENDLY_ACCORDION_HEADER,
  type: EComponentTypes.COMPONENT,
  displayName: 'Accordion Header',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_ACCORDION],
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_COL_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...FRIENDLY_COL_PICTURE.props,
        src: {
          ...FRIENDLY_COL_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/1416x708.jpg",
            mobile: "/static/constructor/placeholders/900x900.jpg",
          },
        },
      },
    },
  ],
};

export const FRIENDLY_ACCORDION: TComponent = {
  componentKey: EComponents.FRIENDLY_ACCORDION,
  name: EComponents.FRIENDLY_ACCORDION,
  type: EComponentTypes.FEATURE,
  displayName: 'Accordion',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    spacer: {
      name: 'Spacer between content items',
      defaultValue: 'no',
      items: ['no', 'small', 'medium', 'large'],
    },
    iconSvgCodeCollapsed: {
      name: 'Svg-code of opening icon',
      defaultValue: '',
    },
    iconSvgCodeExpanded: {
      name: 'Svg-code of closing icon',
      defaultValue: '',
    },
  },
  children: [
    {
      ...FRIENDLY_ACCORDION_HEADER,
    },
    {
      ...FRIENDLY_ACCORDION_CONTENT,
    },
  ],
};
