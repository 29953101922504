import {EComponents} from "../../../components";
import {EComponentTypes} from "../../../types";
import {
  FRIENDLY_COL_PICTURE,
} from "./col-picture";
import {
  FRIENDLY_DESCRIPTION,
} from "../description";

export const FRIENDLY_COL_PICTURE_WITH_TEXT = {
  componentKey: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
  name: EComponents.FRIENDLY_PICTURE_WITH_TEXT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Col',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_COL_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        fontWeight: {
          ...FRIENDLY_DESCRIPTION.props!.fontWeight,
          defaultValue: '700'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'center'
        }
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'center'
        }
      }
    },
  ],
}
