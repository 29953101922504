import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";
import { TEXT } from '../text';

export const FORM_ERROR: TComponent = {
  componentKey: EComponents.FORM_ERROR,
  name: EComponents.FORM_ERROR,
  type: EComponentTypes.COMPONENT,
  displayName: 'Error Message',
  noManualRemove: true,
  nonDraggable: true,
  noDynamic: true,
  isBaseComponent: true,
  props: {
    ...TEXT.props,
    color: {
      ...TEXT.props!.color,
      defaultValue: '#ff0000',
    },
    textAlign: {
      ...TEXT.props!.textAlign,
      defaultValue: 'left',
    },
    maxLines: undefined,
    element: undefined,
    content: undefined,
    hide: undefined,
  },
};
