import _cloneDeep from 'lodash/cloneDeep'
import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {OZON_SINGLE_LEFT_IMAGE} from './single-left-image';
import {OZON_SINGLE_RIGHT_IMAGE} from './single-right-image';

export const OZON_MULTIPLE_IMAGE_TEXT: TComponent = {
  componentKey: EComponents.OZON_MULTIPLE_IMAGE_TEXT,
  name: EComponents.OZON_MULTIPLE_IMAGE_TEXT,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Multiple Image Text',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: Array.from({ length: 4 }, (_, i) => _cloneDeep({
    ...(i % 2 === 0 ? OZON_SINGLE_LEFT_IMAGE : OZON_SINGLE_RIGHT_IMAGE),
    type: EComponentTypes.COMPONENT,
    displayName: 'Image Text Block',
    noManualRemove: true,
  })),
};

