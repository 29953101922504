import { TComponentProps } from '@/store/builder-pro/types';

export const POSITIONING_PROPS: TComponentProps = {
  position: {
    name: 'Position',
    defaultValue: 'static',
    items: ['static', 'relative', 'absolute', 'fixed', 'sticky'],
  },
  top: {
    name: 'Position top',
    defaultValue: '',
  },
  left: {
    name: 'Position left',
    defaultValue: '',
  },
  right: {
    name: 'Position right',
    defaultValue: '',
  },
  bottom: {
    name: 'Position bottom',
    defaultValue: '',
  },
  zIndex: {
    inputType: 'number',
    name: 'Z-index',
    defaultValue: '',
  },
};
