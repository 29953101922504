import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const LINK: TComponent = {
  componentKey: EComponents.LINK,
  name: EComponents.LINK,
  type: EComponentTypes.COMPONENT,
  withChildren: true,
  props: {
    url: {
      name: 'URL',
      defaultValue: '',
    },
    target: {
      name: 'Target',
      defaultValue: '_blank',
      items: ['_self', '_blank'],
    },
    internal: {
      defaultValue: false,
    },
    ...COMMON_PROPS,
  },
};
