import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS } from '../props';
import { EComponents } from '../components';
import { EComponentTypes } from "../types";

export const YOUTUBE: TComponent = {
  componentKey: EComponents.YOUTUBE,
  name: EComponents.YOUTUBE,
  type: EComponentTypes.COMPONENT,
  props: {
    youtubeId: {
      name: 'Youtube Link / ID',
      defaultValue: 'MUdJyNMx8kc',
    },
    ...COMMON_PROPS,
  },
};
