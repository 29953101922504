import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../components";
import { EComponentTypes } from "../types";
import {COMMON_PROPS} from "../props";

export const FEATURE: TComponent = {
  componentKey: EComponents.FEATURE,
  name: EComponents.FEATURE,
  displayName: "Custom Module",
  type: EComponentTypes.FEATURE,
  isBaseComponent: true,
  withChildren: true,
  noParent: true,
  noAdaptive: true,
  noHover: true,
  props: {
    hide: COMMON_PROPS.hide,
  },
};
