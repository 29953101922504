import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import {EComponentTypes} from "../../types";

export const OZON_VIDEO: TComponent = {
  componentKey: EComponents.OZON_VIDEO,
  name: EComponents.OZON_VIDEO,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Video',
  noHover: true,
  noDynamic: true,
  noParent: true,
  props: {
    src: {
      fileType: "video",
      name: "Video",
      defaultValue: "/static/constructor/placeholders/video.mp4",
    },
  },
};
