import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {AMAZON_DESCRIPTION} from "./description";
import {AMAZON_PICTURE} from "./picture";

// StandardThreeImageTextModule

export const AMAZON_COL3: TComponent = {
  componentKey: EComponents.AMAZON_COL3,
  name: EComponents.AMAZON_COL3,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Col3',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...AMAZON_DESCRIPTION,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    ...Array.from({ length: 3 }, () => ({
      componentKey: EComponents.AMAZON_PICTURE_WITH_TEXT,
      name: EComponents.AMAZON_PICTURE_WITH_TEXT,
      type: EComponentTypes.COMPONENT,
      displayName: 'Col',
      noManualRemove: true,
      noHover: true,
      noDynamic: true,
      noParent: true,
      staticChildren: true,
      children: [
        {
          ...AMAZON_PICTURE,
          type: EComponentTypes.COMPONENT,
          displayName: 'Picture',
          noManualRemove: true,
          props: {
            ...AMAZON_PICTURE.props,
            src: {
              ...AMAZON_PICTURE.props!.src,
              defaultValue: "/static/constructor/placeholders/300x300.jpg",
            },
          },
        },
        {
          ...AMAZON_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Block Title',
          noManualRemove: true,
          props: {
            ...AMAZON_DESCRIPTION.props,
            content: {
              ...AMAZON_DESCRIPTION.props!.content,
              defaultValue: "Enter block title",
            },
          },
        },
        {
          ...AMAZON_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Description',
          noManualRemove: true
        },
      ]
    })),
  ]
};
