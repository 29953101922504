import {BuilderProGetters} from "@/store/builder-pro/types";

const history: Partial<BuilderProGetters> = {
  canUndo: (state) => !!state.history[(state as any).activePagePathDecorated]?.steps?.length
    && state.history[(state as any).activePagePathDecorated].position !== 0,

  canRedo: (state) => !!state.history[(state as any).activePagePathDecorated]?.steps?.length
    && state.history[(state as any).activePagePathDecorated].position !== state.history[(state as any).activePagePathDecorated].steps.length,
}

export { history };
