import {TComponent} from "@/store/builder-pro/types";
import _cloneDeep from "lodash/cloneDeep";

const fixComponentChildrenIds = (
  component: TComponent,
  currentComponents: TComponent[],
  resetOrders = false,
  deleteChildren = true
): TComponent[] => {
  if (component.children) {
    const correctedComponents: TComponent[] = [];
    const children = _cloneDeep(component.children)
    if (deleteChildren) {
      delete component.children
    }
    children.forEach((child, index) => {
      child.parentId = component.id
      child.id = currentComponents.reduce((prev, cur) => prev < cur.id! ? cur.id! : prev, 0) + 1
      if (resetOrders) child.order = index + 1
      if (deleteChildren) {
        currentComponents.push(child)
      }
    })
    correctedComponents.push(...children);
    children.forEach(child => {
      correctedComponents.push(...fixComponentChildrenIds(child, currentComponents, resetOrders))
    })
    return correctedComponents;
  }

  return [];
}

export { fixComponentChildrenIds }
