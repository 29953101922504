import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import { COMMON_PROPS, TEXT_PROPS } from "../../props";
import _cloneDeep from "lodash/cloneDeep";

export const FRIENDLY_COMPARISON_CHART_COL_IMAGE: TComponent = {
  componentKey: EComponents.FRIENDLY_COMPARISON_CHART_COL_IMAGE,
  name: EComponents.FRIENDLY_COMPARISON_CHART_COL_IMAGE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Image Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_COMPARISON_CHART_ROW],
  props: {
    src: {
      fileType: "image",
      name: "Image",
      defaultValue: "/static/constructor/placeholders/200x200.jpg",
    },
    backgroundColor: COMMON_PROPS.backgroundColor,
    align: {
      name: "Align",
      defaultValue: "center",
      items: ["flex-start", "center", "flex-end"]
    },
  },
}

export const FRIENDLY_COMPARISON_CHART_COL_TEXT: TComponent = {
  componentKey: EComponents.FRIENDLY_COMPARISON_CHART_COL_TEXT,
  name: EComponents.FRIENDLY_COMPARISON_CHART_COL_TEXT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Text Column',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_COMPARISON_CHART_ROW],
  props: {
    content: {
      name: "Text",
      defaultValue: "Example text",
    },
    backgroundColor: COMMON_PROPS.backgroundColor,
    color: {
      ...TEXT_PROPS.color,
      defaultValue: '',
    },
    fontFamily: {
      ...TEXT_PROPS.fontFamily,
      defaultValue: ''
    },
    fontWeight: {
      ...TEXT_PROPS.fontWeight,
      defaultValue: ''
    },
    fontStyle: {
      ...TEXT_PROPS.fontStyle,
      defaultValue: ''
    },
    textAlign: {
      ...TEXT_PROPS.textAlign,
      defaultValue: ''
    },
  }
}

export const FRIENDLY_COMPARISON_CHART_ROW: TComponent = {
  componentKey: EComponents.FRIENDLY_COMPARISON_CHART_ROW,
  name: EComponents.FRIENDLY_COMPARISON_CHART_ROW,
  type: EComponentTypes.COMPONENT,
  displayName: 'Row',
  noHover: true,
  noDynamic: true,
  staticChildren: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_COMPARISON_CHART]
}

export const FRIENDLY_COMPARISON_CHART: TComponent = {
  componentKey: EComponents.FRIENDLY_COMPARISON_CHART,
  name: EComponents.FRIENDLY_COMPARISON_CHART,
  type: EComponentTypes.FEATURE,
  displayName: 'Comparison Chart',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    color: {
      ...TEXT_PROPS.color,
      defaultValue: ''
    },
    fontFamily: TEXT_PROPS.fontFamily,
    fontWeight: TEXT_PROPS.fontWeight,
    fontStyle: TEXT_PROPS.fontStyle,
    textAlign: TEXT_PROPS.textAlign,
    titleColor: TEXT_PROPS.color,
    titleFontFamily: TEXT_PROPS.fontFamily,
    titleFontWeight: {
      ...TEXT_PROPS.fontWeight,
      defaultValue: '700'
    },
    titleFontStyle: TEXT_PROPS.fontStyle,
    titleTextAlign: TEXT_PROPS.textAlign,
    backgroundColor: COMMON_PROPS.backgroundColor,
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #dddddd',
    },
  },
  children: [
    {
      ...FRIENDLY_COMPARISON_CHART_ROW,
      children: [
        {
          ...FRIENDLY_COMPARISON_CHART_COL_TEXT,
          displayName: 'Empty Column',
        },
        ...Array.from({ length: 4 }, () => _cloneDeep(FRIENDLY_COMPARISON_CHART_COL_IMAGE))
      ]
    },
    ...Array.from({ length: 4 }, () => ({
      ...FRIENDLY_COMPARISON_CHART_ROW,
      children: [
        {
          ..._cloneDeep(FRIENDLY_COMPARISON_CHART_COL_TEXT),
          props: {
            ...FRIENDLY_COMPARISON_CHART_COL_TEXT.props,
            backgroundColor: {
              ...COMMON_PROPS.backgroundColor,
              defaultValue: '#f3f3f3',
            },
          },
        },
        ...Array.from({ length: 4 }, () => _cloneDeep(FRIENDLY_COMPARISON_CHART_COL_TEXT)),
      ]
    })),
  ],
};
