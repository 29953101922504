import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";

export const DYNAMIC_DATA_RECEIVER: TComponent = {
  componentKey: EComponents.DYNAMIC_DATA_RECEIVER,
  name: EComponents.DYNAMIC_DATA_RECEIVER,
  type: EComponentTypes.COMPONENT,
  displayName: 'Dynamic Data Receiver',
  nonDraggable: true,
  staticChildren: true,
  possibleChildren: [EComponents.TEXT, EComponents.IMAGE, EComponents.VIDEO, EComponents.YOUTUBE, EComponents.LINK],
  props: {
    content: {
      name: 'Content',
      defaultValue: '',
    },
    hideOnEmptyData: {
      name: 'Hide on empty data',
      items: ['on', 'off'],
      defaultValue: 'off',
    },
    fallbackOnEmptyData: {
      name: 'Fallback on empty data',
      items: ['on', 'off'],
      defaultValue: 'on',
    },
  },
};
