import {BuilderProActions, TComponent} from "@/store/builder-pro/types";
import {EComponents, EEditorSizes} from "@/store/builder-pro/consts";
import axios from "@/plugins/axios";

export const shared: Partial<BuilderProActions> = {
  setHighestSelectedTabGroup() {
    const components = this.epackData.components?.[this.activeLocale]?.[this.activeTemplate]?.[this.activePage];
    let highestSelectedTabGroup = 1;
    components?.forEach(component => {
      if (
        [EComponents.TAB_BUTTON, EComponents.TAB_BUTTON_WITH_STATE].includes(component.componentKey) &&
        component.props?.tabGroup?.value?.[EEditorSizes.DESKTOP] &&
        component.props.tabGroup.value[EEditorSizes.DESKTOP] > highestSelectedTabGroup
      ) {
        highestSelectedTabGroup = component.props.tabGroup.value[EEditorSizes.DESKTOP]
      }
    })
    this.localStates.lastSelectedTabGroup = highestSelectedTabGroup;
  },

  setDefaultActiveTabContentUniqueIds() {
    const components = this.epackData.components?.[this.activeLocale]?.[this.activeTemplate]?.[this.activePage];
    const activeTabContentUniqueIds: Record<string, boolean> = {};

    const tabButtons: TComponent[] = [];
    const tabContentUniqueIdsOpenByDefault: Record<string, boolean> = {};
    components?.forEach(component => {
      if ([EComponents.TAB_BUTTON, EComponents.TAB_BUTTON_WITH_STATE].includes(component.componentKey)) {
        tabButtons.push(component);
      } else if (
        component.componentKey === EComponents.TAB_CONTENT &&
        component.props?.uniqueId?.value?.[EEditorSizes.DESKTOP] &&
        component.props.openByDefault?.value?.[EEditorSizes.DESKTOP] === 'on'
      ) {
        tabContentUniqueIdsOpenByDefault[component.props.uniqueId.value[EEditorSizes.DESKTOP]] = true;
      }
    })
    tabButtons.forEach(tabButton => {
      if (
        tabButton.props?.tabContent?.value?.[EEditorSizes.DESKTOP] &&
        tabContentUniqueIdsOpenByDefault[tabButton.props.tabContent.value[EEditorSizes.DESKTOP]]
      ) {
        activeTabContentUniqueIds[tabButton.props.tabContent.value[EEditorSizes.DESKTOP]] = true;
      }
    })

    this.localStates.activeTabContentUniqueIds = activeTabContentUniqueIds;
  },

  updateWindowSize() {
    const container = document.querySelector('.builder-pro-editor-workarea-workarea-panel__content') as HTMLElement | undefined;
    if (container) {
      this.windowWidth = container.offsetWidth
      this.windowHeight = container.offsetHeight
    }
  },

  async loadBrandsList() {
    await axios.get('/brands/short-list')
      .then((res) => {
        if (res.data?.code === 200) {
          this.brandsList = res.data.data || [];
        }
      })
      .catch(() => {})
  }
}
