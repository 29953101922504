import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {OZON_DESCRIPTION} from "./description";
import {OZON_PICTURE} from "./base/picture";

export const OZON_PICTURE_WITH_TEXT: TComponent = {
  componentKey: EComponents.OZON_PICTURE_WITH_TEXT,
  name: EComponents.OZON_PICTURE_WITH_TEXT,
  type: EComponentTypes.FEATURE,
  displayName: 'Ozon Picture With Text',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...OZON_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      props: {
        ...OZON_PICTURE.props,
        objectFit: undefined,
      },
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        content: {
          ...OZON_DESCRIPTION.props!.content,
          defaultValue: 'Title',
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...OZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...OZON_DESCRIPTION.props,
        fontSize: {
          ...OZON_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...OZON_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
  ]
};
