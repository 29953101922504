import _cloneDeep from 'lodash/cloneDeep'
import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {AMAZON_DESCRIPTION} from "./description";
import {AMAZON_PICTURE} from "./picture";

// StandardMultipleImageTextModule

export const AMAZON_MULTIPLE_IMAGE_TEXT_SLIDE: TComponent = {
  componentKey: EComponents.AMAZON_MULTIPLE_IMAGE_TEXT_SLIDE,
  name: EComponents.AMAZON_MULTIPLE_IMAGE_TEXT_SLIDE,
  type: EComponentTypes.COMPONENT,
  displayName: 'Slide',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.AMAZON_MULTIPLE_IMAGE_TEXT],
  staticChildren: true,
  children: [
    {
      ...AMAZON_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...AMAZON_PICTURE.props,
        src: {
          ...AMAZON_PICTURE.props!.src,
          defaultValue: "/static/constructor/placeholders/300x300.jpg",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter title",
        },
      },
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true
    },
    {
      ...AMAZON_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Caption',
      noManualRemove: true,
      props: {
        ...AMAZON_DESCRIPTION.props,
        content: {
          ...AMAZON_DESCRIPTION.props!.content,
          defaultValue: "Enter caption",
        },
      },
    },
  ],
}

export const AMAZON_MULTIPLE_IMAGE_TEXT: TComponent = {
  componentKey: EComponents.AMAZON_MULTIPLE_IMAGE_TEXT,
  name: EComponents.AMAZON_MULTIPLE_IMAGE_TEXT,
  type: EComponentTypes.FEATURE,
  displayName: 'Amazon Multiple Image Text',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: Array.from({ length: 4 }, () => _cloneDeep(AMAZON_MULTIPLE_IMAGE_TEXT_SLIDE)),
};

