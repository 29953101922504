import { TComponent } from '@/store/builder-pro/types';
import { EComponents } from '../components';
import { EComponentTypes } from '../types';
import { FRIENDLY_DESCRIPTION } from './friendly/description';
import { FRIENDLY_PICTURE } from './friendly/picture';
import { COMMON_PROPS } from '../props';
import { TEXT } from './text';
import { AR_BANNER_BUTTON } from './ar-banner-button';

export const AR_BANNER: TComponent = {
  componentKey: EComponents.AR_BANNER,
  name: EComponents.AR_BANNER,
  type: EComponentTypes.FEATURE,
  displayName: 'ARBanner',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  props: {
    backgroundColor: { ...COMMON_PROPS.backgroundColor, defaultValue: '#F5F5F5' },
    locale: {
      name: 'AR Locale',
      defaultValue: 'ru',
    },
  },
  children: [
    {
      ...FRIENDLY_DESCRIPTION,
      noManualRemove: true,
      displayName: 'Title',
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        content: {
          ...FRIENDLY_DESCRIPTION.props!.content,
          defaultValue: 'Product in the interior',
        },
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '4',
        },
        fontFamily: {
          ...FRIENDLY_DESCRIPTION.props!.fontFamily,
          defaultValue: 'TimesNewRoman',
        },
      },
    },
    {
      ...FRIENDLY_PICTURE,
      noManualRemove: true,
      displayName: 'Image',
      props: {
        ...FRIENDLY_PICTURE.props,
        src: { ...FRIENDLY_PICTURE.props!.src, defaultValue: '/static/constructor/placeholders/ar_banner.jpg' },
      },
    },
    {
      ...FRIENDLY_DESCRIPTION,
      noManualRemove: true,
      displayName: 'Description',
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        content: {
          ...FRIENDLY_DESCRIPTION.props!.content,
          defaultValue:
            'Appreciate all the benefits of the product in 3D or see how it will look in the interior using your smartphone camera',
        },
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2',
        },
        fontFamily: {
          ...FRIENDLY_DESCRIPTION.props!.fontFamily,
          defaultValue: 'TimesNewRoman',
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left',
        },
        maxWidth: {
          ...FRIENDLY_DESCRIPTION.props!.maxWidth,
          defaultValue: '300px',
        },
        width: {
          ...FRIENDLY_DESCRIPTION.props!.width,
          defaultValue: '100%',
        },
        padding: {
          ...TEXT.props!.padding,
          defaultValue: '0',
        },
      },
    },
    {
      ...AR_BANNER_BUTTON,
    },
  ],
};
