import { TComponent } from "@/store/builder-pro/types";
import { EComponents } from "../../components";
import { EComponentTypes } from "../../types";
import { FRIENDLY_PICTURE } from "./picture";
import { FRIENDLY_DESCRIPTION } from "./description";
import { COMMON_PROPS } from "../../props";

export const FRIENDLY_BUBBLES_LIST_ITEM: TComponent = {
  componentKey: EComponents.FRIENDLY_BUBBLES_LIST_ITEM,
  name: EComponents.FRIENDLY_BUBBLES_LIST_ITEM,
  type: EComponentTypes.COMPONENT,
  displayName: 'Bubble',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_BUBBLES_LIST],
  staticChildren: true,
  props: {
    padding: {
      name: 'Bubble Padding',
      defaultValue: '30px',
    },
    top: {
      inputType: 'number',
      name: 'Bubble Position Top',
      defaultValue: '24',
    },
    left: {
      inputType: 'number',
      name: 'Bubble Position Left',
      defaultValue: '72',
    },
    modalPosition: {
      name: 'Bubble Modal Position',
      defaultValue: 'left-bottom',
      items: ['left-bottom', 'left-top', 'right-bottom', 'right-top'],
    },
    bubbleContentBackgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff'
    },
    bubbleButtonInactiveColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#000000'
    },
    bubbleButtonActiveColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff'
    },
    bubbleButtonInactiveBackgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff'
    },
    bubbleButtonActiveBackgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#000000'
    },
    bubbleCloseButtonColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff'
    },
    bubbleCloseButtonBackgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#000000'
    },
  },
  children: [
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontWeight: {
          ...FRIENDLY_DESCRIPTION.props!.fontWeight,
          defaultValue: '700'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Description',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontSize: {
          ...FRIENDLY_DESCRIPTION.props!.fontSize,
          defaultValue: '2'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
  ],
};

export const FRIENDLY_BUBBLES_LIST: TComponent = {
  componentKey: EComponents.FRIENDLY_BUBBLES_LIST,
  name: EComponents.FRIENDLY_BUBBLES_LIST,
  type: EComponentTypes.COMPONENT,
  displayName: 'List',
  noHover: true,
  noDynamic: true,
  noManualRemove: true,
  noSave: true,
  requiredUpperElements: [EComponents.FRIENDLY_BUBBLES],
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_BUBBLES_LIST_ITEM,
    },
  ],
};

export const FRIENDLY_BUBBLES: TComponent = {
  componentKey: EComponents.FRIENDLY_BUBBLES,
  name: EComponents.FRIENDLY_BUBBLES,
  type: EComponentTypes.FEATURE,
  displayName: 'Bubbles',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...FRIENDLY_PICTURE.props,
        src: {
          ...FRIENDLY_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/1416x708.jpg",
            mobile: "/static/constructor/placeholders/900x900.jpg",
          },
        },
      },
    },
    {
      ...FRIENDLY_BUBBLES_LIST,
    },
  ],
};
