export enum EEditorTabs {
  'EPACK_DATA' = 'epack-data',
  'EDITOR' = 'editor',
  'DATA_SOURCES' = 'data-sources',
}

export const EDITOR_TABS: Record<EEditorTabs, string> = {
  [EEditorTabs.EPACK_DATA]: 'Epack Data',
  [EEditorTabs.EDITOR]: 'Editor',
  [EEditorTabs.DATA_SOURCES]: 'Data Sources',
};
