import { TComponent } from '@/store/builder-pro/types';
import { COMMON_PROPS, POSITIONING_PROPS, TEXT_PROPS } from '../../props';
import { EComponents } from '../../components';
import { EComponentTypes } from "../../types";
import { FORM_ERROR } from './error';

export const FORM_SELECT_SELECTOR: TComponent = {
  componentKey: EComponents.FORM_SELECT_SELECTOR,
  name: EComponents.FORM_SELECT_SELECTOR,
  type: EComponentTypes.COMPONENT,
  displayName: 'Selector',
  staticChildren: true,
  nonDraggable: true,
  noManualRemove: true,
  noSave: true,
  customRender: true,
  props: {
    ...COMMON_PROPS,
    hide: undefined,
    availableToForms: undefined,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: '100%',
    },
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #f1f4f8',
    },
    borderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px',
    },
    backgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff',
    },
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '8px 12px',
    },
    zIndex: {
      ...POSITIONING_PROPS.zIndex,
      defaultValue: '2',
    },
    ...TEXT_PROPS,
    content: undefined,
    element: undefined,
    maxLines: undefined,
    textAlign: undefined,
    fontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '14px',
    },
  },
};

export const FORM_SELECT_OPTION: TComponent = {
  componentKey: EComponents.FORM_SELECT_OPTION,
  name: EComponents.FORM_SELECT_OPTION,
  type: EComponentTypes.COMPONENT,
  displayName: 'Option',
  requiredUpperElements: [EComponents.FORM_SELECT_DROPDOWN],
  noSave: true,
  customRender: true,
  props: {
    value: {
      name: 'Value',
      defaultValue: '',
    },
    label: {
      name: 'Label',
      defaultValue: '',
      value: {
        desktop: 'Option',
      },
    },
  },
};

export const FORM_SELECT_DROPDOWN: TComponent = {
  componentKey: EComponents.FORM_SELECT_DROPDOWN,
  name: EComponents.FORM_SELECT_DROPDOWN,
  type: EComponentTypes.COMPONENT,
  displayName: 'Dropdown',
  nonDraggable: true,
  noManualRemove: true,
  noSave: true,
  customRender: true,
  possibleChildren: [EComponents.FORM_SELECT_OPTION],
  props: {
    noOptionsText: {
      name: 'No options text',
      defaultValue: 'No data',
    },
    noOptionsColor: {
      name: 'No options color',
      defaultValue: '#34495e',
    },
    optionPadding: {
      name: 'Option padding',
      defaultValue: '6px',
    },
    optionBorderRadius: {
      name: 'Option border radius',
      defaultValue: '6px',
    },
    optionBackground: {
      name: 'Option background',
      defaultValue: '',
      value: {
        hover_desktop: '#f1f1f1',
      },
    },
    selectedOptionBackground: {
      name: 'Selected option background',
      defaultValue: '',
      value: {
        desktop: 'rgba(52, 73, 94, 0.1)',
      },
    },
    optionTransition: {
      name: 'Option transition',
      defaultValue: '',
      value: {
        desktop: 'background 0.2s ease',
      },
    },
    spaceBetweenOptions: {
      name: 'Space between options',
      defaultValue: '2px',
    },
    ...COMMON_PROPS,
    hide: undefined,
    margin: undefined,
    availableToForms: undefined,
    padding: {
      ...COMMON_PROPS.padding,
      defaultValue: '2px',
    },
    borderRadius: {
      ...COMMON_PROPS.borderRadius,
      defaultValue: '6px',
    },
    border: {
      ...COMMON_PROPS.border,
      defaultValue: '1px solid #f1f4f8',
    },
    backgroundColor: {
      ...COMMON_PROPS.backgroundColor,
      defaultValue: '#ffffff',
    },
    width: {
      ...COMMON_PROPS.width,
      defaultValue: '100%',
    },
    maxHeight: {
      ...COMMON_PROPS.maxHeight,
      defaultValue: '200px',
    },
    ...POSITIONING_PROPS,
    position: undefined,
    zIndex: {
      ...POSITIONING_PROPS.zIndex,
      defaultValue: '1',
    },
    top: {
      ...POSITIONING_PROPS.top,
      defaultValue: 'calc(100% + 4px)',
    },
    left: {
      ...POSITIONING_PROPS.left,
      defaultValue: '0',
    },
    ...TEXT_PROPS,
    content: undefined,
    textAlign: {
      ...TEXT_PROPS.textAlign,
      defaultValue: 'left',
    },
    fontSize: {
      ...TEXT_PROPS.fontSize,
      defaultValue: '14px',
    },
    color: {
      ...TEXT_PROPS.color,
      defaultValue: '#34495e',
    },
  },
  children: [FORM_SELECT_OPTION],
};

export const FORM_SELECT: TComponent = {
  componentKey: EComponents.FORM_SELECT,
  name: EComponents.FORM_SELECT,
  type: EComponentTypes.COMPONENT,
  displayName: 'Form Select',
  staticChildren: true,
  requiredUpperElements: [EComponents.FORM],
  props: {
    multiple: {
      name: 'Multiple',
      defaultValue: 'off',
      items: ['on', 'off'],
    },
    name: {
      name: 'Name',
      defaultValue: '',
    },
    defaultValue: {
      name: 'Default value',
      defaultValue: '',
    },
    placeholder: {
      name: 'Placeholder',
      defaultValue: 'Search to select',
    },
    rules: {
      name: 'Rules',
      defaultValue: [],
    },
    ...COMMON_PROPS,
    width: {
      ...COMMON_PROPS.width,
      defaultValue: '100%',
    },
  },
  children: [FORM_SELECT_SELECTOR, FORM_SELECT_DROPDOWN, FORM_ERROR],
};
