import { BuilderProGetters, TComponent } from '@/store/builder-pro/types';
import { DYNAMIC_DATA_COMPONENT_KEYS_OBJ } from '@/store/builder-pro/consts';
import {constructNestedComponents, normalizeParentIds, decorateComponents} from "@/store/builder-pro/utils";

type ParseNestedComponentsInnerContext = {
  dynamicProviderIndex: number | undefined;
  radioGroupIndex: number | undefined;
};

const parseNestedComponents = (
  nestedComponents: TComponent[] = [],
  innerContext: ParseNestedComponentsInnerContext = {
    dynamicProviderIndex: undefined,
    radioGroupIndex: undefined,
  }
) => {
  let currentDynamicProviderIndex = innerContext.dynamicProviderIndex;
  let currentRadioGroupIndex = innerContext.radioGroupIndex;
  let currentChildren;
  for (let i = 0; i < nestedComponents.length; i++) {
    if (nestedComponents[i].children?.length) {
      if (DYNAMIC_DATA_COMPONENT_KEYS_OBJ[nestedComponents[i].name]) {
        currentChildren = nestedComponents[i].children;
        const [deletedDynamicComponent] = nestedComponents.splice(i, 1, ...(currentChildren || []));
        if (deletedDynamicComponent.name === 'DynamicDataProvider') {
          currentDynamicProviderIndex = deletedDynamicComponent.index;
        }
        currentChildren?.forEach((childComponent) => {
          childComponent.parentId = deletedDynamicComponent.parentId;
          childComponent.isDynamic = true;
          childComponent.dynamicType = deletedDynamicComponent.name;
          childComponent.dynamicIndex = deletedDynamicComponent.index;
          childComponent.dynamicProviderIndex = currentDynamicProviderIndex;
        });
      }
      if (nestedComponents[i].componentKey === 'FormRadioGroup') {
        currentChildren = nestedComponents[i].children;
        currentRadioGroupIndex = nestedComponents[i].index;
        currentChildren?.forEach((childComponent) => {
          childComponent.radioGroupIndex = currentRadioGroupIndex;
        });
      }
      parseNestedComponents(nestedComponents[i].children, {
        dynamicProviderIndex: currentDynamicProviderIndex,
        radioGroupIndex: currentRadioGroupIndex,
      });
    }
  }
  return nestedComponents;
};

const component: Partial<BuilderProGetters> = {
  customComponents: (state) => state.epackData?.customComponents || [],
  addedComponents: (state) =>
    state.epackData?.components?.[state.activeLocale]?.[state.activeTemplate]?.[state.activePage] || [],
  unparsedNestedComponents() {
    return constructNestedComponents(normalizeParentIds(this.addedComponents))
  },
  nestedComponents() {
    return parseNestedComponents(this.unparsedNestedComponents);
  },
  activePagePathDecorated: (state) => decorateComponents(state.activeLocale, state.activeTemplate, state.activePage)
};

export { component };
