import _cloneDeep from 'lodash/cloneDeep'
import {TComponent} from "@/store/builder-pro/types";
import {EComponents} from "../../components";
import {EComponentTypes} from "../../types";
import {FRIENDLY_DESCRIPTION} from "./description";
import {FRIENDLY_PICTURE} from "./picture";
import {FRIENDLY_TEXT_LIST} from './base/text-list';
import {FRIENDLY_TITLE_DESCRIPTION} from "./title-description";

export const FRIENDLY_SINGLE_IMAGE_HIGHLIGHTS: TComponent = {
  componentKey: EComponents.FRIENDLY_SINGLE_IMAGE_HIGHLIGHTS,
  name: EComponents.FRIENDLY_SINGLE_IMAGE_HIGHLIGHTS,
  type: EComponentTypes.FEATURE,
  displayName: 'Single Image Highlights',
  noHover: true,
  noDynamic: true,
  noParent: true,
  staticChildren: true,
  children: [
    {
      ...FRIENDLY_PICTURE,
      type: EComponentTypes.COMPONENT,
      displayName: 'Picture',
      noManualRemove: true,
      props: {
        ...FRIENDLY_PICTURE.props,
        src: {
          ...FRIENDLY_PICTURE.props!.src,
          defaultValue: {
            desktop: "/static/constructor/placeholders/300x300.jpg",
            mobile: "/static/constructor/placeholders/900x900.jpg",
          },
        },
      }
    },
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        fontWeight: {
          ...FRIENDLY_DESCRIPTION.props!.fontWeight,
          defaultValue: '700'
        },
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    ...Array.from({ length: 3 }, () => _cloneDeep({
      ...FRIENDLY_TITLE_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'Text Block',
      noManualRemove: true,
      children: [
        {
          ...FRIENDLY_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Title',
          noManualRemove: true,
          props: {
            ...FRIENDLY_DESCRIPTION.props,
            fontSize: {
              ...FRIENDLY_DESCRIPTION.props!.fontSize,
              defaultValue: '2'
            },
            fontWeight: {
              ...FRIENDLY_DESCRIPTION.props!.fontWeight,
              defaultValue: '700'
            },
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              defaultValue: 'left'
            }
          }
        },
        {
          ...FRIENDLY_DESCRIPTION,
          type: EComponentTypes.COMPONENT,
          displayName: 'Description',
          noManualRemove: true,
          props: {
            ...FRIENDLY_DESCRIPTION.props,
            fontSize: {
              ...FRIENDLY_DESCRIPTION.props!.fontSize,
              defaultValue: '2'
            },
            textAlign: {
              ...FRIENDLY_DESCRIPTION.props!.textAlign,
              defaultValue: 'left'
            }
          }
        },
      ]
    })),
    {
      ...FRIENDLY_DESCRIPTION,
      type: EComponentTypes.COMPONENT,
      displayName: 'List Title',
      noManualRemove: true,
      props: {
        ...FRIENDLY_DESCRIPTION.props,
        textAlign: {
          ...FRIENDLY_DESCRIPTION.props!.textAlign,
          defaultValue: 'left'
        }
      }
    },
    {
      ...FRIENDLY_TEXT_LIST,
      type: EComponentTypes.COMPONENT,
      displayName: 'List',
    },
  ]
};
