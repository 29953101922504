import { TEpackData } from '@/store/builder-pro/types';
import dayjs from 'dayjs';
import { EEpackDataTypes } from './epack-data-types';
import { EDITOR_SIZES } from "./editor-sizes";
import {cloneDeep} from "lodash-es";

export const DATE_FORMAT = 'YYYY-MM-DDTHH:mm:ss';

export const DEFAULT_EPACK_DATA: TEpackData = {
  type: EEpackDataTypes.minisite,
  manifest: {
    createdDate: dayjs().format(DATE_FORMAT),
    updatedDate: dayjs().format(DATE_FORMAT),
    version: '1.0',
    packageID: '',
    emsId: 'constructor',
    author:
      localStorage.getItem('ttlUserInfo') !== null ? JSON.parse(localStorage.getItem('ttlUserInfo')!).username : '',
    tags: '1',
    productData: {
      brandName: '',
      productName: '',
    },
    data: {
      ru: {
        minisite: {},
      },
    },
  },
  components: {
    ru: {
      master_template: {
        'index.html': [],
      },
      ozon_template: {
        "index.html": [],
      },
      amazon_template: {
        "index.html": [],
      },
    },
  },
  templateConfiguration: {
    ru: {
      master_template: null,
      ozon_template: null,
      amazon_template: null,
    },
  },
  usedFonts: [],
  dataSources: {},
  customComponents: [],
  sizes: cloneDeep(EDITOR_SIZES),
};
